import ReportDefinitionItem from "../../../pages/Models/ReportDefinitionItem";

export default class SaveReportDefinitionRequest {    
   
    public reportDefinitionId: string = "";
    
    public name: string = "";
    
    public items: ReportDefinitionItem[] = [];
    
    public isDeleted: boolean = false;
}