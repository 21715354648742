import styles from "./DeviceErrorFaultCode.module.scss";
import React, {useState} from "react";
import Localizer from "@/localization/Localizer";
import ErrorCodeModel from "@/models/ErrorCodeModel";
import {CodeSeverity} from "@/models/Enums";
import ToolsUtility from "@/helpers/ToolsUtility";
import {Utility} from "@renta-apps/athenaeum-toolkit";

export interface IDeviceErrorFaultCodeProps
{
    errorCode : ErrorCodeModel
}


const DeviceErrorFaultCode = ({errorCode} : IDeviceErrorFaultCodeProps) => {
    const [isExpended, setExpended] = useState(false);

    const handleExpendedClick = () =>{
        setExpended(!isExpended);
    }

    const getErrorCodeWrapperStyles = () =>{
        if(errorCode.fromRentalPeriod){
            return Utility.css(styles.informationBar, styles.rent);
        }

        return  Utility.css(styles.informationBar, styles.depo);
    }

    return (
            <div className={styles.errorCodeWrapper}>
                <div className={getErrorCodeWrapperStyles()}></div>
                <p className={styles.errorCodeTitle}>{errorCode.errorTitle}</p>
                <p><span className={styles.bold}>{Localizer.errorCodesErrorCode}:</span> {errorCode.errorCode}</p>
                {errorCode.isRecurring && (
                    <>
                        <p className={styles.bold}>{Localizer.errorCodeRecurring} ({errorCode.occurred})</p>
                        <p><span className={styles.bold}>{Localizer.errorCodeLatest}:</span> {ToolsUtility.toDateStringWithTime(errorCode.latest)}</p>
                    </>
                )}

                {!errorCode.isRecurring && (
                    <p><span className={styles.bold}>{Localizer.errorCodesTimestamp}:</span> {ToolsUtility.toDateStringWithTime(errorCode.latest)}</p>
                )}

                <div className={styles.buttonContainer}>
                    <div className={styles.buttonIcon} onClick={handleExpendedClick}>
                        {isExpended && (
                            <i className="fas fa-chevron-up"></i>
                        )}
                        {!isExpended && (
                            <i className="fas fa-chevron-down"></i>
                        )}
                    </div>
                </div>

                {isExpended && (
                    <div className={styles.extendedContentGrid}>
                        <p>{Localizer.errorCodeCriticality}:</p> <p>{errorCode.severity === CodeSeverity.Critical ? Localizer.criticalityHigh : Localizer.criticalityLow}</p>
                        <p>{Localizer.errorCodeActive}:</p> <p>{errorCode.isActive ? Localizer.yes : Localizer.no}</p>
                        <p>{Localizer.errorCodesDescription}:</p> <p>{errorCode.description}</p>
                        <p>{Localizer.errorCodesSaCode}</p> <p>{errorCode.errorCode}</p>
                        <p>{Localizer.errorCodesSpnCode}:</p> <p>{errorCode.SPN}</p>
                        <p>{Localizer.errorCodesFmiCode}:</p> <p>{errorCode.FMI}</p>
                        {errorCode.isRecurring && (
                            <>
                                <p>{Localizer.errorCodesTimestamps}:</p>
                                <div>
                                    {errorCode.timestamps.map(date =>
                                        (<p>{ToolsUtility.toDateStringWithTime(date)}</p>)
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}

            </div>
    )
}

export default DeviceErrorFaultCode;