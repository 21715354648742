export enum LoginResultStatus {
    Success,

    NotFound,

    Deleted,

    Locked,

    /**
     * Token security stamp does not correspond to user security stamp
     */
    TokenInvalidSecurityStamp,

    /**
     * Token already used
     */
    TokenUsed,

    /**
     * Token invalid or expired
     */
    TokenInvalidOrExpired
}

export enum LinkTarget {
    /**
     * Load in a new window
     */
    Blank = "_blank",

    /**
     * Load in the same frame as it was clicked
     */
    Self = "_self"
}

export enum BaseInputType {
    Text = "text",

    Email = "email",

    Password = "password",

    Number = "number",

    Currency = "currency",

    TextArea = "textarea",

    Dropdown = "dropdown",

    Checkbox = "checkbox",

    File = "file"
}

export enum InputValidationRule {
    /**
     * Default empty matcher
     */
    Default = "",

    /**
     * Value must be a proper email form
     */

    Email = ".+@.+\..+",

    /**
     * Value must contain at least 1 lowercase, 1 uppercase character and 1 special character
     * Value must be 8 characters or longer
     */
    Password = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})",

    /**
     * General phone number validation for Finland.
     * It will pass old and new format numbers.
     * Between numbers space or dash can be used ones.
     * Number has to start +358 or 00358 or 0 are code can be 2 or 3 digit.
     */
    Phone = "^((([\\+][\\s]{0,1})|([0]{2}[\\s-]{0,1}))([358]{3})([\\s-]{0,1})|([0]{1}))(([1-9]{1}[0-9]{0,1})([\\s-]{0,1})([0-9]{2,4})([\\s-]{0,1})([0-9]{2,4})([\\s-]{0,1}))([0-9]{0,3}){1}$"
}

export enum PasswordValidationRule {
    LowerCaseCharacter = "^(?=.*[a-z])",

    UpperCaseCharacter = "^(?=.*[A-Z])",

    NumberCharacter = "^(?=.*[0-9])",

    SpecialCharacter = "^(?=.*[^a-zA-Z0-9 :])"
}

export enum DropdownSchema {
    Default,

    Widget,

    Transparent
}

export enum MeasuringOperators {

    Less = 0,

    LessOrEqual = 1,

    Bigger = 2,

    BiggerOrEqual = 3,

    Equal = 4,

    NotEqual = 5
}

export enum ActionType {
    Default,

    /**
     * "text-success"
     */
    Create,

    Edit,

    /**
     * red
     */
    Delete,

    Muted,

    /**
     * "text-light"
     */
    Light,

    /**
     * "text-secondary"
     */
    Secondary,

    /**
     * cyan
     */
    Info,

    Grey,

    Blue,
}

export enum DeviceStatus {
    InStock,

    ReturnInspectionIsNeeded,

    InMaintenance,

    InRent,

    RepairIsNeeded
}

export enum DeviceBanOfUse {
    None,

    NeedBan,

    Banned,

    AdminRemoved
}

export enum DeviceCounterType {
    /**
     * Total operation hours
     */
    TotalOperatingHours,

    /**
     * Operation hours after last service
     */
    CurrentOperatingHours,

    /**
     * Total rental days
     */
    TotalRentalDays,

    /**
     * Rental days after last service
     */
    CurrentRentalDays,

    /**
     * Operation hours after service previous service recycle
     */
    RollingOperatingHours,

    /**
     * How many times service cycle has been started over
     */
    RollingOperatingHoursRecycleCount

}

export enum DeviceAlertType {
    /**
     * The device status was changed from NeedsReturnInspection to InRent directly in ERP
     */
    IgnoreReturnInspection,

    /**
     * The device was moved from one contract to another during the rent directly in ERP
     */
    MoveContract,

    /**
     * The device was moved from one contract to another while machine was waiting a return inspection
     */
    ResetStatus,

    /**
     * The device is needed return inspection or service but at the same time device is under contract
     */
    MaintenanceIntegrityFault,

    /**
     * The device is in rent but there is no contract specified
     */
    RentIntegrityFault,
}

export enum ResourceItemType {
    Operating,

    Washing,

    Fueling,

    AdBlue
}

export enum UserRole {
    Technician,

    DepoManager,

    AreaManager,

    Admin
}

export enum InvitationType {
    Invitation,

    ResetPassword,

    ForgotPassword
}

export enum AuthType {
    Email,

    Phone
}

export enum ReportItemType {
    QuestionPictures,

    Question,

    Resource,

    QuestionResource,

    Pictures,

    MeasuringResource,

    Checks,

    ErrorsCodes,

    AdvancedChecks,
}

export enum ReportDefinitionType {
    ReturnInspection,

    Service
}

export enum MaintenanceReason {
    /*
    *  Repair: has active faults
    */
    Repair,

    /*
    *  Service: one of the service counter is triggered
    */
    Service,

    /*
    *  Service trigger: months after last service limit
    */
    AnnualServiceMonthsLimit,

    /*
    *  Service trigger: total operation hours limit
    */
    TotalOperatingHoursLimit,

    /*
    *  Service trigger: current (after last service) operation hours limit
    */
    CurrentOperatingHoursLimit,

    /*
    *  Service trigger: total rental days limit
    */
    TotalRentalDaysLimit,

    /*
    *  Service trigger: current (after last service) rental days limit
    */
    CurrentRentalDaysLimit,

    /*
    *  Service trigger: total life months limit
    */
    TotalMonthsLimit,

    /*
    *  Service triggered manually
    */
    Manual,

    /*
    * Annual inspection has remarks that have to be fixed before lease or it is overdue.
    */
    AnnualInspection,
}

export enum MaintenanceReasons
{
    None = 0,

    Repair = 1 << MaintenanceReason.Repair,

    Service = 1 << MaintenanceReason.Service,

    AnnualServiceMonthsLimit = 1 << MaintenanceReason.AnnualServiceMonthsLimit,

    TotalOperatingHoursLimit = 1 << MaintenanceReason.TotalOperatingHoursLimit,

    CurrentOperatingHoursLimit = 1 << MaintenanceReason.CurrentOperatingHoursLimit,

    TotalRentalDaysLimit = 1 << MaintenanceReason.TotalRentalDaysLimit,

    CurrentRentalDaysLimit = 1 << MaintenanceReason.CurrentRentalDaysLimit,

    TotalMonthsLimit = 1 << MaintenanceReason.TotalMonthsLimit,

    Manual = 1 << MaintenanceReason.Manual,

    AnnualInspection = 1 << MaintenanceReason.AnnualInspection,
}

export enum FaultLevel {
    Minor,

    Major
}

export enum AuditTimestamp {
    CreatedAt,

    ModifiedAt
}

export enum SavePasswordResultStatus {
    Success,

    WeakPassword,

    WrongCurrent
}

export enum AnnualInspectionStatus {
    Passed,

    PassedWithRemarks,

    RemarksRepaired,

    PassedWithRemarksAndDeviceOnBanOfUse
}

export enum AnnualInspectionType {
    Inspection,

    Calibration,

    Anniversary10Years,

    DeploymentInspection,

    IbcContainerInspection,
}

export enum InvoiceStatusFilter {
    NotProcessed,

    Approved,

    Declined
}

export enum ServiceLocationType {
    Depot = 0,

    ConstructionSite = 1,

    ServiceShop = 2
}

export enum DevicePicturesFilter {
    Manual,

    ReturnInspection,

    ReturnInspectionFirstPicturesStep,

    ReturnInspectionFinalPicturesStep
}

export enum DevicePictureType {
    ReturnInspection,

    ReturnInspectionFirstPicturesStep,

    ReturnInspectionFinalPicturesStep,

    Manual
}

export enum AiJobStatus {
    Created,

    InProcess,

    Success,

    Failed
}

export enum AiJobType {
    Forecast,

    Train
}

export enum AiDataType {
    DeviceTypeDaily,

    DeviceTypeDepoDaily
}

export enum AiMetadataType {
    TargetTimeSeries,

    ItemMetadataData,

    RelatedTimeSeriesData
}

export enum ScheduleAction {
    Auto = 0
}

export enum AnnualInspectionDevicesType {
    Upcoming,
    Remarks,
    Past
}

export enum AnnualInspectionStatusFilterType {
    Late,
    OneMonth,
    TwoWeeks
}

export enum ReturnInspectionStatus {
    Waiting = 0,
    PartlyCompleted = 1,
    Skipped = 3,
    InProgress = 4,
    Completed = 5,
}

export enum ReturnInspectionDetailsTypes {
    Washing = 0,
    Fueling = 1,
    WashingAndFueling = 2,
}

export enum ImageValidationResult {
    Success = 0,
    ResolutionTooHigh = 1,
    UnsupportedFormat = 2,
}

export enum SearchItemType {
    Device = 0,
    ProductGroup = 1,
    File = 2,
    Picture = 3,
    ReturnInspectionReport = 4,
    ServiceReport = 5,
    AnnualInspectionReport = 6,
    Invoice = 7,
    DeviceAlert = 8,
    ReportingRow = 9,
    DeviceContract = 10,
    DeviceCounter = 11,
    DeviceDailyInfo = 12,
}

export enum ReturnInspectionResponseType
{
    Success = 0,
    InProgress = 1,
    WrongDeviceStatus = 2,
    ReportDefinitionNotFound = 3,
    InvalidOperatingHours = 4,
    NotFound = 5,
    InvalidSameDayOperatingHours = 6,
    OvertakenByDifferentUser = 7,
}
export enum AnnualInspectionResponseType
{
    Success = 0,
    InvalidOperatingHours = 1,
    InvalidSameDayOperatingHours = 2,
}

export enum SaveServiceResponseType
{
    InvalidOperatingHours = 0,
    InvalidSameDayOperatingHours = 1,
    InvalidOperatingHoursAtService = 2,
}

export enum CodeSeverity {
    Low = 0,
    Critical = 1,
}

export enum ErrorCodesFilters
{
    RentalPeriod = 0,
    FiveDay = 1,
    TenDays = 2,
    TwentyDays = 3,
    LastMonth = 4,
    RentalPeriodTillToday = 5,
}

export enum ServiceAndRepairsStatuses {
    Waiting = 0,
    InProgress = 1,
    Completed = 2,
    UpcomingService = 3,
}

export enum ServiceTypeFilter {
    Service = 0,
    Repair = 1,
}

export enum RecordOperatingHoursSource {
    ReturnInspection = 0,
    Service = 1,
    AnnualInspection = 2,
}

export enum DeviceTelematicsData {
    SerialNumber = 0,
    EngineStatus = 1,
    BatteryPotential = 2,
    CumulativeOperatingHours = 3,
    Timestamp = 4
}

export enum InspectionErrors {
    None = 0,
    WrongContract = 1 << 0,
    ContractConflict = 1 << 1,
    ContractNotFound = 1 << 2,
    ReportNotFound = 1 << 3,
}

export enum InvoicesTypes {
    Invoice = 0,
    ManualInvoices = 1,
    SkippedInspectionInvoice = 2,
}

export enum LambdaLogicalOperation
{
    Or = 0,
    And = 1,
}

export enum CheckStatus {
    Unselected = "Unselected",
    Yes = "Yes",
    No = "No",
    Skip = "Skip",
}

export enum MaintenanceStatus {
    None = 0,
    RequiresReturnInspection = 1 << 0,
    RequiresService = 1 << 1,
    RequiresRepair = 1 << 2,
    RequiresAnnualInspection = 1 << 3,
}

export enum FailedItemType {
    Unknown = 0,
    ReturnInspectionStep = 1,
    DeviceFault = 2,
    ManualFault = 3,
}

export enum DeviceListOrderType {
    ReturnedDevices = 0,
    ServiceDevices = 1,
}

export enum ExpenseUnit
{
    Piece = 0,
    Meter = 1,
    Meter2 = 2,
    Kilometer = 3,
    Liter = 4,
    Box = 5,
    Pair = 6,
    Bottle = 7,
    Can = 8,
    Bag = 9,
    Roll = 10,
    Hour = 11,
}

export enum OnletMaintenanceType
{
    Unknown = 0,
    DeliveryInspection = 1,
    ReturnInspection = 2,
    AnnualInspection = 3,
    General = 4,
    Repair = 8,
    Service = 9,
}

export enum OnletMaintenanceStatus
{
    NotProcessed = 1,
    Started = 2,
    Completed = 5,
}

export enum PredictionTime {
    Week = 0,
    TwoWeeks = 1,
    OneMonth = 2,
    TwoMonths = 3,
}

export enum DeviceListOrder {
    ByPriority = 0,
    ByDate = 1,
}

export enum PredictionDatesType
{
    LinearPredictionDate = 0,
    NextAnnualServiceDate = 1,
}

export enum ScheduledTaskStatus
{
    Pending = 0,
    Failed = 1,
    Completed = 2,
}

export enum NotificationStatus {
    Sent = 0,
    Failed = 1
}