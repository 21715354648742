import Localizer from "../../../localization/Localizer";

describe('Invoice page tests', () => {

    beforeEach(() => {
        cy.login();
        cy.visit('/')
        cy.goToDashboard();
        cy.goToInvoicesPage();
    });

    it('Assert invoices page content', () => {
        assertHeader();
        assertFilters();
    });


    function assertFilters() {

        cy.get('[id^=invoices_list_filter_button]')
            .first()
            .click();

        cy.get("h5")
            .should('contain', Localizer.invoicesPageModalTitleFilters)

        cy.get('[id*="invoices_filter_deviceExternalId_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_status_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_depot_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_contractId_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_inspectorId_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_customerId_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_customerName_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_constructionSiteExternalId_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_constructionSiteName_"]')
            .should('exist');

        cy.get('[id*="invoices_filter_from_"]')
            .should('exist');

        cy.get('form').first().submit();
    }

    function assertHeader() {
        cy.get("h1")
            .should('contain', Localizer.invoicesPageHeaderTitle);
    }
});