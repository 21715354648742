class InvoicesPage {
    public elements = {
        invoiceTab: () => cy.get("#tab_invoices"),
        manualInvoicesTab: () => cy.get("#tab_manualInvoices"),
        skippedInspectionTab: () => cy.get("#tab_skippedInspectionRows"),
        listItem: (deviceExternalId: string) => cy.get(`[id^=listItem_${deviceExternalId}_]`),
        filterBtn: () => cy.get('[id^=invoices_list_filter_button_]'),
        filterModal: () => cy.get('[id^=invoices_list_filters_modal_]'),
    };

    public routes = {
    };
}

export default new InvoicesPage();