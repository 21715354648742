import React from "react";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Dropdown, Form, Inline, Modal, NumberInput, NumberInputBehaviour, SelectListItem, TextAreaInput, TextInput} from "@renta-apps/athenaeum-react-components";
import ServiceReport from "@/pages/Models/ServiceReport";
import EditServiceInvoiceDetailsRequest from "@/models/server/requests/EditServiceInvoiceDetailsRequest";
import ServiceExpense from "@/models/server/ServiceExpense";
import Depo from "@/models/server/Depo";
import {ServiceLocationType} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import Device from "@/pages/Models/Device";
import RentaToolsController from "@/pages/RentaToolsController";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../../localization/Localizer";

import styles from "./ServiceEditInvoiceModal.module.scss";
import newStyles from "@/pages/NewUI.module.scss";

export interface IServiceEditInvoiceModalProps {
    onSubmit(sender: IBaseComponent, request: EditServiceInvoiceDetailsRequest): Promise<boolean>;
    maxOperatingHours: number | undefined;
    supportsOperatingHours: boolean;
}

interface IServiceEditInvoiceModalState {
    serviceExpenses: ServiceExpense[];
    serviceReport: ServiceReport;
    invoiceNumber: string;
    cost: number;
    operatingHours: number | null;
    serviceExpense: ServiceExpense | null;
    depos: Depo[],
    depo: Depo | null;
    locationType: ServiceLocationType | null;
    comment: string | null;
    deposList: SelectListItem[];
    serviceExpensesList: SelectListItem[];
    minOperatingHours: number | null;
}

export default class ServiceEditInvoiceModal extends BaseComponent<IServiceEditInvoiceModalProps, IServiceEditInvoiceModalState> {
    state: IServiceEditInvoiceModalState = {
        serviceReport: new ServiceReport(),
        serviceExpenses: [],
        invoiceNumber: "",
        cost: 0,
        operatingHours: null,
        serviceExpense: null,
        depos: [],
        depo: null,
        locationType: null,
        comment: null,
        deposList: [],
        serviceExpensesList: [],
        minOperatingHours: null,
    };

    private readonly _modalRef: React.RefObject<Modal<ServiceReport>> = React.createRef();

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const serviceExpenses: ServiceExpense[] = await RentaToolsController.getServiceExpensesAsync();
        const depos: Depo[] = await RentaToolsController.getUserDeposAsync();
        const report = RentaToolsController.servicePreview;
 
        const minOperatingHours = report!.previousOperatingHours;
        
        await this.setState({
            serviceExpenses,
            depos,
            minOperatingHours
        });
    }

    private async onSubmitAsync(): Promise<void> { 
        const request = new EditServiceInvoiceDetailsRequest();
        
        request.serviceId = this.state.serviceReport.id;
        request.invoiceNumber = this.state.invoiceNumber;
        request.cost = this.state.cost;
        request.operatingHours = (this.props.supportsOperatingHours) ? this.state.operatingHours : null;
        request.serviceExpenseId = (this.state.serviceExpense) ? this.state.serviceExpense.id : null;
        request.depoId = (this.state.depo) ? this.state.depo.id : null;
        request.locationType = this.state.locationType;
        request.comment = this.state.comment;
        request.date = RentaToolsController.servicePreview!.date;
        request.deviceId = RentaToolsController.servicePreview!.deviceId;

        const success = await this.props.onSubmit(this.modal, request);

        if(success) {
            await this.closeAsync();
        }
    }

    public async openAsync(serviceReport: ServiceReport, device: Device): Promise<void> {
        const serviceExpense: ServiceExpense | null = (serviceReport.serviceExpense)
                ? serviceReport.serviceExpense
                : null;

        const depo: Depo = (serviceReport.depo)
                ? serviceReport.depo
                : device.depo!;

        const locationType: ServiceLocationType = ((serviceReport.locationType !== undefined) && (serviceReport.locationType !== null))
                ? serviceReport.locationType
                : ServiceLocationType.Depot;

        const comment: string | null = (serviceReport.comment)
                ? serviceReport.comment
                : null;
        
        const operatingHours: number | null = (serviceReport.operatingHours)
            ? serviceReport.operatingHours 
            : null;

        const deposList = this.getDepoDropdown(this.state.depos, depo);

        const serviceExpensesList = this.getServiceExpensesDropdown(this.state.serviceExpenses, serviceExpense);

        await this.setState(
            {
                serviceReport,
                invoiceNumber: serviceReport.invoiceNumber,
                cost: serviceReport.cost,
                operatingHours,
                serviceExpense,
                depo,
                locationType,
                comment,
                deposList,
                serviceExpensesList
            }
        )

        await this.modal.openAsync(serviceReport);
    }


    private getDepoDropdown(depos: Depo[], selectedDepo: Depo): SelectListItem[] {
        if (depos && depos.length > 0) {
            return depos.map((depo) => this.getDepoListItem(depo, selectedDepo));
        }
        return [];
    }

    private getDepoListItem(depo: Depo, selectedDepo: Depo): SelectListItem {
        const listItem = new SelectListItem();
        listItem.value = depo.id;
        listItem.text = depo.name!;
        listItem.ref = depo;
        listItem.selected = (selectedDepo.id === depo.id);
        return listItem;
    }

    private getServiceExpensesDropdown(serviceExpenses: ServiceExpense[], selectedServiceExpense: ServiceExpense | null): SelectListItem[] {
        if (serviceExpenses.length > 0) {
            return serviceExpenses.map((serviceExpense) => this.getServiceExpensesListItem(serviceExpense, selectedServiceExpense));
        }
        return [];
    }

    private getServiceExpensesListItem(serviceExpense: ServiceExpense, selectedServiceExpense: ServiceExpense | null): SelectListItem {
        const listItem = new SelectListItem();
        listItem.value = serviceExpense.id;
        listItem.text = serviceExpense.name;
        listItem.ref = serviceExpense;
        listItem.selected = (selectedServiceExpense != null) && (selectedServiceExpense.id === serviceExpense.id);
        return listItem;
    }

    private get modal(): Modal<ServiceReport> {
        return this._modalRef.current!;
    }
    
    private get operatingHoursValid(): boolean {
        return (!this.props.supportsOperatingHours) || 
            ((this.state.operatingHours != null) 
                &&  (
                    (this.state.minOperatingHours == null || this.state.minOperatingHours <= this.state.operatingHours)
                &&
                    (this.props.maxOperatingHours == null || this.props.maxOperatingHours >= this.state.operatingHours)
            )
        )
    }

    public async closeAsync(): Promise<void> {
        // need to clear lists manually, otherwise dropdowns won't be updated during next openAsync call.
        this.setState({
            deposList: [],
            serviceExpensesList: []
        })

        await this.modal.closeAsync();
    }

    private getSelectedLocationTypeItem(): SelectListItem {
        if ((this.state.locationType !== undefined) && (this.state.locationType !== null)) {
            return EnumProvider.getServiceLocationTypeItem(this.state.locationType);
        }
        return EnumProvider.getServiceLocationTypeItem(ServiceLocationType.Depot);
    }

    private async onLocationTypeChangeAsync(item: SelectListItem | null): Promise<void> {
        if (!item || item.value == null) {
            return;
        }

        await this.setState({locationType: Number(item.value) as ServiceLocationType});
    }

    public render(): React.ReactNode {
        return (
            <Modal id={"editInvoiceModal"} ref={this._modalRef}
                   className={styles.editInvoiceModal}
                   title={Localizer.deviceServicePageLabelEditInvoice}
            >

                <Form className={styles.form}>

                    <Inline className={styles.inline}>

                        <TextInput name="invoiceNumber"
                                   id="invoiceNumber"
                                   label={Localizer.deviceServicePageInvoiceLabelInvoiceNumber}
                                   value={this.state.invoiceNumber}
                                   onChange={async (sender, value) => await this.setState({invoiceNumber: value})}

                        />

                        <NumberInput name="cost"
                                     id="cost"
                                     behaviour={NumberInputBehaviour.Restricted}
                                     step={0.01}
                                     hideArrows={true}
                                     label={Localizer.deviceServicePageInvoiceLabelCost}
                                     value={this.state.cost}
                                     onChange={async (sender, value) => await this.setState({cost: value})}
                        />

                    </Inline>
                    
                    {this.props.supportsOperatingHours &&
                        (<Inline className={styles.inline}>
                        <NumberInput name="operatingHours"
                                     id="operatingHours"
                                     behaviour={NumberInputBehaviour.ValidationOnChange}
                                     step={0.01}
                                     hideArrows={true}
                                     label={Localizer.deviceServicePageLabelOperatingHours}
                                     value={this.state.operatingHours}
                                     onChange={async (sender, value) => await this.setState({operatingHours: value})}
                        />
                    </Inline>)
                    }                    
                    
                    <Inline className={styles.inline}>

                        <Dropdown name="serviceExpense"
                                  id="serviceExpense"
                                  label={Localizer.deviceServicePageInvoiceLabelServiceExpense}
                                  items={this.state.serviceExpensesList}
                                  onChange={async (sender, value) => await this.setState({serviceExpense: value as ServiceExpense | null})}
                        />

                    </Inline>

                    <Inline className={styles.inline}>

                        <Dropdown name="depot"
                                  id="depot"
                                  label={Localizer.deviceServicePageLocationLabelDepot}
                                  items={this.state.deposList}
                                  required={true}
                                  onChange={async (sender, value) => await this.setState({depo: value as Depo | null})}
                        />

                        <Dropdown name="locationType"
                                  id="locationType"
                                  label={Localizer.deviceServicePageLocationLabelLocationType}
                                  items={EnumProvider.getServiceLocationTypeItems()}
                                  selectedItem={this.getSelectedLocationTypeItem()}
                                  required={true}
                                  onChange={async (sender, value) => this.onLocationTypeChangeAsync(value)}
                        />

                    </Inline>

                    <Inline className={this.css(styles.inline, styles.final)}>

                        <TextAreaInput id={"comment"}
                                       label={Localizer.deviceServicePageGeneralLabelComment}
                                       value={this.state.comment || ""}
                                       maxLength={RentaToolsConstants.maximumCommentLength}
                                       onChange={async (sender, value) => await this.setState({comment: value})}
                        />

                    </Inline>

                    <ButtonContainer className={styles.buttons}>
                        <Button type={ButtonType.Light} label={Localizer.genericCancel} onClick={async () => await this.closeAsync()} />
                        <Button type={ButtonType.Orange} label={Localizer.genericSave} onClick={async () => await this.onSubmitAsync()} />
                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
};