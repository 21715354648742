import {DevicePicturesFilter} from "@/models/Enums";

export default class ListDevicePicturesRequest {

    constructor(pictureTypes: DevicePicturesFilter[] = []) {
        this.types = pictureTypes;
    }
    
    public deviceId: string = "";

    public types: DevicePicturesFilter[] = [];

    public date: Date | null = null;

    public from: Date | null = null;

    public to: Date | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 50;

    public isEmpty(): boolean {
        return ((this.types == null) || (this.types.length == 0))
            && (!this.date)
            && (!this.from)
            && (!this.to);
    }

    public clear(): void {
        this.types = [];
        this.date = null;
        this.from = null;
        this.to = null;
    }

    resetPage() {
        this.pageNumber = 0;
        this.pageSize = 50;
    }
}