import styles from "./ErrorCodesTooltip.module.scss";
import Localizer from "@/localization/Localizer";
import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";

const ErrorCodesTooltip = () =>{
    return (
        <div>
            <div>
                <div className={Utility.css(styles.circle, styles.rent)}/> {Localizer.errorCodesOccurredOnRent}
            </div>
            <div>
                <div className={Utility.css(styles.circle, styles.depo)}/> {Localizer.errorCodesOccurredInDepo}
            </div>
        </div>
    )
}


export default ErrorCodesTooltip;