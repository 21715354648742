import {AthenaeumConstants, GeoCoordinate} from "@renta-apps/athenaeum-toolkit";
import DateFormatSelectionModel from "@/models/DateFormatSelectionModel";
import {ch} from "@renta-apps/athenaeum-react-common";
import {ReportItemType} from "@/models/Enums";

export default class RentaToolsConstants extends AthenaeumConstants {
    //5 min in milliseconds.
    public static readonly PictureCacheExpiration : number = 300000;

    public static readonly phaseMinimalNumber = 1;

    public static readonly applicationName: string = "RentaTools";

    // 10 MB
    public static readonly fileMaxSize: number = 10 * 1024 * 1024;

    // 15 MB
    public static readonly imageMaxSize: number = 15 * 1024 * 1024;

    // 60 MB
    public static readonly originalImageMaxSize: number = 60 * 1024 * 1024;

    public static readonly imageFileTypes: string[] = ["image/gif", "image/jpeg", "image/png"];

    //this is only way to make camera works on the Android 14
    public static readonly supportedImageFileTypes: string[] = [
        "image/*",
        "text/plain"
    ];

    public static readonly alertCloseDelay: number = 5000;

    public static readonly alertAnimationDelay: number = 500;

    public static readonly minimumCommentLength: number = 3;

    public static readonly maximumCommentLength: number = 1024;

    public static readonly maxResourceValue: number = 999999;

    public static readonly maximumReturnInspectionCommentLength : number = 1000;

    public static readonly itemsPerReturnInspectionPage : number = 50;

    public static readonly returnInspectionListViewSetting = "ReturnInspectionListSetting";

    public static readonly servicedPageListViewSetting = "ServicedPageListSettings";

    /// <summary>
    /// "2880"
    /// </summary>
    public static readonly lockServiceTimeoutInMinutes: number = 2880;

    public static readonly defaultGuid: string = "00000000-0000-0000-0000-000000000000";

    public static readonly defaultLocation: GeoCoordinate = new GeoCoordinate(60.192059, 24.945831);

    public static readonly pageHiddenTimestamp: string = "pageHiddenTimestamp";

    public static readonly pageReloadThresholdMinutes: number = 10;

    public static readonly intMinValue : number = -2147483648;
    public static readonly intMaxValue :number = 2147483648;

    // Feature flags

    public static featureFlagSyncEnabled: string = "SYNC_ENABLED";

    public static featureFlagServiceAndRepairEnabled: string = "SERVICE_AND_REPAIR_ENABLED";

    public static featureFlagQueueEnabled: string = "QUEUE_ENABLED";

    public static featureFlagStatisticsEnabled: string = "STATISTICS_ENABLED";

    public static featureFlagFuelingAndWashingEnabled: string = "FUELING_AND_WASHING_ENABLED";

    public static featureFlagAnnualInspectionsEnabled: string = "ANNUAL_INSPECTIONS_ENABLED";

    public static featureFlagGoogleAnalytics: string = "GOOGLE_ANALYTICS";

    public static featureFlagLoggingEnabled: string = "LOGGING_ENABLED";

    public static featureFlagAdminAiDeleteEnabled: string = "ADMIN_AI_DELETE_ENABLED";

    public static featureFlagDailyInfoEnabled: string = "DAILY_INFO_ENABLED";

    public static featureFlagRiMultiUser: string = "RI_MULTI_USER";

    public static featureFlagRiNavigationModuleEnabled: string = "RI_NAVIGATION_MODULE_ENABLED";

    public static featureFlagRemarksRepairedPdfEnabled: string = "REMARKS_REPAIRED_PDF_ENABLED";

    public static featureFlagRecognitionEnabled: string = "RECOGNITION_ENABLED";

    public static featureFlagBanOfUseEnabled: string = "BAN_OF_USE_ENABLED";

    public static featureFlagShowPhasesEnabled: string = "SHOW_PHASES_ENABLED";

    public static featureFlagLocationEnabled: string = "LOCATION_ENABLED";

    public static featureReturnInspectionSaveButtonEnabled : string = "RI_SUMMARY_PAGE_SHOW_SAVE_BUTTON";

    public static featureMeasureUploadTimeEnabled : string = "MEASURE_UPLOAD_TIME";

    public static featureErrorCodes : string = "DEVICE_ERROR_CODES";

    public static featureFlagTelematicEnabled: string = "TELEMATIC_ENABLED";

    public static speechRecognitionEnabled: string = "SPEECH_RECOGNITION";

    public static featureSaveFaultCodeRi : string = "SAVE_FAULTS_CODE_RI";

    public static featureFlagDetailsSyncEnabled: string = "DETAILSSYNCENABLED";

    public static featureFlagHomePageEnabled: string = "HOME_PAGE_ENABLED";

    public static featureNextServiceDateEnabled: string = "NEXT_SERVICE_DATE_ENABLED";

    public static featureRiCompletedAtFilterEnabled: string = "RI_COMPLETED_AT_ENABLED";

    public static featureFlagManualFaultsEnabled: string = "MANUAL_FAULTS";

    public static featureFlagFaultCodesWithoutConnect: string = "FAULT_CODES_WITHOUT_CONNECT";

    public static featureFlagServiceForecastList = "SERVICE_FORECAST_LIST";

    public static productGroupUtilizationUI =  "PRODUCT_GROUP_UTILIZATION_UI";

    public static dotDayMonthYear: string = "dd.MM.yyyy";
    public static dotMonthDayYear: string = "MM.dd.yyyy";
    public static dotYearMonthDay: string = "yyyy.MM.dd";

    public static dashDayMonthYear: string = "dd-MM-yyyy";
    public static dashMonthDayYear: string = "MM-dd-yyyy";
    public static dashYearMonthDay: string = "yyyy-MM-dd";

    public static slashDayMonthYear: string = "dd/MM/yyyy";
    public static slashMonthDayYear: string = "MM/dd/yyyy";
    public static slashYearMonthDay: string = "yyyy/MM/dd";

    public static supportedDateFormats: DateFormatSelectionModel[] = [
        {
            value: this.dotDayMonthYear,
            text: "31.12.2023",
        } as DateFormatSelectionModel,
        {
            value: this.dashDayMonthYear,
            text: "31-12-2023",
        } as DateFormatSelectionModel,
        {
            value: this.slashDayMonthYear,
            text: "31/12/2023",
        } as DateFormatSelectionModel,
        {
            value: this.dotMonthDayYear,
            text: "12.31.2023",
        } as DateFormatSelectionModel,
        {
            value: this.dashMonthDayYear,
            text: "12-31-2023",
        } as DateFormatSelectionModel,
        {
            value: this.slashMonthDayYear,
            text: "12/31/2023",
        } as DateFormatSelectionModel,
        {
            value: this.dotYearMonthDay,
            text: "2023.12.31",
        } as DateFormatSelectionModel,
        {
            value: this.dashYearMonthDay,
            text: "2023-12-31",
        } as DateFormatSelectionModel,
        {
            value: this.slashYearMonthDay,
            text: "2023/12/31",
        } as DateFormatSelectionModel,
    ];

    public static dateAttributes: string[] = [
        "DataService.Attributes.LastRent",
        "DataService.Attributes.MachineCreatedAt",
        "DataService.Attributes.LastReturnInspection",
        "DataService.Attributes.LastService",
        "DataService.Attributes.LastServiceFromErp",
        "DataService.Attributes.LastRepair",
        "DataService.Attributes.MaintenanceFrom",
        "DataService.Attributes.LastAnnualInspection",
        "DataService.Attributes.NextAnnualInspection",
    ];

    public static imageStepTypes: ReportItemType[] = [
        ReportItemType.Question,
        ReportItemType.QuestionPictures,
        ReportItemType.QuestionResource,
        ReportItemType.Pictures,
    ];

    public static CurrentContractAttributeName: string = "DataService.Attributes.CurrentContract";
    public static MaintenanceTaskIdAttributeName: string = "DataService.Attributes.MaintenanceId";
    public static LastServiceFromErpAttributeName: string = "DataService.Attributes.LastServiceFromErp";
    public static LastServiceOperatingHoursFromErpAttributeName: string = "DataService.Attributes.LastServiceOperatingHoursFromErp";

    public static get defaultLatitude(): number {
        switch (ch.country) {
            case "fi":
                return 60.192059;
            case "dk":
            case "da":
                return 55.613896;
            case "no":
            case "nb":
            case "nor":
                return 60.450172;
            case "pl":
                return 52.1029824;
            case "se":
            case "sv":
                return 59.0041252;
            default:
                return 60.192059;
        }
    };

    public static get defaultLongitude(): number {
        switch (ch.country) {
            case "fi":
                return 24.945831;
            case "dk":
            case "da":
                return 9.540399;
            case "no":
            case "nb":
            case "nor":
                return 8.2435016;
            case "pl":
                return 19.1338044;
            case "se":
            case "sv":
                return 14.5168652;
            default:
                return 24.945831;
        }
    };
}