
import {RouteData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

 class AnnualInspectionsPage {
    public elements = {
        statusText: () => cy.get('[id=inspection_status_text]'),
        upcomingTab: () => cy.get("#tab_upcomingAnnualInspections"),
        remarksTab: () => cy.get("#tab_remarksAnnualInspections"),
        pastTab: () => cy.get("#tab_pastAnnualInspections"),
        firstUpcomingDevice: () => cy.get('[data-cy="deviceType_upcoming_0"]'),
        firstRemarksDevice: () => cy.get('[data-cy="deviceType_remarks_0"]'),
        firstPastDevice: () => cy.get('[data-cy="deviceType_past_0"]'),
        legend: (postFix: string) => cy.get(`[id=legend_${postFix}]`),
        inspectionList: (type: string) => cy.get(`[data-cy="annual_inspection_list_${type}"]`),
    }

     public routes = {
        filters: {
            path: "api/device/getAnnualInspectionDeviceFilters",
            method: "POST",
        } as RouteData,
         findDevice: {
            path: "api/device/findDevice",
            method: "POST",
         } as RouteData,
         deviceAnnualInspections: {
            path: "api/AnnualInspection/ListDeviceAnnualInspections",
            method: "POST",
         } as RouteData,
         listAnnualInspectionDevices: {
            path: "api/device/listAnnualInspectionDevices",
            method: "POST",
         } as RouteData
     };

    public validateTabs() {
        this.elements.upcomingTab()
            .should('contain', Localizer.annualInspectionsPageTabUpcoming.toUpperCase());

        this.elements.remarksTab()
            .should('contain', Localizer.annualInspectionsPageTabRemarks.toUpperCase());

        this.elements.pastTab()
            .should('contain', Localizer.annualInspectionsPageTabPast.toUpperCase());
    }

    public validateDeviceType(type: string) {
        switch (type) {
            case "upcoming":
                this.elements.inspectionList(type)
                    .children()
                    .should('contain.text', 'JLG 1230ES');
                break;
            case "remarks":
                this.elements.inspectionList(type)
                    .children()
                    .should('contain.text', 'TEST DEVICE TYPE');
                break;
            case "past":
                this.elements.inspectionList(type)
                    .children()
                    .should('contain.text', 'TEST DEVICE TYPE');
                break;
            default:
                break;
        }
    }
}

export default new AnnualInspectionsPage();