import React, {Children, ReactNode, useState} from "react";
import styles from "@/components/ReturnInspectionDevicesList/ReturnInspectionDevicesList.module.scss";
import {
  Button,
  ButtonType,
  IconSize, Switch,
} from "@renta-apps/athenaeum-react-components";
import newStyles from "@/pages/NewUI.module.scss";
import { Utility } from "@renta-apps/athenaeum-toolkit";
import localStyles from './ExpandableMenu.module.scss';
import Localizer from "@/localization/Localizer";

interface IExpandableMenuProps {
  children?: ReactNode;
  onClick: () => void;
  buttonDescription : string;
  buttonState : boolean;
}

const ExpandableMenu = (props: IExpandableMenuProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const onClick = async () => {
    if (!props.children) {
      props.onClick();
    } else {
        setIsExpanded(!isExpanded);
    }
  };

  return (
    <div className={localStyles.wrapper}>
      <Button
        id={"expandableMenuButton"}  
        icon={{ name: "fas fa-bars", size: IconSize.X2 }}
        type={ButtonType.Orange}
        className={Utility.css(styles.filter, newStyles.filter)}
        onClick={async () => await onClick()}
      />
      {isExpanded && 
        <div className={localStyles.menu}>
          <div className={localStyles.switchContainer}>
            <Switch
                    inline
                    className={localStyles.arsenalSwitchWidget}
                    title={props.buttonDescription}
                    label={props.buttonDescription}
                    value={props.buttonState}
                    onChange={async (_, checked) => props.onClick()}/>
          </div>
          {props.children}
        </div>
      }
    </div>
  );
};

export default ExpandableMenu;
