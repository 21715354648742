import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";

class DashboardPage {
  public elements = {
    findDevice: () => cy.get("[id=input_deviceId]", { timeout: 10000 }),
    findDeviceButton: () => cy.get("[id=findDeviceButton]"),
  };

  public routes = {
    findDevice: {
      path: "/api/device/findDevice",
      method: "POST",
    } as RouteData,
    getOngoingReport: {
      path: "api/ReturnInspection/GetOngoingReport",
      method: "POST"
    } as RouteData,
    getDeviceLocation: {
      path: "api/device/getDeviceLocation",
      method: "POST",
    } as RouteData,
  };

  public openDevice(deviceId: string) {
    cy.wait(100);

      this.elements.findDevice().should("be.visible").wait(500).type(deviceId);

      this.elements.findDevice().invoke('val').as('inputDeviceId');

      cy.wait(500);

      cy.get('@inputDeviceId').then(($inputDeviceId) => {

        cy.wait(200);

        //this $inputDeviceId is string, using method from JQuery wrapper cause errors!!!
        if($inputDeviceId as unknown as string !== deviceId){
          this.elements.findDevice().should("be.visible").clear().type(deviceId);
        }

      })
    executeWithIntercept(() => {
        this.elements.findDeviceButton().click();
    }, [this.routes.findDevice, this.routes.getOngoingReport, this.routes.getDeviceLocation])
  }
}

export default new DashboardPage();