import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {UploadFullImageResponse} from "@/models/UploadFullImageResponse";
import {ch, ILayoutPage} from "@renta-apps/athenaeum-react-common";
import PreSignedUrlItem from "@/pages/Models/PreSignedUrlItem";
import EndpointPaths from "@/common/EndpointPaths";
import UploadFileResponse from "@/models/server/responses/UploadFileResponse";
import {ImageValidationResult} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ImageProcessor from "@/images/ImageProcessor";
import HttpClient from "@/common/HttpClient";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

class FileService {

    private async validateImageOnUpload(file : FileModel) : Promise<boolean> {
        let allowedExtensions =
            /(\.jpg|\.jpeg|\.png|\.heic)$/i;

        if(!allowedExtensions.exec(file.name)){
            await ch.alertErrorAsync(Localizer.componentImageInputImageTypeNotSupported, true);
            return false;
        }

        const index = file.name.lastIndexOf('.');
        const pureName = file.name.slice(0, index);
        const extension = file.name.slice(index).toLowerCase();

        file.name = `${pureName}${extension}`;

        return true;
    }
    
    private async uploadEnd(fileName : string) : Promise<void>{
        if(UnleashHelper.isEnabled(RentaToolsConstants.featureMeasureUploadTimeEnabled)) {
            await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.FilePaths.FileUploadEnd(fileName));
        }
    }
    
    private async convertImage(fileModel: FileModel) : Promise<FileModel | null>
    {
        const response = await HttpClient.postAsyncWithoutErrorHandling<UploadFileResponse>(EndpointPaths.FilePaths.ConvertImage, fileModel);

        switch (response.validationResult){
            case ImageValidationResult.ResolutionTooHigh:
                await ch.alertErrorAsync(Localizer.componentImageInputResolutionTooBig, true);
                break;
            case ImageValidationResult.UnsupportedFormat:
                await ch.alertErrorAsync(Localizer.componentImageInputImageTypeNotSupported, true);
                break;
        }

        return response.fileModel;
    }
    
    public async uploadFullImageAsync(file : FileModel) : Promise<UploadFullImageResponse | null> {
        await this.validateImageOnUpload(file);

        const layout: ILayoutPage = ch.getLayout();
        await layout.setSpinnerAsync(true);

        let fileModel = await ImageProcessor.convertImage(file);

        // Shrink image for local use to ease browser's memory usage
        const shrunkImage = await ImageProcessor.shrinkImage(fileModel.src);

        if(!shrunkImage.isSuccessful) {
            await layout.setSpinnerAsync(false);
            await ch.alertErrorAsync(Localizer.componentImageInputDamagedImage , true);
            return null;
        }

        // Get url to upload file to. Don't need to send image data
        let noBodyFile: FileModel = {...fileModel, src: ""};
        const preSignedUrl: PreSignedUrlItem =  await HttpClient.postAsyncWithoutErrorHandling<PreSignedUrlItem>(EndpointPaths.FilePaths.CreatePreSignedUrl, noBodyFile);

        if (preSignedUrl.useLocalInsteadOfS3) {
            await layout.setSpinnerAsync(false);

            const response = await this.convertImage(fileModel);

            if(response == null){
                return null;
            }

            await this.uploadEnd(response.name);

            return UploadFullImageResponse.Create(response, file.src, shrunkImage.src);
        }

        // Spinner while uploading
        try {

            // Image data to blob
            let fileBlob: Blob = await fetch(fileModel.src).then(r => r.blob());
            const requestOptions = {
                method: 'PUT',
                body: fileBlob,
            };

            // Upload image to S3
            let response: Response = await fetch(preSignedUrl.url, requestOptions);
            if (!response.ok) {
                const message: string = Localizer.get(Localizer.rentaToolsControllerErrorUploadingFullSizedImageFailed);
                await ch.alertErrorAsync(message, true);
                return null;
            }
        } finally {
            await this.uploadEnd(preSignedUrl.fileModel?.name!);
            await layout.setSpinnerAsync(false);
        }

        return UploadFullImageResponse.Create(preSignedUrl.fileModel!, file.src, shrunkImage.src);
    }
}

export default new FileService();