import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, CellAction, CellModel, Checkbox, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, IconSize, Inline, JustifyContent, RowModel, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import {ActionType} from "@/models/Enums";
import SaveWashingTypeResponse from "@/models/server/responses/SaveWashingTypeResponse";
import AddWashingTypeRequest from "@/models/server/requests/AddWashingTypeRequest";
import SaveWashingTypeRequest from "@/models/server/requests/SaveWashingTypeRequest";
import WashingType from "@/models/server/WashingType";
import DeleteResourceTypeResponse from "@/models/server/responses/DeleteResourceTypeResponse";
import Localizer from "@/localization/Localizer";

import styles from "./WashingTypesData.module.scss";

interface IWashingTypesDataProps {
}

interface IWashingTypesDataState {
    showDeleted: boolean
}

export default class WashingTypesData extends BaseComponent<IWashingTypesDataProps, IWashingTypesDataState> {

    state: IWashingTypesDataState = {
        showDeleted: false
    };

    private readonly _washingTypesGridRef: React.RefObject<Grid<WashingType>> = React.createRef();

    private readonly _washingTypesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 40,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.washingTypesPageGridNameLanguageItemName,
            accessor: nameof<WashingType>(w => w.name),
            minWidth: 250,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.washingTypesPageGridProductExternalIdLanguageItemName,
            accessor: nameof<WashingType>(w => w.productExternalId),
            minWidth: 100,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.washingTypesPageGridEquipmentTypeLanguageItemName,
            accessor: nameof<WashingType>(w => w.equipmentType),
            maxWidth: 300,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.washingTypesPageGridhourlyInvoicedLanguageItemName,
            accessor: nameof<WashingType>(w => w.hourlyInvoiced),
            transform: (_, value) => value ? "✓" : "",
            minWidth: 100,
            type: ColumnType.Boolean,
            textAlign: TextAlign.Center,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.resourceTypeIsInvoiceableLanguageItemName,
            accessor: nameof<WashingType>(w => w.isInvoiceable),
            transform: (_, value) => value ? "✓" : "",
            minWidth: 100,
            type: ColumnType.Boolean,
            textAlign: TextAlign.Center,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initWashingTypesOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.washingTypesPageGridSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processWashingTypeOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.washingTypesPageGridCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processWashingTypeOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.washingTypesPageGridDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processWashingTypeOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.washingTypesPageGridRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processWashingTypeOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get washingTypesGrid(): GridModel<WashingType> {
        return this._washingTypesGridRef.current!.model;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.washingTypesGrid.rows.some(row => !row.deleted && !row.model.id));
    }

    private async getWashingTypesAsync(): Promise<WashingType[]> {
        let washingTypes: WashingType[] = await this.washingTypesGrid.postAsync("api/classifier/getWashingTypes", null);
        if (!this.state.showDeleted) {
            washingTypes = washingTypes.filter(f => !f.isDeleted)
        }
        return washingTypes;
    }

    private initRow(row: RowModel<WashingType>): void {
        const model: WashingType = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = this.isValid(model);
        row.deleted = model.isDeleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private async addWashingTypeAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const washingType = new WashingType();

            const newRows: RowModel<WashingType>[] = await this.washingTypesGrid.insertAsync(0, washingType);

            const newRow: RowModel<WashingType> = newRows[0];
            const nameCell: CellModel<WashingType> = newRow.get("name");

            await nameCell.editAsync(true);
        }
    }

    private isValid(washingType: WashingType): boolean {
        return (!!washingType.name) && (!!washingType.productExternalId) && (!!washingType.equipmentType);
    }

    private async initWashingTypesOperationsAsync(cell: CellModel<WashingType>): Promise<void> {

        const model: WashingType = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<WashingType> = cell.actions[0];
        const cancelAction: CellAction<WashingType> = cell.actions[1];
        const deleteAction: CellAction<WashingType> = cell.actions[2];
        const restoreAction: CellAction<WashingType> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    private getDeleteConfirmation(cell: CellModel<WashingType>): string {
        const model: WashingType = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types require returned value to be a string, but has been like this forever
            ? null as any
            : Utility.format(Localizer.washingTypesPageConfirmationButtonDelete, `'${cell.model.name} (${cell.model.productExternalId})'`);
    }

    private async processWashingTypeOperationAsync(cell: CellModel<WashingType>, action: CellAction<WashingType>): Promise<void> {
        await ch.hideAlertAsync();

        const model: WashingType = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {

                const request = new AddWashingTypeRequest();
                request.name = model.name;
                request.productExternalId = model.productExternalId;
                request.equipmentType = model.equipmentType;
                request.hourlyInvoiced = model.hourlyInvoiced;
                request.isInvoiceable = model.isInvoiceable;

                const response: SaveWashingTypeResponse = await cell.grid.postAsync("api/classifier/addWashingType", request);

                if (response.washingTypeAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.washingTypesPageAlertErrorAsyncWashingTypeExist, model.name, model.productExternalId, model.equipmentType), true);
                    return;
                }

                cell.row.model = response.washingType!;

                await cell.row.saveAsync();

            } else {

                const request = new SaveWashingTypeRequest();
                request.id = model.id;
                request.name = model.name;
                request.productExternalId = model.productExternalId;
                request.equipmentType = model.equipmentType;
                request.hourlyInvoiced = model.hourlyInvoiced;
                request.isInvoiceable = model.isInvoiceable;

                const response: SaveWashingTypeResponse = await cell.grid.postAsync("api/classifier/saveWashingType", request);

                if (response.washingTypeAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.washingTypesPageAlertErrorAsyncWashingTypeExist, model.name, model.productExternalId, model.equipmentType), true);
                    return;
                }

                cell.row.model = response.washingType!;

                await cell.row.bindAsync();
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                const response: DeleteResourceTypeResponse = await cell.grid.postAsync("api/classifier/deleteWashingType", model.id);

                if (response.success) {
                    model.isDeleted = true;

                    await cell.row.setDeletedAsync(true);
                } else {
                    await ch.alertErrorAsync(Localizer.alertMessageResourceTypeCannotDelete.format(model.name, response.reportDefinitionsContainingResource.join(', ')));
                }
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await cell.grid.postAsync("api/classifier/restoreWashingType", model.id);

                model.isDeleted = false;
            }

            await cell.row.setDeletedAsync(false);
        }
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.washingTypesGrid.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.container}>

                <ToolbarContainer className={styles.toolbar}>


                    <Inline justify={JustifyContent.End}>

                        <Checkbox inline
                                  id={'showDeletedWashingTypes'}
                                  label={Localizer.userManagementPageCheckboxShowDeleted}
                                  value={this.state.showDeleted}
                                  onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                        />

                        <Button id={'reloadWashingTypes'}
                                title={Localizer.washingTypesPageButtonReload}
                                className="ml-1"
                                icon={{name: "far history", size: IconSize.Large}}
                                type={ButtonType.Info}
                                onClick={async () => await this.reloadAsync()}
                        />

                        <Button id={'addWashingType'}
                                icon={{name: "plus", size: IconSize.Large}}
                                type={ButtonType.Orange}
                                title={Localizer.washingTypesPageButtonAddWashingType}
                                onClick={async () => await this.addWashingTypeAsync()}
                        />

                    </Inline>

                </ToolbarContainer>

                <Grid responsive
                      id={'washingTypesGrid'}
                      className={styles.washingTypesGrid}
                      ref={this._washingTypesGridRef}
                      hovering={GridHoveringType.EditableCell}
                      odd={GridOddType.None}
                      minWidth="auto"
                      noDataText={Localizer.genericNoData}
                      columns={this._washingTypesColumns}
                      initRow={(row) => this.initRow(row)}
                      fetchData={async (_) => await this.getWashingTypesAsync()}
                />

            </div>
        );
    }
}