import {RouteData} from "@/helpers/CypressHelper";

class InvoiceDetailsPage {
    public elements = {
        header: () => cy.get('.athenaeum-page-container-header'),
        statusInfo: () => cy.get("#statusInfo"),
        approve: () => cy.get("#approveInvoice"),
        decline: () => cy.get("#declineInvoice"),
        confirmationDialog: () => cy.get("div.athenaeum-confirmation-dialog-opened"),
        fuelTypesDropdown: () => cy.get('#fuelTypes .athenaeum-dropdown-dropdown'),
        washingTypesDropdown: () => cy.get('#washingTypes .athenaeum-dropdown-dropdown'),
        additionalExpense: (index: number) => cy.get(`#additionalExpenseInvoiced_${index}`),
        rows: () => cy.get('@rows'),
    };

    public routes = {
    };
}

export default new InvoiceDetailsPage();