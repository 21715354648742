import {InvoiceStatusFilter} from "@/models/Enums";
import ReturnInspectionInvoice from "@/models/server/ReturnInspectionInvoice";

export default class ListInvoicesRequest {

    public status: InvoiceStatusFilter = InvoiceStatusFilter.NotProcessed;

    public deviceExternalId: string = "";

    public customerExternalId: string | null = null;

    public customerName: string | null = null;

    public constructionSiteExternalId: string | null = null;

    public constructionSiteName: string | null = null;

    public depotsIds: string[] = [];

    public inspectorIds: string[] = [];

    public contractExternalId: string | null = null;

    public from: Date | null = null;

    public to: Date | null = null;

    public pageNumber : number = 0;

    public pageSize : number = 0;
    public isEmpty(): boolean {
        return (!this.status)
            && (!this.deviceExternalId)
            && (!this.customerExternalId)
            && (!this.customerName)
            && (!this.constructionSiteExternalId)
            && (!this.constructionSiteName)
            && (!this.contractExternalId)
            && ((this.depotsIds == null) || (this.depotsIds.length == 0))
            && ((this.inspectorIds == null) || (this.inspectorIds.length == 0))
            && (!this.from)
            && (!this.to);
    }

    public clear(): void {
        this.status = InvoiceStatusFilter.NotProcessed;
        this.deviceExternalId = "";
        this.customerExternalId = null;
        this.customerName = null;
        this.constructionSiteExternalId = null;
        this.constructionSiteName = null;
        this.contractExternalId = null;
        this.depotsIds = [];
        this.inspectorIds = [];
        this.from = null;
        this.to = null;
    }

    public getDate(returnInspectionInvoice: ReturnInspectionInvoice): Date {
        return (returnInspectionInvoice.returnInspectionReport)
            ? returnInspectionInvoice.returnInspectionReport.startedAt
            : returnInspectionInvoice.createdAt;
    }

    public nextPage() : void{
        this.pageNumber++;
    }

    public resetPage() : void {
        this.pageNumber = 0;
        this.pageSize = 50;
    }
}