import React from "react";
import {Link, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import Localizer from "../../localization/Localizer";

import styles from "./AdminPage.module.scss"

interface IAdminPageProps {
}

interface IAdminPageState {
}

export default class AdminPage extends AuthorizedPage<IAdminPageProps, IAdminPageState> {

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.admin}>
                <PageHeader title={Localizer.topNavAdminManagement} subtitle={this.getContext().username}/>
                <PageRow>
                    <div className="col">
                        <div className={styles.menu} id={"admin_menu"}>
                            <Link className={styles.menuLink} route={PageDefinitions.applicationSettingsRoute}>{Localizer.applicationSettingsPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.userManagementRoute}>{Localizer.userManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.reportDefinitionsRoute}>{Localizer.reportDefinitionsPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.serviceActionsRoute}>{Localizer.serviceActionsAndTypesPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.mappingsRoute}>{Localizer.mappingsPageTitle}</Link>

                            {
                                <FeatureSwitch flagName={RentaToolsConstants.featureFlagAnnualInspectionsEnabled}>
                                    <Link className={styles.menuLink} route={PageDefinitions.annualInspectionVendorsRoute}>{Localizer.annualInspectionVendorsPageTitle}</Link>
                                </FeatureSwitch>
                            }

                            <Link className={styles.menuLink} route={PageDefinitions.deposRoute}>{Localizer.deposPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.annualInspectionTypesRoute}>{Localizer.adminPageInspectionTypeMapping}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.expensesRoute}>{Localizer.additionalExpensesPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.serviceExpensesRoute}>{Localizer.serviceExpensesPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.devicesManagementRoute}>{Localizer.devicesManagementPageTitle}</Link>
                                <FeatureSwitch flagName={RentaToolsConstants.featureFlagShowPhasesEnabled}>
                                    <Link className={styles.menuLink} route={PageDefinitions.phasePageRoute}>{Localizer.genericPhase}</Link>
                                </FeatureSwitch>
                            <Link className={styles.menuLink} route={PageDefinitions.adminConsoleRoute}>{"Admin console"}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.dataManagementRoute}>{"Data management"}</Link>
                        </div>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}