import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Modal, PageContainer} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Device from "../Models/Device";
import PageDefinitions from "../../providers/PageDefinitions";
import DeviceInfo from "@/models/server/DeviceInfo";
import ListUnInspectedDevicesRequest from "../../models/server/requests/ListUnInspectedDevicesRequest";
import FindDeviceResponse from "../../models/server/responses/FindDeviceResponse";
import GetMaintenanceDevicesFiltersResponse from "../../models/server/responses/GetMaintenanceDevicesFiltersResponse";
import {DeviceListOrder, ReturnInspectionStatus} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ReturnInspectionDevicesList from "@/components/ReturnInspectionDevicesList/ReturnInspectionDevicesList";
import FiltersData from "@/pages/Models/FiltersData";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "../RentaToolsController";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ReturnedDevicesPage.module.scss";
import newStyles from "../NewUI.module.scss";


interface IReturnedDevicesPageProps  {
}

interface IReturnedDevicesPageState {
    unInspectedRequest: ListUnInspectedDevicesRequest;
    filtersData: FiltersData;
    unInspectedCount: number;
}

export default class ReturnedDevicesPage extends AuthorizedPage<IReturnedDevicesPageProps, IReturnedDevicesPageState> {

    state: IReturnedDevicesPageState = {
        unInspectedRequest: ReturnedDevicesPage.initializeListUnInspectedDevicesRequest(),
        filtersData: new FiltersData(),
        unInspectedCount: 0,
    };

    private readonly _pageInfoModalRef: React.RefObject<Modal> = React.createRef();

    private static initializeListUnInspectedDevicesRequest(): ListUnInspectedDevicesRequest {
        var request = new ListUnInspectedDevicesRequest();

        request.returnInspectionStatus = [ReturnInspectionStatus.PartlyCompleted.toString(), ReturnInspectionStatus.InProgress.toString(), ReturnInspectionStatus.Waiting.toString()];

        var restoredRequest = UserInteractionDataStorage.restore("ListDevices", request);

        restoredRequest.pageNumber = 0;
        restoredRequest.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;
        restoredRequest.modified = true;
        
        if(!UnleashHelper.isEnabled(RentaToolsConstants.productGroupUtilizationUI)){
            restoredRequest.orderBy = DeviceListOrder.ByDate;
        }

        return restoredRequest;
    }

    private async openDeviceAsync(deviceInfo: DeviceInfo): Promise<void> {
        const response: FindDeviceResponse = await RentaToolsController.findDeviceAsync(deviceInfo.externalId);
        const device: Device | null = response.device;

        if (device) {

            RentaToolsController.device = device;
            await PageRouteProvider.redirectAsync(PageDefinitions.deviceRoute);

        } else {

            RentaToolsController.device = null;

            RentaToolsController.failedReportItems = null;

            await this.alertErrorAsync(Localizer.dashboardPageNotFound, true);
        }
    }

    private async fetchUnInspectedCountAsync(): Promise<void> {
        const unInspectedCount: number = await RentaToolsController.getUnInspectedDeviceCount(this.state.unInspectedRequest);

        const unInspectedRequest = this.state.unInspectedRequest;

        unInspectedRequest.modified = false;

        this.setState({unInspectedCount, unInspectedRequest})
    }

    private async fetchUnInspectedDevicesAsync(): Promise<DeviceInfo[]> {
        UserInteractionDataStorage.set("ListDevices", this.state.unInspectedRequest);
        let unInspectedDevices: DeviceInfo[] = await ReturnInspectionController.listDevicesAsync(this.state.unInspectedRequest);

        if (this.state.unInspectedRequest.modified) {
            const unInspectedCount: number = 0;

            const unInspectedRequest = this.state.unInspectedRequest;
            unInspectedRequest.modified = false;

            this.setState({unInspectedCount, unInspectedRequest})
        }

        await this.reRenderAsync();
        return unInspectedDevices;
    }

    private get unInspectedDevicesTitle(): string {
        return (this.state.unInspectedCount === 1)
            ? Utility.format(Localizer.devicesListTitleUnInspectedSingular, this.state.unInspectedCount)
            : Utility.format(Localizer.devicesListTitleUnInspected, this.state.unInspectedCount);
    }

    private get pageInfoModal(): Modal {
        return this._pageInfoModalRef.current!;
    }

    public manualPropsCallback(): Promise<void> {
        return this.pageInfoModal.openAsync();
    }

    protected get customInfoModal(): boolean {
        return true;
    }

    private async onClosePageInfoModalAsync(): Promise<void> {
        await this.pageInfoModal.closeAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const response: GetMaintenanceDevicesFiltersResponse = await RentaToolsController.getMaintenanceDevicesFiltersAsync();

        this.setState({
            filtersData: this.getFiltersData(response)
        });
    }

    public getFiltersData(response: GetMaintenanceDevicesFiltersResponse): FiltersData {
        const combinedData = new FiltersData();
        combinedData.depos = (!response.unInspected.depos.length && !response.inspected.depos.length) ? response.userDepos : [...response.unInspected.depos, ...response.inspected.depos].distinct(d => d.id);
        combinedData.productGroups = [...response.unInspected.productGroups, ...response.inspected.productGroups].distinct(d => d.productGroupId);
        combinedData.deviceTypes = [...response.unInspected.deviceTypes, ...response.inspected.deviceTypes].distinct(t => t);
        combinedData.rentalObjectGroups = [...response.unInspected.rentalObjectGroups, ...response.inspected.rentalObjectGroups].distinct(d => d.groupCode);

        return combinedData;
    }

    public getManual(): string {
        return Localizer.dashboardPageGetManual;
    }

    public renderTitle(): React.ReactNode {
        if (!this.state.unInspectedCount) {
            return (
                <div>
                    <span className={this.css(newStyles.subTitle)} onClick={async () => await this.fetchUnInspectedCountAsync()}>
                        {`${this.toMultiLines(Localizer.devicesListDeviceCountLoad)}`}
                    </span>
                </div>)
        }

        if (this.state.unInspectedCount) {
            return (
                <div>
                    <span className={this.css(newStyles.subTitle)} onClick={async () => await this.fetchUnInspectedCountAsync()}>
                        {`${this.toMultiLines(this.unInspectedDevicesTitle)}`}
                    </span>
                </div>
            )
        }
        
        return <div>{this.toMultiLines(this.unInspectedDevicesTitle)}</div>
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.returnedDevicesPage, newStyles.pageContainer, newStyles.returnedDevicesPage)}>

                <ReturnInspectionDevicesList sticky returnInspectionList
                                             title={this.unInspectedDevicesTitle}
                                             noDataText={Localizer.devicesListNoDataTextNoUnInspectedDevices}
                                             request={this.state.unInspectedRequest}
                                             filtersData={this.state.filtersData}
                                             fetchData={async () => await this.fetchUnInspectedDevicesAsync()}
                                             onDeviceOpen={async (sender, deviceInfo) => await this.openDeviceAsync(deviceInfo)}
                                             renderTitle={() => this.renderTitle()}
                />

                <Modal id={"returnedDevices-info-modal"}
                       ref={this._pageInfoModalRef}
                       className={rentaToolsStyles.infoPageModal}
                       title={Localizer.returnedDevicesPageInfoHeader}
                       onClose={async () => await this.onClosePageInfoModalAsync()}
                >
                    {this.getPageInfo(Localizer.returnedDevicesPageInfoContent)}
                </Modal>

            </PageContainer>
        );
    }
}