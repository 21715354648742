import React from "react";
import {
    ITabContainerClassNames,
    PageContainer,
    PageHeader,
    Tab,
    TabContainer,
    TabContainerHeaderStyleType,
    TabRenderType
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import ListInvoicesRequest from "@/models/server/requests/ListInvoicesRequest";
import ReturnInspectionInvoiceFiltersData from "@/models/server/ReturnInspectionInvoiceFiltersData";
import InvoicesList from "@/pages/InvoicesPage/InvoicesList/InvoicesList";
import ReturnInspectionInvoice from "@/models/server/ReturnInspectionInvoice";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import Invoice from "@/models/server/Invoice";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {InvoicesTypes} from "@/models/Enums";
import InvoicePageParameters from "@/models/InvoicePageParameters";
import GetReturnInspectionInvoicesFiltersResponse
    from "@/models/server/responses/GetReturnInspectionInvoicesFiltersResponse";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./InvoicesPage.module.scss";
import newStyles from "@/pages/NewUI.module.scss";

export interface IInvoicesPageProps {
}

interface IInvoicesPageState {
    request: ListInvoicesRequest;
    manualInvoicesRequest: ListInvoicesRequest;
    skippedInvoicesRequest: ListInvoicesRequest;
    filtersData: ReturnInspectionInvoiceFiltersData;
    manualInvoicesFiltersData: ReturnInspectionInvoiceFiltersData;
    skippedInspectionFiltersData: ReturnInspectionInvoiceFiltersData;
}

export default class InvoicesPage extends AuthorizedPage<IInvoicesPageProps, IInvoicesPageState> {

    state: IInvoicesPageState = {
        request: InvoicesPage.initializeListInvoicesRequest(),
        manualInvoicesRequest: InvoicesPage.initializeManualInvoicesRequest(),
        skippedInvoicesRequest: InvoicesPage.initializeSkippedInvoicesRequest(),
        filtersData: new ReturnInspectionInvoiceFiltersData(),
        manualInvoicesFiltersData: new ReturnInspectionInvoiceFiltersData(),
        skippedInspectionFiltersData: new ReturnInspectionInvoiceFiltersData(),
    };

    private readonly _listRef: React.RefObject<InvoicesList> = React.createRef();
    private readonly _manualInvoicesRef: React.RefObject<InvoicesList> = React.createRef();
    private readonly _skippedInspectionRef: React.RefObject<InvoicesList> = React.createRef();

    private static initializeListInvoicesRequest(): ListInvoicesRequest {
        const request = UserInteractionDataStorage.restore("ListInvoicesRequest", new ListInvoicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return request;
    }

    private static initializeManualInvoicesRequest(): ListInvoicesRequest {
        const request = UserInteractionDataStorage.restore("ManualInvoicesRequest", new ListInvoicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return request;
    }

    private static initializeSkippedInvoicesRequest(): ListInvoicesRequest {
        const request = UserInteractionDataStorage.restore("SkippedInvoicesRequest", new ListInvoicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return request;
    }

    private async onInvoiceOpenAsync(invoice: Invoice, invoiceType: InvoicesTypes): Promise<void> {
        const params: InvoicePageParameters = {
            invoiceId: invoice.id,
            invoicesType: invoiceType
        };
        await PageRouteProvider.redirectAsync(PageDefinitions.invoiceDetails(params));
    }

    private async fetchInvoicesAsync(): Promise<ReturnInspectionInvoice[]> {
        UserInteractionDataStorage.set("ListInvoicesRequest", this.state.request);
        return await this.postAsync("api/invoice/ListInvoicesReturnInspection", this.state.request);
    }

    private async fetchManualInvoicesAsync(): Promise<Invoice[]> {
        UserInteractionDataStorage.set("ManualInvoicesRequest", this.state.manualInvoicesRequest);
        return await this.postAsync("api/invoice/ListManualInvoices", this.state.manualInvoicesRequest);
    }

    private async fetchSkippedInvoicesAsync(): Promise<Invoice[]> {
        UserInteractionDataStorage.set("SkippedInvoicesRequest", this.state.skippedInvoicesRequest);
        return await this.postAsync("api/invoice/SkippedReturnInspectionInvoices", this.state.skippedInvoicesRequest);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: GetReturnInspectionInvoicesFiltersResponse = await this.postAsync("api/invoice/getReturnInspectionInvoicesFilters");

        await this.setState({
            filtersData: response.data,
            manualInvoicesFiltersData: response.data,
            skippedInspectionFiltersData: response.data
        });
    }

    public getManual(): string {
        return Localizer.invoicesPageManual;
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: rentaToolsStyles.tabContainerNavTabs,
            headerLink: rentaToolsStyles.tabHeaderLink,
            navigationContainer: this.css(rentaToolsStyles.stickyNavigationContainer, styles.stickyNavigationContainer)
        };
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.workOrders)}>

                <ArsenalPageRow>
                    <div className={this.css("w-100", newStyles.col)}>
                        <PageHeader className={this.css(styles.pageHeader, rentaToolsStyles.pageHeader)} title={Localizer.invoicesPageHeaderTitle}/>
                    </div>
                </ArsenalPageRow>

                <TabContainer id={"invoicesTabContainer"}
                              renderType={TabRenderType.Once}
                              headerStyleType={TabContainerHeaderStyleType.Underline}
                              className={rentaToolsStyles.deviceListPageTabs}
                              classNames={this.tabContainerClassNames}
                >

                    <Tab id={"invoices"}
                         title={Localizer.invoicesListInspectionInvoices.toUpperCase()}
                         className={rentaToolsStyles.deviceListPageTab}
                    >
                        <ArsenalPageRow className={newStyles.row}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <InvoicesList ref={this._listRef}
                                              modalTitle={Localizer.invoicesPageModalTitleFilters}
                                              noDataText={Localizer.invoicesPageInvoicesListNoData}
                                              request={this.state.request}
                                              filtersData={this.state.filtersData}
                                              fetchData={async () => await this.fetchInvoicesAsync()}
                                              onInvoiceOpen={async (_, invoice) => await this.onInvoiceOpenAsync(invoice, InvoicesTypes.Invoice)}
                                />

                            </div>

                        </ArsenalPageRow>

                    </Tab>

                    <Tab id={"manualInvoices"}
                         title={Localizer.invoicesListManualInvoices.toUpperCase()}
                         className={rentaToolsStyles.deviceListPageTab}
                    >
                        <ArsenalPageRow className={newStyles.row}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <InvoicesList ref={this._manualInvoicesRef}
                                              modalTitle={Localizer.invoicesPageManual}
                                              noDataText={Localizer.invoicesPageInvoicesListNoData}
                                              request={this.state.manualInvoicesRequest}
                                              filtersData={this.state.manualInvoicesFiltersData}
                                              fetchData={async () => await this.fetchManualInvoicesAsync()}
                                              onInvoiceOpen={async (_, invoice) => await this.onInvoiceOpenAsync(invoice, InvoicesTypes.ManualInvoices)}
                                />

                            </div>

                        </ArsenalPageRow>

                    </Tab>

                    <Tab id={"skippedInspectionRows"}
                         title={Localizer.invoiceListSkippedInspections.toUpperCase()}
                         className={rentaToolsStyles.deviceListPageTab}
                    >
                        <ArsenalPageRow className={newStyles.row}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <InvoicesList ref={this._skippedInspectionRef}
                                              modalTitle={Localizer.invoicesPageManual}
                                              noDataText={Localizer.invoicesPageInvoicesListNoData}
                                              request={this.state.skippedInvoicesRequest}
                                              filtersData={this.state.skippedInspectionFiltersData}
                                              fetchData={async () => await this.fetchSkippedInvoicesAsync()}
                                              onInvoiceOpen={async (_, invoice) => await this.onInvoiceOpenAsync(invoice, InvoicesTypes.SkippedInspectionInvoice)}
                                />

                            </div>

                        </ArsenalPageRow>
                    </Tab>

                </TabContainer>
            </PageContainer>
        );
    }
}