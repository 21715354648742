import React from "react";
import {
  ch,
  PageRouteProvider,
  ReactUtility,
} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    Checkbox,
    Dropdown, DropdownAlign,
    DropdownOrderBy,
    DropdownRequiredType,
    Form,
    Icon,
    IconSize,
    Inline,
    InlineType,
    IStringInputModel,
    NumberInput,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListItem,
    Switch,
    TextAreaInput,
    TextInput,
    TwoColumns,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import BaseReportDefinition from "@/pages/Models/BaseReportDefinition";
import ReportDefinition from "../Models/ReportDefinition";
import QuestionReportItem from "../Models/QuestionReportItem";
import ReportDefinitionItem from "../Models/ReportDefinitionItem";
import SaveReportDefinitionRequest from "../../models/server/requests/SaveReportDefinitionRequest";
import {
  FaultLevel,
  MeasuringOperators,
  ReportDefinitionType,
  ReportItemType,
  ResourceItemType,
} from "@/models/Enums";
import DeleteReportDefinitionResponse from "@/models/server/responses/DeleteReportDefinitionResponse";
import PageDefinitions from "@/providers/PageDefinitions";
import CheckItem from "@/pages/Models/CheckItem";
import ChecksReportItem from "@/pages/Models/ChecksReportItem";
import FuelType from "@/models/server/FuelType";
import WashingType from "@/models/server/WashingType";
import AdBlue from "@/models/server/AdBlue";
import ResourceReportItem from "@/pages/Models/ResourceReportItem";
import QuestionResourceReportItem from "@/pages/Models/QuestionResourceReportItem";
import BaseClassifier from "@/models/server/BaseClassifier";
import StatefulNumberInput from "@/components/StatefulNumberInput/StatefulNumberInput";
import Phase from "@/models/server/Phase";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import AdditionalExpense from "@/models/server/AdditionalExpense";
import EnumProvider from "@/providers/EnumProvider";
import RentaToolsController from "../RentaToolsController";
import UnleashHelper from "@/helpers/UnleashHelper";
import { FeatureSwitch } from "@/providers/FeatureSwitch";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ReportDefinitionPage.module.scss";

interface IReportDefinitionPageProps {
}

interface IReportDefinitionPageState {
    definition: ReportDefinition;
    fuelTypes: FuelType[];
    washingTypes: WashingType[],
    adBlues: AdBlue[]
    additionalExpenses: AdditionalExpense[]
    phases : Phase[],
    errorCodeEnabled: boolean,

}

export default class ReportDefinitionPage extends AuthorizedPage<IReportDefinitionPageProps, IReportDefinitionPageState> {

    state: IReportDefinitionPageState = {
        definition: new ReportDefinition(),
        fuelTypes: [],
        washingTypes: [],
        adBlues: [],
        additionalExpenses: [],
        phases: [],
        errorCodeEnabled: false,
    };

    private _validationErrorMessage: string[] = [];

    private name: IStringInputModel = {value: ""};

    private get definition(): ReportDefinition {
        return this.state.definition!;
    }

    private get items(): ReportDefinitionItem[] {
        return this.definition.items;
    }

    private get fuelTypes(): FuelType[] {
        return this.state.fuelTypes;
    }

    private get existingFuelTypes(): FuelType[] {
        return this.fuelTypes.where(item => !item.isDeleted);
    }

    private get washingTypes(): WashingType[] {
        return this.state.washingTypes;
    }

    private get existingWashingTypes(): WashingType[] {
        return this.washingTypes.filter(item => !item.isDeleted);
    }

    private get adBlueTypes(): AdBlue[] {
        return this.state.adBlues;
    }

    private get existingAdBlueTypes(): AdBlue[] {
        return this.adBlueTypes.filter(item => !item.isDeleted);
    }

    private get additionalExpenses(): AdditionalExpense[] {
        return this.state.additionalExpenses;
    }

    private get existingAdditionalExpenses(): AdditionalExpense[] {
        return this.additionalExpenses.filter(item => !item.isDeleted);
    }

    private get telematicsStepSelected(): boolean {
        return this.definition.items.some(item => item.isTelematicsStep);
    }

    private get telematicsStepId(): string | null {
        const telematicsStep = this.definition.items.find(item => item.isTelematicsStep);
        return (telematicsStep) ? telematicsStep.id : null;
    }

    private getCheckItems(item: ReportDefinitionItem): CheckItem[] {
        return item.checks ?? (item.checks = []);
    }

    private isTitleRequired(type: ReportItemType): boolean {
        return type === ReportItemType.Question || type === ReportItemType.QuestionPictures || type === ReportItemType.QuestionResource;
    }

    private isPhasesValid(reportDefinition: ReportDefinition): boolean {
        if (!UnleashHelper.isEnabled(RentaToolsConstants.featureFlagShowPhasesEnabled)) {
            return true;
        }

        if (reportDefinition.type !== ReportDefinitionType.ReturnInspection) {
            return true;
        }

        const phases = reportDefinition.items.filter(p => p.phase !== null);

        if (phases.length > 0) {
            return phases.length === reportDefinition.items.length;
        }

        return true;
    }

    private isAdditionalExpensesValid(reportDefinition: ReportDefinition): boolean {
        if (reportDefinition.type !== ReportDefinitionType.ReturnInspection) {
            return true;
        }

        const invalidSteps: ReportDefinitionItem[] = reportDefinition.items.where(item => (item.additionalExpensesCanBeAdded) && (item.additionalExpenseTypeIds == null || item.additionalExpenseTypeIds.length == 0));

        return !(invalidSteps != null && invalidSteps.length > 0);
    }

    private isValid(): boolean {
        const namesValid: boolean = this.items.every((item: ReportDefinitionItem) => !!item.name);
        const titlesValid: boolean = this.items.every((item: ReportDefinitionItem) => this.isTitleRequired(item.type) ? !!item.title : true);
        const stepsExist: boolean = this.definition.items.length > 0;
        const checkStepsValid: boolean = this.isValidCheckSteps(this.definition);
        const questionResourceStepsValid: boolean = this.isValidQuestionResourceSteps(this.definition);
        const resourceStepsValid: boolean = this.isValidResourceSteps(this.definition);
        const isMinMaxValid: boolean = this.isIsValidMinMax(this.definition);
        const phaseValid = this.isPhasesValid(this.definition);
        const additionalExpensesValid = this.isAdditionalExpensesValid(this.definition);

        if (!phaseValid) {
            this.addInfoToErrorMessage(null, Localizer.reportDefinitionPageReturnInspectionPhaseErrorMessage);
        }

        if (!additionalExpensesValid) {
            this.addInfoToErrorMessage(null, Localizer.reportDefinitionPageReturnInspectionAdditionalExpensesErrorMessage);
        }

        if (!namesValid || !titlesValid) {
            this.addInfoToErrorMessage(null, Localizer.reportDefinitionPageMessageNameOrTitleMissed);
        }

        return ((namesValid) && (titlesValid) && (stepsExist) && (checkStepsValid) && (questionResourceStepsValid) && (resourceStepsValid) && (isMinMaxValid) && (phaseValid) && (additionalExpensesValid));
    }

    private isIsValidMinMax(item: ReportDefinition): boolean {
        const checkSteps = item.items.filter(step => this.hasMinMax(step));
        return (checkSteps.length == 0) || (checkSteps.every(checkStep => this.isValidMinMax(checkStep)));

    }

    private addInfoToErrorMessage(item: ReportDefinitionItem | null, text: string): void {
        if ((item != null) && ((item.name != null && item.name.length > 0) || (!!item.title))) {
            this._validationErrorMessage.push(`${item.name || item.title}`);
        } else {
            this._validationErrorMessage.push(text);
        }
    }

    private isValidMinMax(item: ReportDefinitionItem): boolean {

        let isValid: boolean = true;

        if (item.min != null && item.max != null) {
            isValid = item.max > item.min;

            if (item.default != null) {
                isValid = item.default >= item.min && item.max >= item.default;
            }

            if (!isValid) {
                this.addInfoToErrorMessage(item, Localizer.reportDefinitionPageMessageMinMaxIncorrect);
            }

            return isValid;
        }
        return isValid;
    }

    private isValidCheckSteps(item: ReportDefinition): boolean {
        const checkSteps = item.items.filter(step => step.type === ReportItemType.Checks || step.type === ReportItemType.AdvancedChecks);
        return (checkSteps.length == 0) || (checkSteps.every(checkStep => this.isValidCheckStep(checkStep as ChecksReportItem)));
    }

    private isValidCheckStep(item: ChecksReportItem): boolean {
        const isValid: boolean = ((item.checks != null) && (item.checks.length > 0) && (item.checks.every((item: CheckItem) => !!item.name)));

        if (!isValid) {
            this.addInfoToErrorMessage(item, Localizer.enumReportItemTypeChecks);
        }

        return isValid;
    }

    private isValidQuestionResourceSteps(item: ReportDefinition): boolean {
        const questionResourceSteps = item.items.filter(step => step.type === ReportItemType.QuestionResource);
        return (questionResourceSteps.length == 0) || (questionResourceSteps.every(checkStep => this.isValidResourceItem(checkStep as QuestionResourceReportItem)));
    }

    private isValidResourceSteps(item: ReportDefinition): boolean {
        const resourceSteps = item.items.filter(step => step.type === ReportItemType.Resource);
        return ((resourceSteps.length == 0) || (resourceSteps.every(checkStep => this.isValidResourceItem(checkStep as ResourceReportItem)))) && this.typesHaveSingleOrNoOccurrence(resourceSteps);
    }

    private typesHaveSingleOrNoOccurrence(items: ReportDefinitionItem[]): boolean {
        const fuelingSteps = items.count(item => item.resourceType == ResourceItemType.Fueling);
        const adBlueSteps = items.count(item => item.resourceType == ResourceItemType.AdBlue);
        const cleaningSteps = items.count(item => item.resourceType == ResourceItemType.Washing);
        const operatingSteps = items.count(item => item.resourceType == ResourceItemType.Operating);

        if (fuelingSteps <= 1 && adBlueSteps <= 1 && cleaningSteps <= 1 && operatingSteps <= 1) {
            return true;
        } else {
            this.addInfoToErrorMessage(null, Localizer.reportDefinitionPageDuplicateResourceTypeErrorMessage);

            return false;
        }
    }

    private getClassifiersForDefaultSelection(typeIds: string[] | null, type: ResourceItemType): BaseClassifier[] {

        if (!typeIds || !typeIds.length) {
            return [];
        }

        let types: BaseClassifier[];

        switch (type) {
            case ResourceItemType.Washing:
                types = this.existingWashingTypes;
                break;
            case ResourceItemType.Fueling:
                types = this.existingFuelTypes;
                break;
            case ResourceItemType.AdBlue:
                types = this.existingAdBlueTypes;
                break;
            default:
                types = [];
        }

        return types.filter(type => typeIds?.some(id => id == type.id));
    }

    private isValidResourceItem(item: QuestionResourceReportItem | ResourceReportItem): boolean {
        let isValid: boolean;

        switch (item.resourceType) {
            case ResourceItemType.Fueling:
                const existingFuelTypesIds: string[] = this.existingFuelTypes.map(item => item.id);
                isValid = this.canSave(item, this.fuelTypes.length, existingFuelTypesIds);

                if (!isValid) {
                    this.addInfoToErrorMessage(item, Localizer.enumResourceItemTypeFueling);
                }

                return isValid;

            case ResourceItemType.Washing:
                const existingWashingTypesIds: string[] = this.existingWashingTypes.map(item => item.id);
                isValid = this.canSave(item, this.washingTypes.length, existingWashingTypesIds);

                if (!isValid) {
                    this.addInfoToErrorMessage(item, Localizer.enumResourceItemTypeWashing);
                }

                return isValid;

            case ResourceItemType.AdBlue:
                const existingAdBlueTypesIds: string[] = this.existingAdBlueTypes.map(item => item.id);
                isValid = this.canSave(item, this.adBlueTypes.length, existingAdBlueTypesIds);

                if (!isValid) {
                    this.addInfoToErrorMessage(item, Localizer.enumResourceItemTypeAdBlue);
                }

                return isValid;

            case ResourceItemType.Operating:
                return true;

            default:
                return false;
        }
    }

    private canSave(item: QuestionResourceReportItem | ResourceReportItem, availableResourceTypesAmount: number, existingResourceTypesIds: string[]): boolean {
        return (availableResourceTypesAmount > 0)
            ? (item.valueTypeIds != null && item.valueTypeIds.length > 0) &&
            !(item.valueTypeIds
                .some(id => !existingResourceTypesIds
                    .includes(id)))
            : true;
    }

    private get subtitle(): string {
        return this.definition.name;
    }

    private async upAsync(item: ReportDefinitionItem, index: number): Promise<void> {
        if (index > 0) {

            const previous: ReportDefinitionItem = this.items[index - 1];

            this.items[index - 1] = item;
            this.items[index] = previous;

            await this.reRenderAsync();
        }
    }

    private async downAsync(item: ReportDefinitionItem, index: number): Promise<void> {
        if (index < this.items.length - 1) {

            const next: ReportDefinitionItem = this.items[index + 1];

            this.items[index + 1] = item;
            this.items[index] = next;

            await this.reRenderAsync();
        }
    }

    private async deleteAsync(index: number): Promise<void> {

        this.items.splice(index, 1);

        await this.reRenderAsync();
    }

    private async restoreReportDefinitionAsync(): Promise<void> {
        if (this.definition.id) {

            await this.postAsync("api/reportDefinition/restoreReportDefinition", this.definition.id);

            this.definition.isDeleted = false;

            this.setState({definition: this.definition});

            await ch.flyoutMessageAsync(Localizer.reportDefinitionsPageRestore);
        }
    }

    private async deleteReportDefinitionAsync(): Promise<void> {
        if (this.definition.id) {
            const response: DeleteReportDefinitionResponse = await this.postAsync("api/reportDefinition/deleteReportDefinition", this.definition.id);

            if (response.removedPermanently) {

                await PageRouteProvider.redirectAsync(PageDefinitions.reportDefinitionsRoute);

                await ch.flyoutMessageAsync(Localizer.reportDefinitionsPageDeletePermanently);

            } else {

                this.definition.isDeleted = true;

                this.setState({definition: this.definition});

                await ch.flyoutMessageAsync(Localizer.reportDefinitionsPageDelete);
            }
        }
    }

    private async saveChangesAsync(): Promise<void> {
        if (!this.isValid()) {
            let message: string = Localizer.reportDefinitionsPageNotValid;

            if (this._validationErrorMessage.length > 0) {
                const items: string = this._validationErrorMessage.join(", ");
                message += `\n${Localizer.reportDefinitionPageMessageErrorsDetected}`.format(items);
            }

            await ch.flyoutErrorAsync(message);

            this._validationErrorMessage = [];
            return;
        }

        const request = new SaveReportDefinitionRequest();

        request.reportDefinitionId = this.definition!.id;
        request.items = this.items;
        request.name = this.name.value;

        const definition: ReportDefinition = await this.postAsync("api/reportDefinition/saveReportDefinitionSteps", request);

        RentaToolsController.reportDefinition = definition;

        this.setState({definition});
        this._validationErrorMessage = [];
        await ch.flyoutMessageAsync(Localizer.reportDefinitionsPageDataSaved);
    }

    private async setOperatorType(reportDefinitionItem: ReportDefinitionItem, selectedItem: SelectListItem | null): Promise<void> {
        if (selectedItem == null || selectedItem.value == null) {
            return;
        }

        const measuringOperator: MeasuringOperators = Number(selectedItem.value);

        if (reportDefinitionItem.passedValueOperator != measuringOperator) {
            reportDefinitionItem.passedValueOperator = measuringOperator;

            await this.setState({definition: this.definition});
        }
    }

    private async setCheckItemNameAsync(item: CheckItem, value: string): Promise<void> {
        if (item.name != value) {
            item.name = value;
        }
    }

    private async addCheckItemAsync(item: ReportDefinitionItem): Promise<void> {
        const checks = this.getCheckItems(item);
        checks.push(new CheckItem());

        await this.setState({definition: this.definition});
    }

    private async addAdvancedCheckItemAsync(item: ReportDefinitionItem): Promise<void> {
        const checks = this.getCheckItems(item);
        checks.push(new CheckItem());

        await this.setState({definition: this.definition});
    }

    private async setAdvancedCheckItemRequired(item: ReportDefinitionItem, checkItem: CheckItem, value: boolean): Promise<void> {
        const checks = this.getCheckItems(item);
        const index = checks.indexOf(checkItem);

        checks[index].isRequired = value;

        await this.setState({definition: this.definition});
    }

    private async removeCheckItemAsync(item: ReportDefinitionItem, index: number): Promise<void> {
        if (item.checks && item.checks.length) {
            item.checks.splice(index, 1);

            this.items.map(i => i === item);

            await this.setState({definition: this.definition});
        }
    }

    private async setResourceType(reportDefinitionItem: ReportDefinitionItem, selectedItem: SelectListItem | null): Promise<void> {
        if (selectedItem == null || selectedItem.value == null) {
            return;
        }

        const resourceItemType: ResourceItemType = Number(selectedItem.value);

        if (reportDefinitionItem.resourceType != resourceItemType) {
            reportDefinitionItem.resourceType = resourceItemType;

            await this.setState({definition: this.definition});
        }
    }

    private async setFuelTypesAsync(serviceType: ReportDefinitionItem, values: FuelType[]): Promise<void> {
        serviceType.valueTypeIds = values.map(fuelingType => fuelingType.id);

        await this.setState({definition: this.definition});
    }

    private async setWashingTypesAsync(serviceType: ReportDefinitionItem, values: WashingType[]): Promise<void> {
        serviceType.valueTypeIds = values.map(washingType => washingType.id);

        await this.setState({definition: this.definition});
    }

    private async setDefaultWashingTypeAsync(item: ReportDefinitionItem, value: BaseClassifier | null): Promise<void> {
        item.defaultWashingTypeId = value?.id ?? null;

        await this.setState({definition: this.definition});
    }

    private async setDefaultFuelTypeAsync(item: ReportDefinitionItem, value: BaseClassifier | null): Promise<void> {
        item.defaultFuelTypeId = value?.id ?? null;

        await this.setState({definition: this.definition});
    }

    private async setDefaultAdBlueTypeAsync(item: ReportDefinitionItem, value: BaseClassifier | null): Promise<void> {
        item.defaultAdBlueTypeId = value?.id ?? null;

        await this.setState({definition: this.definition});
    }

    private async setAdBluesAsync(serviceType: ReportDefinitionItem, values: AdBlue[]): Promise<void> {
        serviceType.valueTypeIds = values.map(adBlue => adBlue.id);

        await this.setState({definition: this.definition});
    }

    private async toggleMinFaultLevel(reportDefinitionItem: ReportDefinitionItem, enabled: boolean): Promise<void> {
        reportDefinitionItem.minFaultLevel = enabled
            ? (reportDefinitionItem.minFaultLevel == null)
                ? FaultLevel.Minor
                : reportDefinitionItem.minFaultLevel
            : null;

        await this.setState({definition: this.definition});
    }

    private async toggleAddAdditionalExpenses(reportDefinitionItem: ReportDefinitionItem, enabled: boolean): Promise<void> {
        reportDefinitionItem.additionalExpensesCanBeAdded = enabled;

        if (!enabled) {
            reportDefinitionItem.additionalExpenseTypeIds = [];
        }

        await this.setState({definition: this.definition});
    }

    private async setAdditionalExpensesAsync(definitionItem: ReportDefinitionItem, additionalExpenses: AdditionalExpense[]): Promise<void> {
        definitionItem.additionalExpenseTypeIds = additionalExpenses.map(additionalExpense => additionalExpense.id);

        await this.setState({definition: this.definition});
    }

    private async toggleTelematicsStep(reportDefinitionItem: ReportDefinitionItem, enabled: boolean): Promise<void> {
        reportDefinitionItem.isTelematicsStep = enabled;

        await this.setState({definition: this.definition});
    }

    private async toggleFaultLevel(item: CheckItem, enabled: boolean): Promise<void> {
        item.faultLevel = enabled
            ? FaultLevel.Minor
            : null;

        await this.setState({definition: this.definition});
    }

    private async setMinFaultLevel(reportDefinitionItem: ReportDefinitionItem, selectedItem: SelectListItem | null): Promise<void> {
        if (selectedItem == null || selectedItem.value == null) {
            return;
        }

        const faultLevel: FaultLevel = Number(selectedItem.value);

        if (reportDefinitionItem.minFaultLevel != faultLevel) {
            reportDefinitionItem.minFaultLevel = faultLevel;

            await this.setState({definition: this.definition});
        }
    }

    private async setFaultLevel(item: CheckItem, selectedItem: SelectListItem | null): Promise<void> {
        if (selectedItem == null || selectedItem.value == null) {
            return;
        }

        const faultLevel: FaultLevel = Number(selectedItem.value);

        if (item.faultLevel != faultLevel) {
            item.faultLevel = faultLevel;

            this.setState({definition: this.definition});
        }
    }

    private async changeStepType(reportDefinitionItem: ReportDefinitionItem, selectedItem: SelectListItem): Promise<void> {
        if (selectedItem == null || selectedItem.value == null) {
            return;
        }

        switch (Number(selectedItem.value)) {
            case ReportItemType.Pictures:
                reportDefinitionItem.type = ReportItemType.Pictures;
                break;
            case ReportItemType.QuestionPictures:
                reportDefinitionItem.type = ReportItemType.QuestionPictures;
                break;
            case ReportItemType.Question:
                reportDefinitionItem.type = ReportItemType.Question;
                break;
            case ReportItemType.Resource:
                reportDefinitionItem.type = ReportItemType.Resource;
                break;
            case ReportItemType.MeasuringResource:
                reportDefinitionItem.type = ReportItemType.MeasuringResource;
                break;
            case ReportItemType.QuestionResource:
                reportDefinitionItem.type = ReportItemType.QuestionResource;
                break;
            case ReportItemType.Checks:
                reportDefinitionItem.type = ReportItemType.Checks;
                break;
            case ReportItemType.ErrorsCodes:
                reportDefinitionItem.type = ReportItemType.ErrorsCodes;
                break;
            case ReportItemType.AdvancedChecks:
                reportDefinitionItem.type = ReportItemType.AdvancedChecks;
                break;
        }

        reportDefinitionItem.checks = (reportDefinitionItem.type === ReportItemType.Checks)
            ? reportDefinitionItem.checks || []
            : null;

        if (reportDefinitionItem.type === ReportItemType.MeasuringResource) {
            if (reportDefinitionItem.passedValue == null) {
                reportDefinitionItem.passedValue = reportDefinitionItem.min!;
            }
            if (reportDefinitionItem.passedValueOperator == null) {
                reportDefinitionItem.passedValueOperator = MeasuringOperators.Bigger;
            }
        } else {
            reportDefinitionItem.passedValue = null;
            reportDefinitionItem.passedValueOperator = null;
        }

        if (this.hasResourceType(reportDefinitionItem)) {
            if (reportDefinitionItem.resourceType == null) {
                reportDefinitionItem.resourceType = ResourceItemType.Fueling;
            }
        } else {
            reportDefinitionItem.resourceType = null;
            reportDefinitionItem.valueTypeId = null;
            reportDefinitionItem.valueTypeIds = [];
        }

        if (this.hasMinMax(reportDefinitionItem)) {
            if (reportDefinitionItem.min == null) {
                reportDefinitionItem.min = 0;
            }
            if ((reportDefinitionItem.max == null) || (reportDefinitionItem.max === 0)) {
                reportDefinitionItem.max = RentaToolsConstants.maxResourceValue;
            }
            if (reportDefinitionItem.step == null) {
                reportDefinitionItem.step = 0.5;
            }
            if (reportDefinitionItem.default == null) {
                reportDefinitionItem.default = reportDefinitionItem.min;
            }
        } else {
            reportDefinitionItem.max = null;
            reportDefinitionItem.min = null;
            reportDefinitionItem.step = null;
            reportDefinitionItem.default = null;
        }

        if (!this.hasMinFaultLevel(reportDefinitionItem)) {
            reportDefinitionItem.minFaultLevel = null;
        }

        await this.setState({definition: this.definition});
    }

    public async changePhase(reportDefinitionItem: ReportDefinitionItem, selectedItem: Phase | null): Promise<void> {
        reportDefinitionItem.phase = selectedItem;
    }

    private async addAsync(index: number, after: boolean = true): Promise<void> {
        let item = new QuestionReportItem();
        item.type = ReportItemType.Question;

        let newIndex: number = (after) ? index + 1 : index;
        this.items.splice(newIndex, 0, item);

        await this.setState({definition: this.definition});
    }

    public getManual(): string {
        return Localizer.reportDefinitionPageManual;
    }

    private async setReportDefinitionManual(item: ReportDefinitionItem, value: string): Promise<void> {
        item.manual = value;
    }

    private async setReportDefinitionTitle(item: ReportDefinitionItem, value: string): Promise<void> {
        item.title = value;
    }

    private async setReportDefinitionName(item: ReportDefinitionItem, value: string): Promise<void> {
        item.name = value;
    }

    private async setReportDefinitionDefault(item: ReportDefinitionItem, value: number): Promise<void> {
        item.default = value;
    }

    private async setReportDefinitionStep(item: ReportDefinitionItem, value: number): Promise<void> {
        item.step = value;
    }

    private async setPassedValue(item: ReportDefinitionItem, value: number): Promise<void> {
        item.passedValue = value;
    }

    private async setReportDefinitionMin(item: ReportDefinitionItem, value: number, defaultInput: React.RefObject<StatefulNumberInput>): Promise<void> {
        item.min = value;

        if (item.default != null && item.default < value) {
            item.default = value;
            defaultInput.current?.setState({value: value})

        }

        defaultInput.current?.setState({min: value})
    }

    private async setReportDefinitionMax(item: ReportDefinitionItem, value: number, defaultInput: React.RefObject<StatefulNumberInput>): Promise<void> {
        item.max = value;

        defaultInput.current?.setState({max: value})
    }

    private async setStepPicture(item: ReportDefinitionItem, value: string): Promise<void> {
        item.icon = value;
    }

    private hasMinFaultLevel(item: ReportDefinitionItem): boolean {
        return item.type == ReportItemType.Question || item.type == ReportItemType.QuestionResource || item.type == ReportItemType.QuestionPictures;
    }

    private hasMinMax(item: ReportDefinitionItem): boolean {
        return item.type == ReportItemType.Resource || item.type == ReportItemType.QuestionResource || item.type == ReportItemType.MeasuringResource;
    }

    private hasResourceType(item: ReportDefinitionItem): boolean {
        return item.type == ReportItemType.Resource || item.type == ReportItemType.QuestionResource;
    }

    private isResourceTypeApplicable(item: ReportDefinitionItem, resourceType: ResourceItemType): boolean {
        return (item.resourceType == resourceType) &&
            ((item.type == ReportItemType.Resource) || (item.type == ReportItemType.QuestionResource));
    }

    private getResourceTypeAlert(resourceLabel: string, resourceType: ResourceItemType): React.ReactNode {
        return <span>{ReactUtility.toMultiLines(Localizer.reportDefinitionPageNoAvailableResourceTypes.format(resourceLabel, EnumProvider.getResourceItemTypeText(resourceType)))}</span>;
    }

    public async initializeAsync(): Promise<void> {
        const definition: BaseReportDefinition | null = RentaToolsController.reportDefinition;

        const fuelTypes: FuelType[] = await RentaToolsController.getFuelTypesAsync();

        const washingTypes: WashingType[] = await RentaToolsController.getWashingTypesAsync();

        const adBlues: AdBlue[] = await RentaToolsController.getAdBluesAsync();

        const additionalExpenses: AdditionalExpense[] = await RentaToolsController.getAdditionalExpensesAsync();

        const phases: Phase[] = (await RentaToolsController.getPhasesAsync()).phases;

        if ((definition) && (definition.type == ReportDefinitionType.ReturnInspection)) {

            const returnInspectionDefinition = definition as ReportDefinition;

            const errorCodeEnabled = returnInspectionDefinition.items.some(p => p.type === ReportItemType.ErrorsCodes);

            this.name.value = definition.name;

            await this.setState({
                definition: returnInspectionDefinition,
                fuelTypes, washingTypes, adBlues, additionalExpenses, phases, errorCodeEnabled
            });
        }
    }

    private renderStep(item: ReportDefinitionItem, index: number): React.ReactNode {
        if (item.type === ReportItemType.ErrorsCodes) {
            return null;
        }

        //this component state is too large. Changing state cause to rerender page with visible lag on input.
        const defaultInputRef = React.createRef<StatefulNumberInput>();

        return (
            <React.Fragment key={ch.getId()}>
                <tr key={index}>
                    <td>
                        <span>
                            <TextInput id={`icon_${index}`}
                                       readonly={this.definition.isDeleted}
                                       prepend={item.icon != null && <Icon name={item.icon!}/>}
                                       label={Localizer.reportDefinitionsPageStepIcon}
                                       value={item.icon!}
                                       onChange={async (input, value) => await this.setStepPicture(item, value)}
                            />

                            <TextInput required
                                       id={`name_${index}`}
                                       readonly={this.definition.isDeleted}
                                       label={Localizer.reportDefinitionsPageStepName}
                                       value={item.name!}
                                       onChange={async (input, value) => await this.setReportDefinitionName(item, value)}
                            />

                            <TextInput id={`title_${index}`}
                                       required={this.isTitleRequired(item.type!)}
                                       readonly={this.definition.isDeleted}
                                       label={Localizer.reportDefinitionsPageStepTitle}
                                       value={item.title}
                                       onChange={async (input, value) => await this.setReportDefinitionTitle(item, value)}
                            />

                            <Dropdown noFilter noWrap required
                                      id={`type_${index}`}
                                      disabled={this.definition.isDeleted}
                                      orderBy={DropdownOrderBy.None}
                                      label={Localizer.reportDefinitionsPageStepType}
                                      items={EnumProvider.getReportItemTypeItems().filter(p => p.value !== ReportItemType.ErrorsCodes.toString())}
                                      selectedItem={EnumProvider.getReportItemTypeItem(item.type)}
                                      onChange={async (input, value) => await this.changeStepType(item, value!)}
                            />
                            
                                       
                            <FeatureSwitch flagName={RentaToolsConstants.featureFlagTelematicEnabled}>
                                 <Checkbox inline
                                           id={`telematics_${index}`}
                                           label={Localizer.localizerReportDefinitionTelematicStep}
                                           className={styles.checkbox}
                                           inlineType={InlineType.Right}
                                           value={item.isTelematicsStep}
                                           readonly={this.definition.isDeleted || (this.telematicsStepSelected && this.telematicsStepId != item.id)}
                                           onChange={async (sender, value) => await this.toggleTelematicsStep(item, value)}
                                 />
                            </FeatureSwitch>
                            
                            <FeatureSwitch flagName={RentaToolsConstants.featureFlagShowPhasesEnabled}>
                                <Dropdown noFilter noWrap
                                          id={`phase_${index}`}
                                          label={Localizer.genericPhase}
                                          disabled={this.definition.isDeleted}
                                          orderBy={DropdownOrderBy.None}
                                          items={this.state.phases}
                                          selectedItem={item.phase}
                                          onChange={async (input, value) => await this.changePhase(item, value!)}
                                />
                            </FeatureSwitch>
                            {
                                (this.hasMinFaultLevel(item)) &&
                                (
                                    <React.Fragment>

                                        <Checkbox inline
                                                  id={`fault_level_enabled_${index}`}
                                                  className={styles.checkbox}
                                                  readonly={this.definition.isDeleted}
                                                  label={Localizer.reportDefinitionPageCheckboxGenerateFault}
                                                  inlineType={InlineType.Right}
                                                  value={item.minFaultLevel != null}
                                                  onChange={async (sender, value) => await this.toggleMinFaultLevel(item, value)}
                                        />

                                        {
                                            ((item as QuestionReportItem).minFaultLevel != null) &&
                                            (
                                                <Dropdown required
                                                          id={`fault_level_${index}`}
                                                          label={Localizer.reportDefinitionPageDropdownLabelFaultLevel}
                                                          items={EnumProvider.getFaultLevelItems()}
                                                          selectedItem={EnumProvider.getFaultLevelItem(item.minFaultLevel || FaultLevel.Minor)}
                                                          disabled={this.definition.isDeleted}
                                                          orderBy={DropdownOrderBy.None}
                                                          onChange={async (sender, listItem) => await this.setMinFaultLevel(item, listItem)}
                                                />
                                            )
                                        }

                                    </React.Fragment>
                                )
                            }

                            <TextAreaInput id={`manual_${index}`}
                                           readonly={this.definition.isDeleted}
                                           rows={3}
                                           label={Localizer.reportDefinitionsPageStepManual}
                                           value={item.manual!}
                                           onChange={async (input, value) => await this.setReportDefinitionManual(item, value)}
                            />

                            {
                                (this.hasMinMax(item)) &&
                                (
                                    <React.Fragment>

                                        <NumberInput id={`min_${index}`}
                                                     readonly={this.definition.isDeleted}
                                                     label={Localizer.reportDefinitionsPageStepMinValue}
                                                     step={0.5}
                                                     value={item.min!}
                                                     onChange={async (input, value) => await this.setReportDefinitionMin(item, value, defaultInputRef)}
                                        />

                                        <TwoColumns>
                                            <NumberInput id={`max_${index}`}
                                                         readonly={this.definition.isDeleted || item.max === RentaToolsConstants.maxResourceValue}
                                                         label={Localizer.reportDefinitionsPageStepMaxValue}
                                                         step={0.5}
                                                         value={item.max!}
                                                         onChange={async (input, value) => await this.setReportDefinitionMax(item, value, defaultInputRef)}
                                            />

                                            <Checkbox id={`checkbox_max_${index}`}
                                                      className={this.css(styles.checkbox, styles.auto)}
                                                      label={Localizer.reportDefinitionsPageUnlimited}
                                                      value={item.max === RentaToolsConstants.maxResourceValue}
                                                      onChange={async (sender, value) => {
                                                          await this.setReportDefinitionMax(item, value ? RentaToolsConstants.maxResourceValue : 0, defaultInputRef);
                                                          await this.reRenderAsync();
                                                      }}
                                            />

                                        </TwoColumns>

                                        <StatefulNumberInput id={`input_default_${index}`}
                                                             ref={defaultInputRef}
                                                             readonly={this.definition.isDeleted}
                                                             label={Localizer.reportDefinitionsPageStepDefaultValue}
                                                             step={0.5}
                                                             value={item.default!}
                                                             min={item.min!}
                                                             max={item.max!}
                                                             onChange={async (input, value) => await this.setReportDefinitionDefault(item, value)}
                                        />

                                    </React.Fragment>
                                )
                            }

                            {
                                ((item.type == ReportItemType.MeasuringResource) || (item.type == ReportItemType.QuestionResource) || (item.type == ReportItemType.Resource)) &&
                                (
                                    <NumberInput label={Localizer.reportDefinitionPageNumberInputLabelStep}
                                                 readonly={this.definition.isDeleted}
                                                 step={0.000001}
                                                 value={item.step!}
                                                 onChange={async (input, value) => await this.setReportDefinitionStep(item, value)}
                                    />
                                )
                            }

                            {
                                (item.type == ReportItemType.MeasuringResource) &&
                                (
                                    <React.Fragment>

                                        <NumberInput label={Localizer.reportDefinitionPageNumberInputLabelPassedValue}
                                                     readonly={this.definition.isDeleted}
                                                     step={0.5}
                                                     value={item.passedValue!}
                                                     onChange={async (input, value) => await this.setPassedValue(item, value)}
                                        />

                                        <Dropdown id={`resource_operator_${index}`}
                                                  required={item.type === ReportItemType.MeasuringResource}
                                                  disabled={this.definition.isDeleted}
                                                  label={Localizer.reportDefinitionPageDropdownLabelOperation}
                                                  items={EnumProvider.getMeasuringOperatorsItems()}
                                                  selectedItem={EnumProvider.getMeasuringOperatorsItem(item.passedValueOperator!)}
                                                  onChange={async (input, listItem) => await this.setOperatorType(item, listItem)}
                                        />

                                    </React.Fragment>
                                )
                            }

                            {
                                (item.type == ReportItemType.Checks) &&
                                (
                                    <React.Fragment>

                                        {
                                            this.getCheckItems(item).map((checkItem, itemIndex) => (

                                                <div key={`check_item_${index}_${itemIndex}`} className={"d-flex align-items-center"}>

                                                    <TextInput required
                                                               id={`checkItemName_${index}_${itemIndex}`}
                                                               readonly={this.definition.isDeleted}
                                                               label={Localizer.reportDefinitionPageTextInputCheckName}
                                                               value={checkItem.name || ""}
                                                               onChange={(_, value) => this.setCheckItemNameAsync(checkItem, value)}
                                                    />

                                                    {
                                                        (this.getCheckItems(item).length !== 1) &&
                                                        (
                                                            <Icon className={"ml-2 cursor-pointer"}
                                                                  name={"times-circle"}
                                                                  size={IconSize.Large}
                                                                  disabled={this.definition.isDeleted}
                                                                  onClick={async () => await this.removeCheckItemAsync(item, index)}
                                                            />
                                                        )
                                                    }

                                                </div>
                                            ))
                                        }

                                        <Button id={`addCheckItemButton_${index}`}
                                                label={Localizer.reportDefinitionPageButtonLabelAddCheckItem}
                                                disabled={this.definition.isDeleted}
                                                icon={{name: "plus"}}
                                                onClick={async () => await this.addCheckItemAsync(item)}
                                        />

                                    </React.Fragment>
                                )
                            }

                            {
                                (item.type == ReportItemType.AdvancedChecks) &&
                                (
                                    <React.Fragment>

                                        {
                                            this.getCheckItems(item).map((checkItem, checkIndex) => (

                                                <div key={`advanced_check_item_${index}_${checkIndex}`} className={styles.backgroundBlue}>
                                                    <div className={"d-flex align-items-center"}>

                                                        <TextInput required
                                                                   readonly={this.definition.isDeleted}
                                                                   label={Localizer.reportDefinitionPageTextInputCheckName}
                                                                   value={checkItem.name || ""}
                                                                   onChange={(_, value) => this.setCheckItemNameAsync(checkItem, value)}
                                                        />
                                                        {
                                                            (this.getCheckItems(item).length !== 1) &&
                                                            (
                                                                <Icon className={"ml-2 cursor-pointer"}
                                                                      disabled={this.definition.isDeleted}
                                                                      name={"times-circle"}
                                                                      size={IconSize.Large}
                                                                      onClick={async () => await this.removeCheckItemAsync(item, checkIndex)}
                                                                />
                                                            )
                                                        }

                                                    </div>

                                                    <Checkbox inline
                                                              id={`advanced_check_Required_${index}_${checkIndex}`}
                                                              className={styles.checkbox}
                                                              readonly={this.definition.isDeleted}
                                                              inlineType={InlineType.Right}
                                                              label={Localizer.reportDefinitionPageAdvancedCheckRequired}
                                                              value={checkItem.isRequired}
                                                              onChange={async (sender, value) => await this.setAdvancedCheckItemRequired(item, checkItem, value)}
                                                    />


                                                    <Checkbox inline
                                                              id={`fault_level_enabled_advanced_checks_${index}_${checkIndex}`}
                                                              className={styles.checkbox}
                                                              readonly={this.definition.isDeleted}
                                                              inlineType={InlineType.Right}
                                                              label={Localizer.reportDefinitionPageCheckboxGenerateFault}
                                                              value={checkItem.faultLevel != null}
                                                              onChange={async (sender, value) => await this.toggleFaultLevel(checkItem, value)}
                                                    />

                                                    {
                                                        (checkItem.faultLevel != null) &&
                                                        (
                                                            <Dropdown id={`fault_level_advanced_checks_${index}_${checkIndex}`} required
                                                                      disabled={this.definition.isDeleted}
                                                                      label={Localizer.reportDefinitionPageDropdownLabelFaultLevel}
                                                                      items={EnumProvider.getFaultLevelItems()}
                                                                      selectedItem={EnumProvider.getFaultLevelItem(checkItem.faultLevel || FaultLevel.Minor)}
                                                                      orderBy={DropdownOrderBy.None}
                                                                      onChange={async (sender, listItem) => await this.setFaultLevel(checkItem, listItem)}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }

                                        <Button label={Localizer.reportDefinitionPageButtonLabelAddCheckItem}
                                                disabled={this.definition.isDeleted}
                                                icon={{name: "plus"}}
                                                onClick={async () => await this.addAdvancedCheckItemAsync(item)}
                                        />

                                    </React.Fragment>
                                )
                            }

                            {
                                (this.hasResourceType(item)) &&
                                (
                                    <Dropdown id={`resource_type_${index}`}
                                              disabled={this.definition.isDeleted}
                                              required={this.hasResourceType(item)}
                                              label={Localizer.reportDefinitionsPageStepResourceType}
                                              items={EnumProvider.getResourceItemTypeItems()}
                                              selectedItem={EnumProvider.getResourceItemTypeItem((item.resourceType == null) ? ResourceItemType.Fueling : item.resourceType)}
                                              onChange={async (input, listItem) => await this.setResourceType(item, listItem)}
                                    />
                                )
                            }

                            {
                                (this.isResourceTypeApplicable(item, ResourceItemType.Fueling)) &&
                                (
                                    (this.fuelTypes != null && this.fuelTypes.length > 0)
                                        ?
                                        (
                                            <>
                                                <Dropdown multiple required
                                                          id={`fuel_type_${index}`}
                                                          disabled={this.definition.isDeleted}
                                                          requiredType={DropdownRequiredType.Restricted}
                                                          label={Localizer.reportDefinitionPageFuelType}
                                                          items={this.existingFuelTypes}
                                                          selectedItems={item.valueTypeIds || []}
                                                          onChange={async (sender) => await this.setFuelTypesAsync(item, sender.selectedItems)}
                                                />
                                                <Dropdown id={`fuel_type_default_${index}`}
                                                          disabled={this.definition.isDeleted}
                                                          requiredType={DropdownRequiredType.Manual}
                                                          label={Localizer.reportDefinitionsPageStepDefaultValue}
                                                          items={this.getClassifiersForDefaultSelection(item.valueTypeIds, ResourceItemType.Fueling)}
                                                          selectedItem={this.existingFuelTypes.find(type => type.id == item.defaultFuelTypeId)}
                                                          onChange={async (sender) => await this.setDefaultFuelTypeAsync(item, sender.selectedItem)}
                                                />
                                            </>
                                        )
                                        :
                                        this.getResourceTypeAlert(Localizer.reportDefinitionPageFuelType, ResourceItemType.Fueling)
                                )
                            }

                            {
                                (this.isResourceTypeApplicable(item, ResourceItemType.Washing)) &&
                                (
                                    (this.washingTypes != null && this.washingTypes.length > 0)
                                        ?
                                        (
                                            <>
                                                <Dropdown multiple required
                                                          id={`washing_type_${index}`}
                                                          disabled={this.definition.isDeleted}
                                                          requiredType={DropdownRequiredType.Restricted}
                                                          label={Localizer.reportDefinitionPageWashingType}
                                                          items={this.existingWashingTypes}
                                                          selectedItems={item.valueTypeIds || []}
                                                          onChange={async (sender) => await this.setWashingTypesAsync(item, sender.selectedItems)}
                                                />
                                                <Dropdown id={`washing_type_default_${index}`}
                                                          disabled={this.definition.isDeleted}
                                                          label={Localizer.reportDefinitionsPageStepDefaultValue}
                                                          items={this.getClassifiersForDefaultSelection(item.valueTypeIds, ResourceItemType.Washing)}
                                                          selectedItem={this.existingWashingTypes.find(type => type.id == item.defaultWashingTypeId)}
                                                          onChange={async (sender) => await this.setDefaultWashingTypeAsync(item, sender.selectedItem)}
                                                />
                                            </>
                                        )
                                        :
                                        this.getResourceTypeAlert(Localizer.reportDefinitionPageWashingType, ResourceItemType.Washing)
                                )
                            }

                            {
                                (this.isResourceTypeApplicable(item, ResourceItemType.AdBlue)) &&
                                (
                                    (this.adBlueTypes != null && this.adBlueTypes.length > 0)
                                        ?
                                        (
                                            <>
                                                <Dropdown multiple required
                                                          id={`adBlue_type_${index}`}
                                                          disabled={this.definition.isDeleted}
                                                          requiredType={DropdownRequiredType.Restricted}
                                                          label={Localizer.reportDefinitionPageAdBlue}
                                                          items={this.existingAdBlueTypes}
                                                          selectedItems={item.valueTypeIds || []}
                                                          onChange={async (sender) => await this.setAdBluesAsync(item, sender.selectedItems)}
                                                />

                                                <Dropdown id={`adblue_type_default_${index}`}
                                                          disabled={this.definition.isDeleted}
                                                          label={Localizer.reportDefinitionsPageStepDefaultValue}
                                                          items={this.getClassifiersForDefaultSelection(item.valueTypeIds, ResourceItemType.AdBlue)}
                                                          selectedItem={this.existingAdBlueTypes.find(type => type.id == item.defaultAdBlueTypeId)}
                                                          onChange={async (sender) => await this.setDefaultAdBlueTypeAsync(item, sender.selectedItem)}
                                                />
                                            </>
                                        )
                                        :
                                        this.getResourceTypeAlert(Localizer.reportDefinitionPageAdBlue, ResourceItemType.AdBlue)
                                )
                            }

                            {
                                (this.existingAdditionalExpenses != null && this.existingAdditionalExpenses.length > 0) &&
                                (
                                    <>
                                        <Checkbox inline
                                                  id={`additionalExpensesCanBeAdded_${index}`}
                                                  className={styles.checkbox}
                                                  inlineType={InlineType.Left}
                                                  readonly={this.definition.isDeleted}
                                                  label={Localizer.reportDefinitionPageCheckboxCanAddAdditionalExpense}
                                                  value={item.additionalExpensesCanBeAdded}
                                                  onChange={async (sender, value) => await this.toggleAddAdditionalExpenses(item, value)}
                                        />

                                        {
                                            (item.additionalExpensesCanBeAdded) &&
                                            (
                                                <Dropdown required multiple autoGroupSelected noWrap
                                                          id={`additionalExpenses_${index}`}
                                                          requiredType={DropdownRequiredType.Restricted}
                                                          align={DropdownAlign.Left}
                                                          orderBy={DropdownOrderBy.None}
                                                          disabled={this.definition.isDeleted}
                                                          label={Localizer.reportDefinitionPageDropdownApplicableExpenses}
                                                          items={this.existingAdditionalExpenses}
                                                          selectedItems={item.additionalExpenseTypeIds || []}
                                                          onChange={async (sender) => await this.setAdditionalExpensesAsync(item, sender.selectedItems)}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }

                        </span>
                    </td>
                    <td>
                    </td>
                    <td className={styles.add}>

                        <Icon id={`add_step_${index}`}
                              disabled={(this.definition.isDeleted)}
                              name="far plus-circle"
                              size={IconSize.X2}
                              onClick={async () => await this.addAsync(index)}
                        />

                    </td>

                    <td className={styles.up}>

                        <Icon id={`move_up_step_${index}`}
                              disabled={(this.definition.isDeleted) || (index === 0)}
                              name="far arrow-alt-circle-up"
                              size={IconSize.X2}
                              onClick={async () => this.upAsync(item, index)}
                        />

                    </td>

                    <td className={styles.down}>

                        <Icon id={`move_down_step_${index}`}
                              disabled={(this.definition.isDeleted) || (index === this.items.length - 1)}
                              name="far arrow-alt-circle-down"
                              size={IconSize.X2}
                              onClick={async () => this.downAsync(item, index)}
                        />

                    </td>

                    <td className={styles.delete}>

                        <Icon id={`delete_step_${index}`}
                              disabled={(this.definition.isDeleted) || (this.definition.items.length === 1)}
                              name="far times-circle"
                              size={IconSize.X2}
                              onClick={async () => this.deleteAsync(index)}
                        />

                    </td>
                </tr>

            </React.Fragment>
        );
    }

    private async handleErrorCodes(checked: boolean): Promise<void> {
        const definition = this.definition;

        if (checked) {
            const item = new ReportDefinitionItem();
            item.name = Localizer.errorCodesErrorCodes;
            item.title = Localizer.errorCodesErrorCodes;
            item.type = ReportItemType.ErrorsCodes;
            item.icon = "fal fa-engine-warning";
            definition.items.unshift(item);
        } else {
            definition.items = definition.items.filter(p => p.type !== ReportItemType.ErrorsCodes);
        }

        const errorCodeEnabled = checked;

        await this.setState({definition, errorCodeEnabled});
    }

    public getTitle(): string {
        return Localizer.topNavReportDefinition;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer id={"reportDefinitionPageContainer"} alertClassName={rentaToolsStyles.alert} className={styles.reportDefinition}>

                <PageHeader title={Localizer.reportDefinitionPageTitle} subtitle={this.subtitle}/>

                <PageRow>

                    <div className="col">
                        <Form onSubmit={() => this.saveChangesAsync()}>

                            <TwoColumns>

                                <TextInput required autoFocus autoComplete={false}
                                           id={"name"}
                                           readonly={this.definition.isDeleted}
                                           label={Localizer.reportDefinitionsPageName}
                                           value={this.definition.name}
                                           model={this.name}
                                />

                            </TwoColumns>

                            <FeatureSwitch flagName={RentaToolsConstants.featureErrorCodes}>
                                <div className={styles.errorCodes}>
                                    <Switch id={"errorCodes"}
                                            className={this.css(styles.arsenalSwitchWidget)}
                                            title={Localizer.errorCodesErrorCodes}
                                            label={Localizer.errorCodesErrorCodes}
                                            value={this.state.errorCodeEnabled}
                                            onChange={async (_, checked) => await this.handleErrorCodes(checked)}/>
                                </div>
                            </FeatureSwitch>

                            <table id={"reportDefinitionSteps"} className={styles.table}>
                                <tbody>
                                {
                                    this.definition.items.map((item, index) => this.renderStep(item, index))
                                }
                                </tbody>
                            </table>

                            <Inline>

                                {
                                    (!this.definition.isDeleted) &&
                                    (
                                        <Button submit
                                                id={"saveReportDefinition"}
                                                type={ButtonType.Orange}
                                                icon={{name: "far save"}}
                                                label={Localizer.componentFormSave}
                                        />
                                    )
                                }

                                {
                                    (this.definition.isDeleted)
                                        ?
                                        (
                                            <Button small
                                                    id={"restoreReportDefinition"}
                                                    minWidth={90}
                                                    label={Localizer.userManagementPageButtonRestore}
                                                    icon={{name: "trash-restore", size: IconSize.Large}}
                                                    type={ButtonType.Primary}
                                                    onClick={async () => await this.restoreReportDefinitionAsync()}
                                            />
                                        )
                                        :
                                        (
                                            (this.definition.id) && (

                                                <Button small
                                                        id={"deleteReportDefinition"}
                                                        minWidth={90}
                                                        label={Localizer.componentFormDelete}
                                                        icon={{name: "trash-alt", size: IconSize.Large}}
                                                        type={ButtonType.Primary}
                                                        onClick={async () => await this.deleteReportDefinitionAsync()}
                                                        confirm={Localizer.reportDefinitionsPageConfirmationDelete}
                                                />
                                            )
                                        )
                                }

                            </Inline>

                        </Form>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}