import React from "react";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import {BaseAsyncComponent, IBaseAsyncComponentState, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Icon, IconSize, Modal} from "@renta-apps/athenaeum-react-components";
import Report from "@/pages/Models/Report";
import Device from "@/pages/Models/Device";
import PageDefinitions from "@/providers/PageDefinitions";
import PicturesList from "@/pages/PictureEventsPage/PicturesList/PicturesList";
import {DevicePicturesFilter} from "@/models/Enums";
import ListDevicePicturesRequest from "@/models/server/requests/ListDevicePicturesRequest";
import DevicePicture from "@/models/server/DevicePicture";
import QuestionPicturesReportItem from "@/pages/Models/QuestionPicturesReportItem";
import DevicePictureFiltersData from "@/models/server/DevicePictureFiltersData";
import ToolsUtility from "@/helpers/ToolsUtility";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./InspectionReportsList.module.scss";

interface IInspectionReportsListProps {
    device: Device
}

interface IInspectionReportsListState extends IBaseAsyncComponentState<Report[]> {
    selectedReport: Report | null;
    pageNumber: number;
}

export default class InspectionReportsList extends BaseAsyncComponent<IInspectionReportsListProps, IInspectionReportsListState, Report[]> {

    state: IInspectionReportsListState = {
        isLoading: false,
        data: null,
        selectedReport: null,
        pageNumber: 0,
    };

    private readonly _returnInspectionPerPage: number = 4;
    private readonly _selectedInspectionModalRef: React.RefObject<Modal> = React.createRef();
    private readonly pictureRequest = new ListDevicePicturesRequest([DevicePicturesFilter.Manual]);

    private get showLoadMoreButton() {
        if (this.state.data) {
            return this.state.data.length === this._returnInspectionPerPage * (this.state.pageNumber + 1);
        }

        return false;
    }

    protected async loadMoreData(): Promise<void> {
        if (this.state.data) {
            await this.setState({
                isLoading: true,
            })

            const pageNumber = this.state.pageNumber + 1;
            const data = await ReturnInspectionController.getReportsByDeviceExternalId(this.props.device.externalId, pageNumber, this._returnInspectionPerPage);

            this.setState({
                data: [...this.state.data, ...data],
                pageNumber: pageNumber,
                isLoading: false,
            })
        }
    }

    protected async fetchDataAsync(): Promise<Report[]> {
        return await ReturnInspectionController.getReportsByDeviceExternalId(this.props.device.externalId, 0, this._returnInspectionPerPage);
    }

    protected async fetchPicturesDataAsync(): Promise<IPagedList<DevicePicture>> {
        const request = this.pictureRequest;
        request.deviceId = this.props.device.id;
        request.pageSize = 10;

        return await this.postAsync("api/device/listDevicePictures", request);
    }

    private async onOpenPictureListItemAsync(devicePictureItem: DevicePicture): Promise<void> {
        const report: Report | null = devicePictureItem.returnInspectionReport;
        let reportItem: QuestionPicturesReportItem = new QuestionPicturesReportItem();

        if (report != null) {
            reportItem = Report.getQuestionPicturesReportItemByFileId(devicePictureItem.fileId, report);

            if (reportItem == null) {
                throw Error(Localizer.picturesPreviewPageErrorPicturesCannotBeFound);
            }

        } else {
            if (devicePictureItem.file == null) {
                throw Error(Localizer.picturesPreviewPageErrorPicturesCannotBeFound);
            }

            reportItem.name = Localizer.get(Localizer.genericMadeBy, TransformProvider.userToString(devicePictureItem.createdBy!));
            reportItem.pictures = [];

            const devicePictures: DevicePicture[] = await this.postAsync("api/device/getGroupedDevicePictures", devicePictureItem.groupKey);

            reportItem.pictures = devicePictures
                .where(item => (item.file != null))
                .map(item => item.file!);

            if ((devicePictureItem.file.description != null) && (devicePictureItem.file.description.length > 0)) {
                reportItem.commented = true;
                reportItem.comment = devicePictureItem.file.description;
            }
        }

        RentaToolsController.context.devicePicture = devicePictureItem;
        RentaToolsController.saveContext();

        await RentaToolsController.previewPicturesAsync(report, reportItem);
    }

    private async openShortReportInfoAsync(report: Report): Promise<void> {
        await this.setState({selectedReport: report});

        if (this._selectedInspectionModalRef.current) {
            await this._selectedInspectionModalRef.current.openAsync();
        }
    }

    private async previewReportAsync(report: Report): Promise<void> {
        const reportId: string = report.id;

        report = await ReturnInspectionController.getReportAsync(reportId);

        if (report.skipped) {
            await this.openShortReportInfoAsync(report);
            return;
        }

        RentaToolsController.reportPreview = report;

        const route = PageDefinitions.reportPreviewRoute;

        await PageRouteProvider.redirectAsync(route);
    }

    private static getReportStatus(report: Report): string {
        return report.skipped
            ? Localizer.reportsPageReportInformationInspectionSkipped
            : report.passed
                ? Localizer.reportsPageReportInformationInspectionPassed
                : Localizer.reportsPageReportInformationInspectionFailed;
    }

    public getManual(): string {
        return Localizer.reportsPageGetManual;
    }

    protected getEndpoint(): string {
        return "";
    }

    public isAsync(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (

            <div id={"inspectionReportsList"} className={styles.inspectionReportsList}>

                {
                    (this.state.data != null && this.state.data.length > 0)
                        ? <div/>
                        : <div>{Localizer.reportsPageNoReports}</div>
                }

                {
                    (this.state.data != null) &&
                    (
                        <React.Fragment>

                            <table id={"inspectionReportsTable"} className={styles.table}>
                                <tbody>
                                {
                                    this.state.data.map((item, index) => (
                                        <tr id={`inspectionReportRow_${index}`} key={index}>
                                            <td id={`inspectionReportStatusIcon_${index}`} className={styles.icon}>
                                                    <span>
                                                    {
                                                        (item.passed)
                                                            ? (<Icon name="far check" size={IconSize.Large} tooltip={Localizer.reportsPageInspectionPassed}/>)
                                                            : (item.skipped)
                                                                ? (<Icon name="fas minus" size={IconSize.Large} tooltip={Localizer.reportsPageInspectionSkipped}/>)
                                                                : (<Icon name="far ban" size={IconSize.Large} tooltip={Localizer.reportsPageInspectionFailed}/>)
                                                    }
                                                    </span>
                                            </td>
                                            <td id={`inspectionReportInfo_${index}`} className={styles.info}>
                                                    <span onClick={async () => await this.previewReportAsync(item)}>
                                                        <div className={styles.report}>
                                                            {(!this.mobile) && Localizer.reportReport} {ToolsUtility.toDateString(item.completedAt)}
                                                        </div>
                                                        {
                                                            (!this.mobile) &&
                                                            (
                                                                <div className={styles.user}>
                                                                    {(item.user) && (TransformProvider.userToString(item.user))}
                                                                </div>
                                                            )
                                                        }
                                                        <div className={styles.depo}>
                                                            {(item.depo) && (item.depo.costPool)}
                                                        </div>
                                                    </span>
                                            </td>
                                            {
                                                (this.mobile) &&
                                                (
                                                    <td className={styles.icon}>
                                                            <span onClick={async () => await this.openShortReportInfoAsync(item)}>
                                                                <Icon name="far info" size={IconSize.Large}/>
                                                            </span>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                            {this.showLoadMoreButton && (
                                <ArsenalButton block
                                               id={"loadMoreButton"}
                                               type={ButtonType.Blue}
                                               disabled={this.state.isLoading}
                                               label={Localizer.returnInspectionHistoryPageLoadMore}
                                               onClick={async () => await this.loadMoreData()}/>
                            )}


                            <PicturesList useReportPageStyles
                                          noDataText={Localizer.picturesPreviewPagePicturesListNoData}
                                          request={this.pictureRequest}
                                          filtersData={{types: [DevicePicturesFilter.Manual]} as DevicePictureFiltersData}
                                          fetchData={async () => await this.fetchPicturesDataAsync()}
                                          onOpenPictureListItem={async (sender, item) => await this.onOpenPictureListItemAsync(item)}
                            />


                        </React.Fragment>
                    )
                }

                <Modal ref={this._selectedInspectionModalRef}
                       title={Localizer.reportsPageReportInformationTitle}
                       subtitle={`${this.props.device.name}`}>
                    {
                        (this.state.selectedReport) &&
                        (
                            <div>
                                {(this.state.selectedReport.comment) && (<p><i>{this.state.selectedReport.comment}</i></p>)}

                                <p>{Localizer.reportsPageReportInformationInspectedBy} "{TransformProvider.toString(this.state.selectedReport.user)}" {Localizer.reportsPageReportInformationAt} {ToolsUtility.toDateString(this.state.selectedReport.completedAt)}.</p>

                                {
                                    (this.state.selectedReport.depo) &&
                                    (
                                        <p>{Localizer.reportsPageReportInformationDepot}: "{TransformProvider.toString(this.state.selectedReport.depo.costPool)}"</p>
                                    )
                                }

                                {
                                    (this.state.selectedReport.externalContractId && this.state.selectedReport.customerName) &&
                                    (
                                        <>
                                            <p>{Localizer.reportsPageReportInformationCustomerName}: "{this.state.selectedReport.customerName}"</p>
                                            <p>{Localizer.reportsPageReportInformationContractId}: "{this.state.selectedReport.externalContractId}"</p>
                                        </>
                                    )
                                }


                                <p>{Localizer.reportsPageReportInformationInspectionResult}: "{InspectionReportsList.getReportStatus(this.state.selectedReport)}"</p>

                                {
                                    (this.state.selectedReport.operatingHours != null) &&
                                    (
                                        <React.Fragment>
                                            <p>{Localizer.deviceServicePageLabelOperatingHours}: {this.state.selectedReport.operatingHours}</p>
                                            <p>TrackUnit {Localizer.deviceServicePageLabelOperatingHours}: {this.state.selectedReport.trackUnitOperatingHours}</p>
                                        </React.Fragment>
                                    )
                                }

                            </div>
                        )
                    }
                </Modal>

            </div>
        );
    }
}