import DeviceFault from "@/pages/Models/DeviceFault";
import {ServiceLocationType} from "@/models/Enums";

export default class SaveServiceRequest {
    public userId: string = "";

    public deviceId: string = "";

    public reportDefinitionId: string = "";

    public serviceTypeId: string | null = null;

    public serviceTypeName: string | null = null;

    public date: Date = new Date();

    public startedAt: Date = new Date();

    public faults: DeviceFault[] = [];

    public actionIds: string[] = [];

    public depoCostPool: string = "";

    public comment: string | null = null;

    public servicedByRenta: boolean = false;

    public dontSendServiceDate: boolean = false;

    public invoiceNumber: string = "";

    public cost: number = 0;

    public operatingHours: number | null = null;

    public locationType: ServiceLocationType | null = null;

    public serviceExpenseId: string | null = null;

    public isManualService: boolean = false;

    public depoId: string | null = null;

    public isRepairWithNoFaults: boolean = false;
}