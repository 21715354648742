import {ch, ILayoutPage} from "@renta-apps/athenaeum-react-common";
import BaseResponse from "@/models/server/responses/BaseResponse";
import ErrorResponseDictionary from "@/common/ErrorResponseDictionary";


class HttpClient{
    public async postAsync<TResponse extends BaseResponse>(endpoint: string, request: any | null = null): Promise<TResponse> {
        const layout: ILayoutPage = ch.getLayout();
        const response =  await layout.postAsync<TResponse>(endpoint, request);

        await this.handleErrorResponse(response);

        return response;
    }

    public async getAsync<TResponse extends BaseResponse>(endpoint: string): Promise<TResponse> {
        const layout: ILayoutPage = ch.getLayout();
        const response =  await layout.getAsync<TResponse>(endpoint);

        await this.handleErrorResponse(response);

        return response;
    }

    public getAsyncWithoutErrorHandling<TResponse>(endpoint: string): Promise<TResponse> {
        const layout: ILayoutPage = ch.getLayout();

        return layout.getAsync(endpoint);
    }

    public postAsyncWithoutErrorHandling<TResponse>(endpoint: string, request: any | null = null): Promise<TResponse> {
        const layout: ILayoutPage = ch.getLayout();

        return layout.postAsync<TResponse>(endpoint, request);
    }

    private async handleErrorResponse(response: BaseResponse): Promise<void> {
        if(response?.success === false){

            const dictionary = new ErrorResponseDictionary();

            if(dictionary.canHandleError(response.errorCode)){
                await ch.alertErrorAsync(dictionary.getError(response.errorCode));
            }
        }
    }
}

export default new HttpClient();