import React from "react";
import {BaseAsyncComponent, IBaseAsyncComponentState, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import InvoicesListFilters from "@/pages/InvoicesPage/InvoicesListFilters/InvoicesListFilters";
import ListInvoicesRequest from "@/models/server/requests/ListInvoicesRequest";
import ReturnInspectionInvoiceFiltersData from "@/models/server/ReturnInspectionInvoiceFiltersData";
import {Button, ButtonType, Icon, IconSize, IconStyle, IIconProps} from "@renta-apps/athenaeum-react-components";
import Invoice from "@/models/server/Invoice";
import {InvoiceStatusFilter} from "@/models/Enums";
import ToolsUtility from "@/helpers/ToolsUtility";
import Localizer from "@/localization/Localizer";

import styles from "./InvoicesList.module.scss";

interface IInvoicesListProps {
    modalTitle: string;
    noDataText: string;
    request: ListInvoicesRequest;
    filtersData: ReturnInspectionInvoiceFiltersData;
    fetchData(sender: IBaseComponent, request: ListInvoicesRequest): Promise<Invoice[]>;
    onInvoiceOpen(sender: IBaseComponent, invoice: Invoice): Promise<void>;
}

export interface IInvoicesListState extends IBaseAsyncComponentState<Invoice[]> {
    request: ListInvoicesRequest;
    isFetching: boolean;
}

export default class InvoicesList extends BaseAsyncComponent<IInvoicesListProps, IInvoicesListState, Invoice[]> {

    state: IInvoicesListState = {
        data: null,
        isLoading: false,
        request: this.props.request,
        isFetching: false,
    };

    private readonly _filtersModalRef: React.RefObject<InvoicesListFilters> = React.createRef();

    private readonly _lastElement: React.RefObject<HTMLDivElement> = React.createRef();

    private _intersectionObserver: IntersectionObserver | null = null;

    public get haveMoreDataToLoad(): boolean {
        return this.state.request.pageSize * (this.state.request.pageNumber + 1) === this.state.data?.length!;
    }

    private async openFiltersAsync(): Promise<void> {
        await this._filtersModalRef.current!.openAsync();
    }

    private async submitFiltersAsync(request: ListInvoicesRequest): Promise<void> {
        request.resetPage();
        this.setState({request});
        await this.reloadAsync();
    }

    private async onItemClickAsync(invoice: Invoice): Promise<void> {
        await this.props.onInvoiceOpen(this, invoice);
    }

    private getStateIcon(invoice: Invoice): IIconProps {
        if (invoice.approved) {
            return {name: "far thumbs-up", className: "text-success", tooltip: Localizer.inspectionInvoicesListStateTooltipApproved};
        }
        if (invoice.declined) {
            return {name: "far thumbs-down", className: "text-danger", tooltip: Localizer.inspectionInvoicesListStateTooltipDeclined};
        }
        return {name: "fas hourglass-start", className: "orange", tooltip: Localizer.inspectionInvoicesListStateTooltipInProcess};
    }

    private getInvoiceRowDate(invoice: Invoice): string {
        if (this.props.request.status == InvoiceStatusFilter.Approved) {
            return ToolsUtility.toDateString(invoice.approvedAt);
        } else if (this.props.request.status == InvoiceStatusFilter.Declined) {
            return ToolsUtility.toDateString(invoice.declinedAt);
        }
        
        return ToolsUtility.toDateString(invoice.createdAt);
    }

    protected async fetchDataAsync(): Promise<Invoice[]> {
        return this.props.fetchData(this, this.state.request);
    }

    protected getEndpoint(): string {
        return "";
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    public async componentDidUpdate() {
        this._intersectionObserver = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting && !this.state.isFetching && this.haveMoreDataToLoad) {

                const request = this.state.request;

                request.nextPage();

                await this.setState({...this.state, isFetching: true})

                const result = await this.props.fetchData(this, request);

                const currentState = this.state.data;

                currentState?.push(...result)

                await this.setState({...this.state, data: currentState, isFetching: false, request: request})
            }
        });

        if (this._intersectionObserver && this._lastElement.current) {
            this._intersectionObserver.observe(this._lastElement.current);
        }
    }

    public renderRow(invoice: Invoice, index: number): React.ReactNode {
        const customerName: string = (invoice.deviceContract)
            ? invoice.deviceContract.customerName || "-"
            : "-";

        const constructionSiteName: string = (invoice.deviceContract)
            ? invoice.deviceContract.constructionSiteName || "-"
            : "-";

        const customerExternalContractId: string = (invoice.deviceContract)
            ? invoice.deviceContract.contractExternalId || "-"
            : "-";

        const deviceExternalId: string = (invoice.device)
            ? invoice.device.externalId || "-"
            : "-";

        const deviceType: string = (invoice.device)
            ? invoice.device.type || "-"
            : "-";

        return (
            <div key={index}
                 id={`listItem_${deviceExternalId}_${index}`}
                 className={this.css(styles.listItem, "cursor-pointer")}
                 ref={index === this.state.data?.length! - 1 ? this._lastElement : undefined}
                 onClick={async () => await this.onItemClickAsync(invoice)}>

                <div className={styles.left}>
                    <div className={this.css(styles.multiLines, styles.topSpace)}>
                        <span>{deviceExternalId} {deviceType}</span>
                        <span>{customerName}</span>
                        <span>{constructionSiteName}</span>
                        <span>{customerExternalContractId}</span>
                    </div>
                </div>

                <div className={(!this.mobile) ? styles.resourceTypes : ""}>

                    <span className={styles.value}>{(invoice.fuelAmount != null) ? "{0:0.0} l".format(invoice.fuelAmount) : "-"}</span>

                    <span className={styles.value}>{(invoice.washingTime != null) ? "{0:0.0} h".format(invoice.washingTime) : "-"}</span>

                    <span className={styles.value}>{(invoice.adBlueAmount != null) ? "{0:0.0} l".format(invoice.adBlueAmount) : "-"}</span>

                </div>

                {
                    (invoice.additionalExpensesCount > 0) &&
                    (
                        <div className={"text-center"}>

                            <span>{invoice.additionalExpensesCount}</span>

                            <Icon name={"fal fa-boxes"}
                                  style={IconStyle.Duotone}
                                  size={IconSize.Large}
                            />

                        </div>
                    )
                }

                <div className={styles.icons}>

                    <Icon {...this.getStateIcon(invoice)} size={IconSize.Large}/>

                </div>

                <span>{this.getInvoiceRowDate(invoice)}</span>

            </div>
        )
    }

    public render(): React.ReactNode {

        return (
            <div className={this.css(styles.returnInspectionInvoicesList)}>

                <div>
                    <div className={this.css(styles.header)}>
                            <span>
                                
                            </span>

                        <Button small
                                id={`invoices_list_filter_button_${this.id}`}
                                icon={{name: this.getIconName(), size: IconSize.X2}}
                                type={ButtonType.Blue}
                                className={this.css(styles.filter)}
                                onClick={async () => await this.openFiltersAsync()}
                        />

                    </div>
                </div>

                {
                    (!this.isLoading) && ((this.state.data == null) || (this.state.data.length == 0)) &&
                    (
                        <div className={this.css(styles.listItem, styles.noItems)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        this.state.data.map((item, index) => this.renderRow(item, index))
                    )
                }

                <InvoicesListFilters ref={this._filtersModalRef}
                                     title={this.props.modalTitle}
                                     request={this.state.request}
                                     filtersData={this.props.filtersData}
                                     onSubmit={async (sender, request) => await this.submitFiltersAsync(request)}
                />

            </div>
        );
    }
}