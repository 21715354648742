import React from "react";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Icon, ImageModal, ModalSize} from "@renta-apps/athenaeum-react-components";

import ArsenalPagination from "../ArsenalPagination/ArsenalPagination";
import ImageProvider from "../../providers/ImageProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ArsenalPicturesCarousel.module.scss";

export interface IArsenalPicturesCarouselProps {
    title?: string;
    subtitle?: string;
    items: (FileModel | null)[];
    className?: string;
    onChangePhoto?(sender: ArsenalPicturesCarousel, index: number, picture: FileModel | null): Promise<void>
}


interface IArsenalPicturesCarouselState {
    pictureNumber: number
}

export default class ArsenalPicturesCarousel extends BaseComponent<IArsenalPicturesCarouselProps, IArsenalPicturesCarouselState> {
    
    state :  IArsenalPicturesCarouselState =  {
        pictureNumber: 0,
    }

    private get items(): FileModel[] {
        return this.props.items as FileModel[] || [];
    }

    private get picture(): FileModel | null {
        return this.props.items[this.state.pictureNumber];
    }

    private async switchPictureAsync(pageNumber: number): Promise<void> {
        const pictureNumber: number = pageNumber - 1;
        await this.setState({ pictureNumber });
    }
    
    protected getTitle(): string {
        return this.props.title || Localizer.arsenalPicturesInputModalTitle;
    }

    protected getSubtitle(): string {
        return this.props.subtitle || "";
    }
    
    render(): React.ReactNode {
        const modalId: string = "imageModal";
        
        return (
            <div className={this.css(this.props.className, styles.arsenalPicturesCarousel)}>
                    <div className={styles.pictures}>
                        <Icon name="fas camera" />
                        {
                            (this.picture) &&
                            (
                                <React.Fragment>
    
                                    <div id={`id${modalId}`} 
                                         className={styles.picture}
                                         style={ImageProvider.getImageStyle(this.picture)}
                                         data-toggle="modal"
                                         data-target={`#${modalId}`}
                                    />
                                    
                                    <ImageModal download
                                                id={modalId}
                                                title={this.getTitle()} 
                                                subtitle={this.getSubtitle()} 
                                                picture={this.picture}
                                                size={this.desktop ? ModalSize.Large : undefined}
                                                downloadOriginal={true}
                                                imageUrl={image => ImageProvider.getImageUrl(image)}
                                    />
    
                                </React.Fragment>
                            )
                        }
                    </div>

                <ArsenalPagination loop
                                   pageNumber={this.state.pictureNumber + 1} 
                                   pageCount={this.items.length} 
                                   className={styles.pagination}
                                   onChange={async (sender, pageNumber) => await this.switchPictureAsync(pageNumber)} 
                />
                
            </div>
        );
    }
};