import React from "react";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import {ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ActionType} from "@/models/Enums";
import Phase from "@/models/server/Phase";
import PhaseResponse from "@/models/server/responses/PhaseResponse";
import PhaseRequest from "@/models/server/requests/PhaseRequest";
import CreatePhaseResponse from "@/models/server/responses/CreatePhaseResponse";
import PhaseUpdateResponse from "@/models/server/responses/PhaseUpdateResponse";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./PhasesPage.module.scss";

interface IPhagePageProps {
}

interface IPhasePageState {
    showDeleted: boolean
}

export default class PhasesPage extends AuthorizedPage<IPhasePageState, IPhagePageProps> {

    state: IPhasePageState = {
        showDeleted: false
    };

    private readonly _phasesGridRef: React.RefObject<Grid<Phase>> = React.createRef();

    private readonly _phasesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 70,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.genericPhase,
            accessor: nameof<Phase>(w => w.name),
            minWidth: 250,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initPhaseOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processPhaseOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processPhaseOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processPhaseOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processPhaseOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get phasesGrid(): GridModel<Phase> {
        return this._phasesGridRef.current!.model;
    }

    private async getPhasesAsync(): Promise<Phase[]> {
        let phaseResponse: PhaseResponse = await this.phasesGrid.getAsync("api/Phase/GetPhases");

        let phases: Phase[] = phaseResponse.phases;

        if (!this.state.showDeleted) {
            phases = phases.filter(f => !f.isDeleted)
        }

        return phases;
    }

    private initRow(row: RowModel<Phase>): void {
        const model: Phase = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = this.isValid(model);
        row.deleted = model.isDeleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private isValid(phase: Phase): boolean {
        return (!!phase.name);
    }

    private async initPhaseOperationsAsync(cell: CellModel<Phase>): Promise<void> {

        const model: Phase = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<Phase> = cell.actions[0];
        const cancelAction: CellAction<Phase> = cell.actions[1];
        const deleteAction: CellAction<Phase> = cell.actions[2];
        const restoreAction: CellAction<Phase> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    private getDeleteConfirmation(cell: CellModel<Phase>): string {
        const model: Phase = cell.model;

        return model.name!;
    }

    private async processPhaseOperationAsync(cell: CellModel<Phase>, action: CellAction<Phase>): Promise<void> {

        await ch.hideAlertAsync();

        const model: Phase = cell.model;
        const isNew: boolean = (!model.id);

        const request = new PhaseRequest();
        model.number = 0; //temporary
        request.phase = model;

        if (action.action.name === "save") {

            if (isNew) {

                const response: CreatePhaseResponse = await cell.grid.postAsync("api/phase/CreatePhase", request);

                if (response.phaseAlreadyExists) {
                    await ch.alertErrorAsync(Localizer.phasesPageAlreadyExists, true);
                    return;
                }

                cell.row.model = response.phase!;

                await cell.row.saveAsync();

            } else {
                const response: PhaseUpdateResponse = await cell.grid.postAsync("api/phase/UpdatePhase", request);

                cell.row.model = response.phase!;

                await cell.row.bindAsync();
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            model.isDeleted = true;

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                await cell.grid.postAsync("api/phase/DeletePhase", request);

                await cell.row.setDeletedAsync(true);

            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await cell.grid.postAsync("api/phase/restorePhase", request);

                model.isDeleted = false;
            }

            await cell.row.setDeletedAsync(false);
        }
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.phasesGrid.reloadAsync();
    }

    private async addPhaseAsync(): Promise<void> {
        const phase = new Phase();

        const newRows: RowModel<Phase>[] = await this.phasesGrid.insertAsync(0, phase);

        const newRow: RowModel<Phase> = newRows[0];
        const nameCell: CellModel<Phase> = newRow.get("name");

        await nameCell.editAsync(true);
    }

    public render(): React.ReactNode {
        return (

            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.adBlues)}>
                <PageHeader title={Localizer.genericPhase}/>
                <PageRow>
                    <div className="col">

                        <div className={styles.container}>

                            <ToolbarContainer className={styles.toolbar}>


                                <Inline justify={JustifyContent.End}>

                                    <Checkbox inline
                                              label={Localizer.userManagementPageCheckboxShowDeleted}
                                              value={this.state.showDeleted}
                                              onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                                    />

                                    <Button className="ml-1"
                                            icon={{name: "far history", size: IconSize.Large}}
                                            type={ButtonType.Info}
                                            onClick={async () => await this.reloadAsync()}
                                    />

                                    <Button icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Orange}
                                            onClick={async () => await this.addPhaseAsync()}
                                    />

                                </Inline>

                            </ToolbarContainer>

                            <Grid responsive
                                  className={styles.valuesGrid}
                                  ref={this._phasesGridRef}
                                  hovering={GridHoveringType.EditableCell}
                                  odd={GridOddType.None}
                                  minWidth="auto"
                                  columns={this._phasesColumns}
                                  initRow={(row) => this.initRow(row)}
                                  fetchData={async () => await this.getPhasesAsync()}
                            />

                        </div>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}