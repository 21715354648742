import React from "react";
import {IPagedList, Utility} from "@renta-apps/athenaeum-toolkit";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {
    ButtonType,
    Checkbox,
    DateInput,
    Dropdown,
    DropdownOrderBy,
    IconStyle,
    InlineType,
    NumberInput,
    NumberInputBehaviour,
    PageContainer,
    PageHeader,
    SelectListItem, TextAreaInput,
    TextInput
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import PageDefinitions from "../../providers/PageDefinitions";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Device from "../Models/Device";
import ServiceReport from "@/pages/Models/ServiceReport";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import DeviceFault from "@/pages/Models/DeviceFault";
import ServiceAction from "@/pages/Models/ServiceAction";
import FaultCheck from "@/pages/DeviceServicePage/FaultCheck/FaultCheck";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import ServiceReportType from "@/pages/Models/ServiceReportType";
import GetServiceReportsResponse from "@/models/server/responses/GetServiceReportsResponse";
import {DeviceCounterType, MaintenanceReason, ServiceLocationType} from "@/models/Enums";
import Depo from "@/models/server/Depo";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ServiceExpense from "@/models/server/ServiceExpense";
import ToolsUtility from "@/helpers/ToolsUtility";
import SpeechRecognitionTextInput from "@/components/SpeechRecognitionTextInput/SpeechRecognitionTextInput";
import RentaToolsController from "../RentaToolsController";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./DeviceServicePage.module.scss";
import rentaToolsStyles from "../RentaTools.module.scss";
import newStyles from "../NewUI.module.scss";
import Banner from "@/components/Alert/Banner";

interface IDeviceServicePageProps {
}

interface IDeviceServicePageState {
    serviceReports: IPagedList<ServiceReport> | null;
    isServiceCommentOpened: boolean;
    operatingHours: number | null;
    serviceExpenses: ServiceExpense[];
    depos: Depo[];
    requiresRepairs: boolean;
    requiresMaintenance: boolean;
}

export default class DeviceServicePage extends AuthorizedPage<IDeviceServicePageProps, IDeviceServicePageState> {

    state: IDeviceServicePageState = {
        serviceReports: null,
        isServiceCommentOpened: false,
        operatingHours: null,
        serviceExpenses: [],
        depos: [],
        requiresRepairs: false,
        requiresMaintenance: false,
    };

    private readonly _commentRef: React.RefObject<TextAreaInput> = React.createRef();

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if ((this.service == null) || (this.service.reportDefinition == null) || (this.device == null)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true, true);
        }

        const pastTime = Utility.addMonths(Utility.date(), -999);

        const serviceReportsResponse: GetServiceReportsResponse | null = await RentaToolsController.getServiceReportsAsync(this.device.externalId, pastTime, 1, 5);
        const serviceReports: IPagedList<ServiceReport> = serviceReportsResponse.serviceReports!;

        const serviceExpenses: ServiceExpense[] = await RentaToolsController.getServiceExpensesAsync();

        const operatingHours: number | null = (this.device.supportsOperatingHours)
            ? this.getDeviceOperatingHours()
            : null;

        const depos: Depo[] = await RentaToolsController.getUserDeposAsync();

        await this.initializeServiceDepoAsync();

        // Initialize the requirements for service completion
        const maintenanceReasons: MaintenanceReason[] = this.device.maintenance;
        let requiresMaintenance: boolean = maintenanceReasons.length > 0 && maintenanceReasons.some(x => x > MaintenanceReason.Service);
        let requiresRepairs: boolean = this.faults.length > 0 && this.faults.some(x => !x.fixed);

        await this.setState({
            serviceReports,
            operatingHours,
            serviceExpenses,
            depos,
            requiresMaintenance,
            requiresRepairs,
        });
    }

    private get preparedForSale() : boolean {
        return this.device.preparedForSale && ch.isDenmark;
    }
    
    private async initializeServiceDepoAsync(): Promise<void> {
        if ((this.service.depoId == null) && (this.device.depoId != null)) {
            this.service.depoId = this.device.depoId;
            this.service.depo = this.device.depo;
            await this.saveContext();
        }
    }

    private async saveServiceAsync(): Promise<void> {
        await RentaToolsController.saveServiceAsync(this.state.operatingHours);
    }

    private async onFaultCheckAsync(fault: DeviceFault, value: boolean): Promise<void> {
        fault.fixed = value;
        await this.reRenderAsync();
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get faults(): DeviceFault[] {
        return this.service.faults || [];
    }

    private get service(): ServiceReport {
        return RentaToolsController.service!;
    }

    private get serviceType(): ServiceReportType | null {
        return this.service.serviceType;
    }

    private get noActions(): boolean {
        return this.serviceType?.noActions == true;
    }

    private get serviceLocationType(): ServiceLocationType {
        if (this.service.locationType == null) {
            this.service.locationType = ServiceLocationType.Depot;
            this.saveContext();
        }
        return this.service.locationType;
    }

    private get serviceReportTypes(): ServiceReportType[] {
        return this.serviceDefinition.types;
    }

    private get serviceReportTypeItems(): SelectListItem[] {
        return this.serviceReportTypes.map(item => TransformProvider.toSelectListItem(item));
    }

    private get serviceReportTypeId(): string | null {
        return this.serviceType?.id ?? null;
    }

    private get serviceDefinition(): ServiceReportDefinition {
        return this.service.reportDefinition!;
    }

    private get serviceActions(): ServiceAction[] {
        return this.serviceType?.actions ?? [];
    }

    private get completedActions(): ServiceAction[] {
        return this.service.actions;
    }

    private get completedServiceActions(): ServiceAction[] {
        const serviceActions: ServiceAction[] = this.serviceActions;
        const completedActions: ServiceAction[] = this.completedActions;
        return serviceActions.filter(action => completedActions.some(item => item.id == action.id));
    }

    private get addServiceCommentLabel(): string {
        const whiteSpace = "\xa0\xa0\xa0";

        return (this.service.comment)
            ? `3 ${whiteSpace} ${this.service.comment}`
            : `3 ${whiteSpace} ${Localizer.deviceServicePageLabelAddComment}`;
    }

    private get canSave(): boolean {
        const repairsSelected: boolean = ((this.faults.length > 0) && (this.faults.some(fault => fault.fixed))) || this.service.isRepairWithNoFaults;
        const serviceActionsSelected: boolean = (this.completedServiceActions.length > 0);

        if (repairsSelected && this.serviceType?.noActions === false && !serviceActionsSelected) {
            return false;
        }

        return serviceActionsSelected || repairsSelected || this.serviceType?.noActions === true;
    }

    private get title(): string {
        return "{0}\n {1}\n {2}".format(
            this.device.type,
            this.device.externalId,
            ServiceReport.getMaintenanceDescription(this.service)
        );
    }

    private isItemSelected(action: ServiceAction): boolean {
        let existingAction: ServiceAction | null = this.service.actions.firstOrDefault(a => a.id == action.id);

        return existingAction != null;
    }

    private serviceTypeTextTransform(sender: Dropdown<SelectListItem>): string {
        const whiteSpace = "\xa0\xa0\xa0";
        const text: string = (sender.selectedListItem)
            ? sender.selectedListItem.text
            : Localizer.deviceServicePageLabelSetType;
        return `2 ${whiteSpace} ${text}`;
    }

    private getDeviceOperatingHours(): number {
        return RentaToolsController.getDeviceCounter(this.device, DeviceCounterType.TotalOperatingHours) || 0
    }

    private renderCustomDateInput(): React.ReactNode {
        const whiteSpace = "\xa0\xa0\xa0";
        const prefix = `1 ${whiteSpace}`;

        const label = ToolsUtility.toDateString(this.service.date, prefix);

        return (
            <ArsenalButton block
                           className={this.css(styles.label, newStyles.label)}
                           type={ButtonType.Default}
                           label={label}
                           icon={{name: "fal calendar-alt"}}
            />
        );
    }

    private async setServiceAction(action: ServiceAction, isChecked: boolean): Promise<void> {
        const existingAction: ServiceAction | null = this.service.actions.firstOrDefault(a => a.id == action.id);

        if (isChecked) {
            if (existingAction == null) {
                this.service.actions.push(action);
            }
        } else {
            if (existingAction != null) {
                this.service.actions.remove(existingAction)
            }
        }

        await this.saveContext();
    }

    private async toggleCommentAsync(): Promise<void> {
        const isServiceCommentOpened: boolean = !this.state.isServiceCommentOpened;
        await this.setState({isServiceCommentOpened});
    }

    private async setDateAsync(date: Date): Promise<void> {
        this.service.date = date;
        await this.saveContext();
    }

    private async setServicedByRentaAsync(servicedByRenta: boolean): Promise<void> {
        this.service.servicedByRenta = servicedByRenta;
        await this.saveContext();
    }

    private async setRepairWithNoFaults(selected: boolean): Promise<void> {
        this.service.isRepairWithNoFaults = selected;
        await this.saveContext();
    }

    private async setServiceCommentAsync(comment: string): Promise<void> {
        this.service.comment = comment;
        await this.saveContext();
    }

    private async setCostAsync(cost: number): Promise<void> {
        this.service.cost = cost;
        await this.saveContext();
    }

    private async setInvoiceNumberAsync(invoiceNumber: string): Promise<void> {
        this.service.invoiceNumber = invoiceNumber;
        await this.saveContext();
    }

    private async setLocationDepoAsync(depo: Depo): Promise<void> {
        this.service.depoId = depo.id;
        this.service.depo = depo;
        await this.saveContext();
    }

    private async setLocationTypeAsync(item: ServiceLocationType): Promise<void> {
        this.service.locationType = item;
        await this.saveContext();
    }

    private async setServiceExpenseAsync(item: ServiceExpense | null): Promise<void> {
        this.service.serviceExpense = item;
        await this.saveContext();
    }

    private async saveContext(): Promise<void> {
        RentaToolsController.saveContext();
        await this.reRenderAsync();
    }

    private async setOperatingHoursAsync(operatingHours: number): Promise<void> {
        await this.setState({operatingHours});
    }

    private async openServicePreviewPageAsync(item: ServiceReport): Promise<void> {
        const serviceReportId: string = item.id;

        RentaToolsController.servicePreview = await RentaToolsController.getServiceReportAsync(serviceReportId);

        await PageRouteProvider.redirectAsync(PageDefinitions.servicePreviewRoute);
    }

    private async onChangeServiceReportTypeAsync(sender: Dropdown<SelectListItem>): Promise<void> {
        const item: SelectListItem | null = sender.selectedListItem;
        const serviceType: ServiceReportType | null = this.serviceReportTypes.find(type => type.id == item?.value) || null;
        const serviceTypeId: string | null = (serviceType) ? serviceType.id : null;

        if (this.service.serviceTypeId !== serviceTypeId) {
            this.service.serviceType = serviceType;
            this.service.serviceTypeId = serviceTypeId;
            this.service.serviceTypeName = (serviceType) ? serviceType.name : null;
            this.service.actions = [];

            await this.saveContext();
        }
    }

    private async returnBackAsync(): Promise<void> {
        await PageRouteProvider.back();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.deviceService, newStyles.pageContainer, newStyles.deviceService)}>

                <PageHeader className={this.css(rentaToolsStyles.leftPageHeader)} title={this.title}/>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <h5>{Localizer.deviceServicePageGeneralHeader}</h5>

                        </div>

                    </ArsenalPageRow>
                    
                    {this.preparedForSale && (
                        <Banner text={Localizer.servicePagePreperedForSale} />
                    )}
                    
                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <DateInput popup
                                       id={"serviceDateInput"}
                                       className={styles.date}
                                       label={Localizer.deviceServicePageGeneralLabelDate}
                                       customInput={() => this.renderCustomDateInput()}
                                       maxDate={Utility.today()}
                                       value={this.service.date}
                                       onChange={async (date) => await this.setDateAsync(date)}
                            />
                        </div>

                    </ArsenalPageRow>

                </div>

                {
                    (this.faults.length == 0) &&
                    (
                        <div>
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <div>
                                        <Checkbox inline
                                                  inlineType={InlineType.Right}
                                                  label={Localizer.enumMaintenanceReasonRepair}
                                                  value={this.service.isRepairWithNoFaults}
                                                  onChange={async (_, value) => await this.setRepairWithNoFaults(value)}
                                        />
                                    </div>

                                </div>

                            </ArsenalPageRow>
                        </div>
                    )
                }

                {
                    (this.faults.length > 0) &&
                    (
                        <div className={styles.faultsContainer}>

                            <ArsenalPageRow className={this.css(styles.faultChecks, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <h5>{Localizer.deviceServicePageFaultsHeader}</h5>

                                </div>

                            </ArsenalPageRow>

                            {
                                this.faults.map((fault, index) =>
                                    (
                                        <ArsenalPageRow key={fault.id} className={this.css(styles.faultChecks, newStyles.row)}>

                                            <div className={this.css("w-100", newStyles.col)}>

                                                <FaultCheck title={this.getFaultTitle(fault)}
                                                            id={`fault_check_${index}`}
                                                            comment={ReportDefinitionItem.getComment(fault) || undefined}
                                                            createdBy={fault.createdBy}
                                                            createdAt={fault.createdAt}
                                                            fixedBy={fault.fixedBy}
                                                            fixedAt={fault.fixedAt}
                                                            value={fault.fixed}
                                                            onChange={async (_, value) => this.onFaultCheckAsync(fault, value)}
                                                />

                                            </div>

                                        </ArsenalPageRow>
                                    )
                                )
                            }
                        </div>
                    )
                }

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <h5>{Localizer.deviceServicePageServiceActionsHeader}</h5>

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <Dropdown noValidate
                                      id={"serviceTypeDropdown"}
                                      className={newStyles.dropdown}
                                      toggleIcon={{name: "caret-circle-down", style: IconStyle.Regular}}
                                      selectedTextTransform={(sender) => this.serviceTypeTextTransform(sender)}
                                      items={this.serviceReportTypeItems}
                                      selectedItem={this.serviceReportTypeId || undefined}
                                      onChange={async (sender) => await this.onChangeServiceReportTypeAsync(sender)}
                            />

                        </div>

                    </ArsenalPageRow>

                    {
                        (!this.noActions) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                {
                                    this.serviceActions.map((item, index) =>

                                        <div className={this.css("w-100", newStyles.col)}>

                                            <Checkbox inline
                                                      id={`action_checkBox_${index}`}
                                                      name={item.name}
                                                      className={this.css(styles.checkbox, styles.checkbox)}
                                                      inlineType={InlineType.Right}
                                                      label={item.name}
                                                      value={this.isItemSelected(item)}
                                                      onChange={async (_, value) => await this.setServiceAction(item, value)}
                                            />

                                        </div>
                                    )
                                }

                            </ArsenalPageRow>
                        )
                    }

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <ArsenalButton block ellipsis
                                           className={this.css(styles.label, newStyles.label)}
                                           type={ButtonType.Default}
                                           label={this.addServiceCommentLabel}
                                           icon={{name: "fal keyboard"}}
                                           onClick={() => this.toggleCommentAsync()}
                            />

                        </div>

                    </ArsenalPageRow>

                    {
                        (this.state.isServiceCommentOpened) &&
                        (
                            <div className={styles.commentWrapper}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <SpeechRecognitionTextInput showRemainingAmount
                                                                className={styles.comment}
                                                                placeholder={Localizer.deviceServicePagePlaceholderComment}
                                                                textInputRef={this._commentRef}
                                                                value={this.service.comment || ""}
                                                                minLength={RentaToolsConstants.minimumCommentLength}
                                                                maxLength={RentaToolsConstants.maximumCommentLength}
                                                                onLeave={(value) => this.setServiceCommentAsync(value)}
                                    />

                                </div>

                            </div>
                        )
                    }

                    {
                        (this.device.supportsOperatingHours) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <NumberInput id={"operating_hours"}
                                                 step={0.01}
                                                 label={Localizer.deviceServicePageLabelOperatingHours}
                                                 behaviour={NumberInputBehaviour.ValidationOnChange}
                                                 value={this.state.operatingHours || 0}
                                                 onChange={async (_, value) => await this.setOperatingHoursAsync(value)}
                                    />

                                </div>

                                <br/>
                            </ArsenalPageRow>
                        )
                    }

                </div>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <h5>{Localizer.deviceServicePageLocationHeader}</h5>

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <Dropdown required
                                      className={this.css(newStyles.dropdown)}
                                      label={Localizer.deviceServicePageLocationLabelDepot}
                                      items={this.state.depos}
                                      selectedItem={this.service.depoId || undefined}
                                      onChange={async (_, item) => await this.setLocationDepoAsync(item!)}
                            />

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <Dropdown required
                                      className={this.css(newStyles.dropdown)}
                                      label={Localizer.deviceServicePageLocationLabelLocationType}
                                      onChange={async (_, item) => await this.setLocationTypeAsync(parseInt(item!.value))}
                                      items={EnumProvider.getServiceLocationTypeItems()}
                                      selectedItem={EnumProvider.getServiceLocationTypeItem(this.serviceLocationType)}
                                      orderBy={DropdownOrderBy.None}
                            />

                        </div>

                    </ArsenalPageRow>

                </div>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <h5>{Localizer.deviceServicePageInvoiceHeader}</h5>

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <div>
                                <Checkbox inline
                                          inlineType={InlineType.Right}
                                          label={Localizer.deviceServicePageInvoiceLabelServicedByRenta}
                                          value={this.service.servicedByRenta}
                                          onChange={async (_, value) => await this.setServicedByRentaAsync(value)}
                                />
                            </div>

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <Dropdown required
                                      className={this.css(newStyles.dropdown)}
                                      label={Localizer.deviceServicePageInvoiceLabelServiceExpense}
                                      items={this.state.serviceExpenses}
                                      selectedItem={this.service.serviceExpense || undefined}
                                      onChange={async (_, item) => await this.setServiceExpenseAsync(item)}
                            />

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <TextInput value={this.service.invoiceNumber}
                                       label={Localizer.deviceServicePageInvoiceLabelInvoiceNumber}
                                       onChange={async (sender, value) => await this.setInvoiceNumberAsync(value)}
                            />

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <NumberInput step={0.01}
                                         behaviour={NumberInputBehaviour.Restricted}
                                         value={this.service.cost}
                                         label={Localizer.deviceServicePageInvoiceLabelCost}
                                         onChange={async (sender, value) => await this.setCostAsync(value)}
                            />

                        </div>

                    </ArsenalPageRow>

                </div>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <ArsenalButton block big
                                           id={"saveServiceBtn"}
                                           className={this.css(styles.saveButton, newStyles.button)}
                                           type={ButtonType.Orange}
                                           label={Localizer.deviceServicePageButtonSaveService}
                                           icon={{name: "fas save"}}
                                           disabled={!this.canSave}
                                           confirm={Localizer.deviceServicePageConfirmationSaveService}
                                           onClick={async () => await this.saveServiceAsync()}
                            />

                        </div>

                    </ArsenalPageRow>

                    {
                        (this.state.serviceReports) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    {
                                        this.state.serviceReports.items.map((item) => (

                                            <ArsenalButton key={item.id} block
                                                           className={newStyles.button}
                                                           type={ButtonType.Orange}
                                                           label={`${ToolsUtility.toDateString(item.date)}: ${ServiceReport.getMaintenanceDescription(item)}`}
                                                           icon={{name: "far arrow-circle-right"}}
                                                           onClick={async () => await this.openServicePreviewPageAsync(item)}
                                            />

                                        ))
                                    }

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>
                        <div className={this.css("w-100", newStyles.col)}>
                            <ArsenalButton block big
                                           id={"backBtn"}
                                           type={ButtonType.Orange}
                                           className={rentaToolsStyles.arsenalButton}
                                           label={Localizer.devicePageReturnBack}
                                           onClick={async () => await this.returnBackAsync()}
                            />
                        </div>
                    </ArsenalPageRow>

                </div>

            </PageContainer>
        );
    }

    private getFaultTitle(fault: DeviceFault) {
        if (!fault.step) {
            return Localizer.genericManualFault;
        }

        return `${fault.stepNumber}. ${fault.step!.name || fault.step!.title}.`;
    }
}