import {BasePageDefinitions, PageRoute} from "@renta-apps/athenaeum-react-common";
import {IDeviceAnnualInspectionFormParams} from "@/pages/DeviceAnnualInspectionFormPage/DeviceAnnualInspectionFormPage";
import InvoicePageParameters from "@/models/InvoicePageParameters";
import {ISavePageParams} from "@/pages/SavePage/SavePage";
import {DeviceListOrderType} from "@/models/Enums";

export default class PageDefinitions extends BasePageDefinitions {

    protected async require(pageContainer: string, pageName: string): Promise<any> {
        pageName += "Page";
        return await require(`../pages/${pageContainer}${pageName}/${pageName}`);
    }

    constructor() {
        super();
    }

    public static readonly logoutRouteName: string = "Logout";

    public static readonly logoutRoute: PageRoute = new PageRoute(PageDefinitions.logoutRouteName);

    public static readonly dummyRouteName: string = "Dummy";

    public static readonly dummyRoute: PageRoute = new PageRoute(PageDefinitions.dummyRouteName);

    public static readonly accountRouteName: string = "Account";

    public static readonly accountRoute: PageRoute = new PageRoute(PageDefinitions.accountRouteName);

    public static readonly loginRouteName: string = "Login";

    public static readonly loginRoute: PageRoute = new PageRoute(PageDefinitions.loginRouteName);

    public static readonly adminRouteName: string = "Admin";

    public static readonly adminRoute: PageRoute = new PageRoute(PageDefinitions.adminRouteName);

    public static readonly applicationSettingsRouteName: string = "ApplicationSettings";

    public static readonly applicationSettingsRoute: PageRoute = new PageRoute(PageDefinitions.applicationSettingsRouteName);

    public static readonly homeRouteName: string = "Home";

    public static readonly homeRoute: PageRoute = new PageRoute(PageDefinitions.homeRouteName);

    public static readonly errorRouteName: string = "Error";

    public static readonly errorRoute: PageRoute = new PageRoute(PageDefinitions.errorRouteName);

    public static readonly contactSupportRouteName: string = "ContactSupport";

    public static readonly contactSupportRoute: PageRoute = new PageRoute(PageDefinitions.contactSupportRouteName);

    public static readonly offlineRouteName: string = "Offline";

    public static readonly offlineRoute: PageRoute = new PageRoute(PageDefinitions.offlineRouteName);

    // #region Renta Tools

    public static readonly deviceAnnualInspectionFormRouteName: string = "DeviceAnnualInspectionForm";

    public static readonly deviceAnnualInspectionFormRoute: (params: IDeviceAnnualInspectionFormParams)  => PageRoute =  (params: IDeviceAnnualInspectionFormParams)  => new PageRoute(PageDefinitions.deviceAnnualInspectionFormRouteName,null, null, params);

    public static readonly annualInspectionVendorsRouteName: string = "AnnualInspectionVendors";

    public static readonly annualInspectionVendorsRoute: PageRoute = new PageRoute(PageDefinitions.annualInspectionVendorsRouteName);

    public static readonly dashboardRouteName: string = "Dashboard";

    public static readonly dashboardRoute: PageRoute = new PageRoute(PageDefinitions.dashboardRouteName);

    public static readonly deviceRouteName: string = "Device";

    public static readonly deviceRoute: PageRoute = new PageRoute(PageDefinitions.deviceRouteName);

    public static readonly questionRouteName: string = "Question";

    public static readonly questionRoute: PageRoute = new PageRoute(PageDefinitions.questionRouteName);

    public static readonly questionPicturesRouteName: string = "QuestionPictures";

    public static readonly questionPicturesRoute: PageRoute = new PageRoute(PageDefinitions.questionPicturesRouteName);

    public static readonly picturesRouteName: string = "Pictures";

    public static readonly picturesRoute: PageRoute = new PageRoute(PageDefinitions.picturesRouteName);

    public static readonly instructionRouteName: string = "Instruction";

    public static readonly instructionRoute: PageRoute = new PageRoute(PageDefinitions.instructionRouteName);

    public static readonly resourceRouteName: string = "Resource";

    public static readonly resourceRoute: PageRoute = new PageRoute(PageDefinitions.resourceRouteName);

    public static readonly measuringResourceRouteName: string = "MeasuringResource";

    public static readonly measuringResourceRoute: PageRoute = new PageRoute(PageDefinitions.measuringResourceRouteName);

    public static readonly checksRouteName: string = "Checks";

    public static readonly checksRoute: PageRoute = new PageRoute(PageDefinitions.checksRouteName);

    public static readonly questionResourceRouteName: string = "QuestionResource";

    public static readonly questionResourceRoute: PageRoute = new PageRoute(PageDefinitions.questionResourceRouteName);

    public static readonly summaryRouteName: string = "Summary";

    public static readonly summaryRoute: PageRoute = new PageRoute(PageDefinitions.summaryRouteName);

    public static readonly deviceServiceRouteName: string = "DeviceService";

    public static readonly deviceServiceRoute: PageRoute = new PageRoute(PageDefinitions.deviceServiceRouteName);

    public static readonly picturesPreviewRouteName: string = "PicturesPreview";

    public static readonly picturesPreviewRoute: PageRoute = new PageRoute(PageDefinitions.picturesPreviewRouteName);

    public static readonly pictureEventsRouteName: string = "PictureEvents";

    public static readonly pictureEventsRoute: PageRoute = new PageRoute(PageDefinitions.pictureEventsRouteName);

    public static readonly saveRouteName: string = "Save";

    public static readonly saveRoute: (params: ISavePageParams)  => PageRoute =  (params: ISavePageParams)  => new PageRoute(PageDefinitions.saveRouteName,null, null, params);

    public static readonly inspectionReportsRouteName: string = "InspectionReports";

    public static readonly inspectionReportsRoute: PageRoute = new PageRoute(PageDefinitions.inspectionReportsRouteName);

    public static readonly serviceReportsRouteName: string = "ServiceReports";

    public static readonly serviceReportsRoute: PageRoute = new PageRoute(PageDefinitions.serviceReportsRouteName);

    public static readonly reportPreviewRouteName: string = "ReportPreview";

    public static readonly reportPreviewRoute: PageRoute = new PageRoute(PageDefinitions.reportPreviewRouteName);

    public static readonly servicePreviewRouteName: string = "ServicePreview";

    public static readonly servicePreviewRoute: PageRoute = new PageRoute(PageDefinitions.servicePreviewRouteName);

    public static readonly reportDefinitionsRouteName: string = "ReportDefinitions";

    public static readonly reportDefinitionsRoute: PageRoute = new PageRoute(PageDefinitions.reportDefinitionsRouteName);

    public static readonly reportDefinitionRouteName: string = "ReportDefinition";

    public static readonly reportDefinitionRoute: PageRoute = new PageRoute(PageDefinitions.reportDefinitionRouteName);

    public static readonly serviceReportDefinitionRouteName: string = "ServiceReportDefinition";

    public static readonly serviceReportDefinitionRoute: PageRoute = new PageRoute(PageDefinitions.serviceReportDefinitionRouteName);

    public static readonly mappingsRouteName: string = "Mappings";

    public static readonly mappingsRoute: PageRoute = new PageRoute(PageDefinitions.mappingsRouteName);

    public static readonly deposRouteName: string = "Depos";

    public static readonly deposRoute: PageRoute = new PageRoute(PageDefinitions.deposRouteName);

    public static readonly userManagementRouteName: string = "UserManagement";

    public static readonly userManagementRoute: PageRoute = new PageRoute(PageDefinitions.userManagementRouteName);

    public static readonly changePasswordRouteName: string = "ChangePassword";

    public static readonly changePasswordRoute: PageRoute = new PageRoute(PageDefinitions.changePasswordRouteName);

    public static readonly forgotPasswordRouteName: string = "ForgotPassword";

    public static readonly forgotPasswordRoute: PageRoute = new PageRoute(PageDefinitions.forgotPasswordRouteName);

    public static readonly resetPasswordRouteName: string = "ResetPassword";

    public static readonly resetPasswordRoute: PageRoute = new PageRoute(PageDefinitions.resetPasswordRouteName);

    public static readonly returnedDevicesPageRouteName: string = "ReturnedDevices";

    public static readonly returnedDevicesRoute: PageRoute = new PageRoute(PageDefinitions.returnedDevicesPageRouteName);

    public static readonly servicedDevicesPageRouteName: string = "ServicedDevices";

    public static readonly servicedDevicesRoute: PageRoute = new PageRoute(PageDefinitions.servicedDevicesPageRouteName);

    public static readonly serviceActionsPageRouteName: string = "ServiceActions";

    public static readonly serviceActionsRoute: PageRoute = new PageRoute(PageDefinitions.serviceActionsPageRouteName);

    public static readonly chartsPageRouteName: string = "Charts";

    public static readonly chartsRoute: PageRoute = new PageRoute(PageDefinitions.chartsPageRouteName);

    public static readonly invoicesRouteName: string = "Invoices";

    public static readonly invoicesRoute: PageRoute = new PageRoute(PageDefinitions.invoicesRouteName);

    public static readonly annualInspectionsRouteName: string = "AnnualInspections";

    public static readonly annualInspectionsRoute: PageRoute = new PageRoute(PageDefinitions.annualInspectionsRouteName);

    public static readonly invoiceDetailsRouteName: string = "InvoiceDetails";

    public static readonly invoiceDetailsRoute: PageRoute = new PageRoute(PageDefinitions.invoiceDetailsRouteName);

    public static readonly invoiceDetails: (invoiceParameters: InvoicePageParameters) => PageRoute = (invoiceParameters: InvoicePageParameters) => new PageRoute(PageDefinitions.invoiceDetailsRouteName, null, null,  invoiceParameters);

    public static readonly expensesRouteName: string = "Expenses";

    public static readonly expensesRoute: PageRoute = new PageRoute(PageDefinitions.expensesRouteName);

    public static readonly serviceExpensesRouteName: string = "ServiceExpenses";

    public static readonly serviceExpensesRoute: PageRoute = new PageRoute(PageDefinitions.serviceExpensesRouteName);

    public static readonly annualInspectionTypesRouteName: string = "AnnualInspectionTypes";

    public static readonly annualInspectionTypesRoute: PageRoute = new PageRoute(PageDefinitions.annualInspectionTypesRouteName);

    public static readonly takePicturesRouteName: string = "TakePictures";

    public static readonly takePicturesRoute: PageRoute = new PageRoute(PageDefinitions.takePicturesRouteName);

    public static readonly devicesManagementRouteName: string = "DevicesManagement";

    public static readonly devicesManagementRoute: PageRoute = new PageRoute(PageDefinitions.devicesManagementRouteName);

    public static readonly deviceAnnualInspectionRouteName: string = "DeviceAnnualInspection";

    public static readonly deviceAnnualInspectionRoute: PageRoute = new PageRoute(PageDefinitions.deviceAnnualInspectionRouteName);

    public static readonly devicePreviousAnnualInspectionsRouteName: string = "DeviceAnnualInspectionHistory";

    public static readonly devicePreviousAnnualInspectionsRoute: PageRoute = new PageRoute(PageDefinitions.devicePreviousAnnualInspectionsRouteName);

    public static readonly deviceDailyInfoRouteName: string = "DeviceDailyInfo";

    public static readonly deviceDailyInfoRoute: PageRoute = new PageRoute(PageDefinitions.deviceDailyInfoRouteName);

    public static readonly adminConsoleRouteName: string = "AdminConsole";

    public static readonly adminConsoleRoute: PageRoute = new PageRoute(PageDefinitions.adminConsoleRouteName);

    public static readonly returnInspectionPreviewPageRouteName: string = "ReturnInspectionPreview";

    public static readonly returnInspectionPreviewPageRoute: PageRoute = new PageRoute(PageDefinitions.returnInspectionPreviewPageRouteName);

    public static readonly phasesPage : string = "Phases";

    public static readonly phasePageRoute: PageRoute = new PageRoute(PageDefinitions.phasesPage);

    public static readonly dataManagementRouteName: string = "DataManagement";

    public static readonly dataManagementRoute: PageRoute = new PageRoute(PageDefinitions.dataManagementRouteName);

    public static readonly errorCodeRouteName: string = "ErrorCodes";

    public static readonly errorCodeRouteRoute: PageRoute = new PageRoute(PageDefinitions.errorCodeRouteName);

    public static readonly returnInspectionErrorCodeRouteName: string = "ReturnInspectionErrorCodes";

    public static readonly returnInspectionErrorCodeRoute: PageRoute = new PageRoute(PageDefinitions.returnInspectionErrorCodeRouteName);

    public static readonly operatingHoursRecordRouteName : string = "OperatingHourRecords";

    public static readonly operatingHoursRecordRoute: PageRoute = new PageRoute(PageDefinitions.operatingHoursRecordRouteName);

    public static readonly advancedChecksName : string = "AdvancedChecks";

    public static readonly advancedChecksRoute: PageRoute = new PageRoute(PageDefinitions.advancedChecksName);
    
    public static readonly depoSelectionRouteName : string = "DepoSelection";
    public static readonly depoSelectionRoute: (orderType : DeviceListOrderType) => PageRoute = (orderType : DeviceListOrderType) => new PageRoute(PageDefinitions.depoSelectionRouteName, null, null,  {orderType : orderType});
    
    public static readonly editOrderDeviceRouteName : string = "EditOrderDevice";
    public static readonly editOrderDeviceRoute: (depoId : string, orderType : DeviceListOrderType) => PageRoute = (depoId: string, orderType : DeviceListOrderType) => new PageRoute(PageDefinitions.editOrderDeviceRouteName, null, null,  {depoId : depoId, orderType : orderType});


    // #endregion
}

new PageDefinitions();