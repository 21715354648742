import AuthorizedPage from "@/models/base/AuthorizedPage";
import RentaToolsStorage, {RentaToolsStorageTable} from "@/pages/RentaToolsStorage";
import httpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";

interface ILogoutPageProps {}

interface ILogoutPageState {}

export default class AppLogoutPage extends AuthorizedPage<ILogoutPageProps, ILogoutPageState> {
  async initializeAsync(): Promise<void> {
      await super.initializeAsync();

      await httpClient.postAsync(EndpointPaths.ApplicationPaths.Logout);
      
      sessionStorage.clear();
      localStorage.clear();
      await RentaToolsStorage.clearDataAsync(RentaToolsStorageTable.Files);
      await RentaToolsStorage.clearDataAsync(RentaToolsStorageTable.DataStorage);
      
      await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute, true, true);
  }

  public render(): React.ReactNode {
    return null;
  }
}