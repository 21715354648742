import ReturnedDeviceList from "@/tests/pages/ReturnedDeviceList";
import DeviceFiltersModal from "@/tests/pages/DeviceFiltersModal";
import Localizer from "@/localization/Localizer";
import { ReturnInspectionStatus } from "@/models/Enums";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import DevicePage from "@/tests/pages/DevicePage";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

describe("Return Inspection tests", () => {
  beforeEach(() => {
    CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
    cy.login();
    cy.visit("Dashboard");
  });

  it("Return Inspections Page: Device row", () => {
    const testDeviceExternalId = "922691";
    const deviceName = "Device row tests";

    cy.goToReturnedDevicesPage();

    ReturnedDeviceList.openFilters();

    DeviceFiltersModal.filterByDeviceId("922691");

    DeviceFiltersModal.apply();

    ReturnedDeviceList.validateDeviceStatus(
        testDeviceExternalId,
        Localizer.returnInspectionWaitingForReturnInspection,
    );
    ReturnedDeviceList.shouldContainText(testDeviceExternalId, deviceName);
    ReturnedDeviceList.shouldContainText(testDeviceExternalId, "101");
    ReturnedDeviceList.shouldContainText(
        testDeviceExternalId,
        Localizer.returnInspectionListReturnedDate,
    );
    ReturnedDeviceList.validateDeviceStateColor(
        testDeviceExternalId,
        ReturnInspectionStatus.Waiting,
    );

    ReturnedDeviceList.openDevice(testDeviceExternalId);

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    cy.goToReturnedDevicesPage();

    ReturnedDeviceList.openFilters();

    DeviceFiltersModal.filterByDeviceId("922691");

    DeviceFiltersModal.apply();

    ReturnedDeviceList.validateDeviceStateColor(
        testDeviceExternalId,
        ReturnInspectionStatus.InProgress,
    );
  });
});