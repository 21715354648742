import React from 'react';
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import DeviceTelematics from "@/pages/Models/DeviceTelematics";
import {CellModel, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridOddType} from "@renta-apps/athenaeum-react-components";
import moment from "moment";
import {DeviceBatteryPotential} from "@/pages/Models/DeviceBatteryPotential";
import {EngineStatus} from "@/pages/Models/EngineStatus";
import Hours from "@/models/Hours";
import {LocationMetadata} from "@/pages/Models/LocationMetadata";
import Localizer from "@/localization/Localizer";

import styles from "@/components/TelematicsDataPanel/TelematicsDataPanel.module.scss";

interface TelematicsDataPanelProps {
    telematicsData: DeviceTelematics;
}

enum TelematicsDataType {
    Voltage,
    Percentage,
    Count,
    Distance
}

type GridData = {
    name: string,
    value: number | boolean | string | Date,
}

type Progressdata = DeviceBatteryPotential | LocationMetadata;

const TelematicsDataPanel = ({telematicsData, ...props}: TelematicsDataPanelProps ) => {
    
    const _telematicsGridRef: React.RefObject<Grid<GridData>> = React.createRef();

    const _telematicsColumns: ColumnDefinition[] = [
      
        {
            header: "",
            accessor: "name",
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true,
            settings: {
                required: true
            },
            editable: false
        },
        {
            header: "",
            accessor: "value",
            minWidth: 70,
            render: (cell: CellModel<GridData>) => initCell(cell),
            noWrap: true,
            settings: {
                required: true
            },
            className: styles.valueColumn
        }
    ];
    
    
    const initCell = (cell:  CellModel<GridData>): React.ReactNode => {
        if (typeof cell.value === "boolean") {
                return (cell.value === true) ? "On" : "Off";
        } else if (typeof cell.value === "object") {
            const asDate = new Date(cell.value);
            const isDate = !isNaN(asDate.valueOf())
            
            if (isDate)
            {
                return moment(cell.value).format("DD-MM-YYYY hh:mm")
            }
        } else if (typeof cell.value === "number")
        {
            return Math.round(cell.value);
        }
        
        return cell.value ||  "NO DATA";
    }
        
   const progressData = (): [string, Progressdata][] | []  => {
        return Object.entries(telematicsData).filter(item => isProgressData(item[0]));
    } 
    
   const isProgressData = (key: string): boolean => {
        return (key.toLowerCase().includes("battery") || key.toLowerCase().includes("location"))
    }
    
    const getGridDataModel = (data: DeviceTelematics): GridData[] => {
        const items: GridData[] = [];
        Object.entries(data)
          .filter(item => !isProgressData(item[0]))
          .forEach(item => {
              let dataitem: GridData = {name: GetLocalizedName(item[0]), value: getValue(item[1])} 
              items.push(dataitem);
          });
        
        return items;
    }
    
    const GetEnumKey = (item: string): string =>  {
        item = item[0].toUpperCase() + item.substring(1);
        
        return `Enum.DeviceTelematicsData.${item}`;
    }
    
    const GetLocalizedName = (item: string): string => {
        return Localizer.get(GetEnumKey(item));
    }
    
    const getValue = (item: DeviceBatteryPotential | EngineStatus | Hours | LocationMetadata | string | Date): number | boolean | string | Date => {

        if (item instanceof DeviceBatteryPotential){
            return item.batteryPotential;
        }

        if (item instanceof EngineStatus) {
            return item.running;
        }

        if (item instanceof Hours) {
            return item.hour;
        }

        if (item instanceof LocationMetadata) {
            return item.accuracyRadius;
        }

        return item;
    }
   const getProgressStyles = (item: Progressdata, max: number) => {
        
        const value: number = getValue(item) as number;
        if (item instanceof LocationMetadata)
        {
            if(value > max*0.75){
                return styles.progressBarRed;
            }
            
            if(value > max*0.49){
                return styles.progressBarYellow;
            }
            
            if(value > max*0.25){
                return styles.progressBarYellow;
            }
            
            return styles.progressBar;
        }
        
        if(value > max*0.75){
           return styles.progressBar;
        }

        return styles.progressBarYellow;
    }                
    
   const getDataType = (key: string): TelematicsDataType => {
        
       if (key.toLowerCase().includes("battery")) {
           return TelematicsDataType.Voltage
       } 
       
       if (key.toLowerCase().includes("percentage")) {
           return TelematicsDataType.Percentage
       }
              
       if (key.toLowerCase().includes("location")) {
           return TelematicsDataType.Distance
       }
       
        return TelematicsDataType.Count
    }
    
    const getUnit = (key: string): string => {
       const type = getDataType(key);

       switch (type) {
           case TelematicsDataType.Voltage:
               return " V";
           case TelematicsDataType.Percentage:
               return "%";  
           case TelematicsDataType.Distance:
               return " m";
           default:
               return "";
       }
    }
    
    const getMaxValue = (key: string): number => {
       const type = getDataType(key);
       
       switch (type) {
           case TelematicsDataType.Voltage:
               return 48;
           case TelematicsDataType.Percentage:
               return 100;     
           case TelematicsDataType.Distance:
               return 100;
           default:
               return 10;
       }
    }
    
    
    
    return (
        <>
            <ArsenalPageRow className={styles.container}>

                <Grid responsive readonly
                      className={styles.telematicsDataTable}
                      ref={_telematicsGridRef}
                      hovering={GridHoveringType.None}
                      odd={GridOddType.None}
                      columns={_telematicsColumns}
                      data={getGridDataModel(telematicsData)}
                />

            </ArsenalPageRow>
            {
                progressData().map((item: [string, Progressdata]) =>
                    <ArsenalPageRow className={styles.progressContainer} key={item[0]}>
                        <p className={styles.progresslabel}>{GetLocalizedName(item[0])}</p>
                        <p className={styles.progresslabel}>{(getValue(item[1]) ?? "").toString()}{getUnit(item[0])}</p>

                        <progress id={item[0]} max={getMaxValue(item[0])} value={getValue(item[1]) as number} className={getProgressStyles(item[1], getMaxValue(item[0]))}/>
                    </ArsenalPageRow>
                )
            }
        </>
    );
};

export default TelematicsDataPanel;