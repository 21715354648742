import {DeviceBatteryPotential} from "@/pages/Models/DeviceBatteryPotential";
import {CumulativeOperatingHours} from "@/pages/Models/CumulativeOperatingHours";
import {EngineStatus} from "@/pages/Models/EngineStatus";
import {CumulativeIdleHours} from "@/pages/Models/CumulativeIdleHours";
import {LocationMetadata} from "@/pages/Models/LocationMetadata";

export default class DeviceTelematics 
{
    constructor(data?: DeviceTelematics | null ) {
        this.serialNumber = data?.serialNumber || "";
        this.engineStatus = new EngineStatus();
        this.batteryPotential = new DeviceBatteryPotential(data?.batteryPotential);
        this.cumulativeOperatingHours = new CumulativeOperatingHours(data?.cumulativeOperatingHours);
        this.cumulativeIdleHours = new CumulativeIdleHours(data?.cumulativeIdleHours);
        this.locationMetadata = new LocationMetadata(data?.locationMetadata);
        this.timestamp = data?.timestamp ?? null;
    }
    
    public serialNumber: string = ""; 

    public engineStatus: EngineStatus | null = null;
    
    public batteryPotential: DeviceBatteryPotential | null = null;
    
    public cumulativeOperatingHours: CumulativeOperatingHours | null = null;
    
    public cumulativeIdleHours:  CumulativeIdleHours | null = null;
    
    public locationMetadata: LocationMetadata | null = null;
    
    public timestamp: Date | null = null;
}