import React from "react";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, GoogleMap, IGoogleMapMarker, PageContainer, PageHeader, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Report from "../Models/Report";
import QuestionPicturesReportItem from "../Models/QuestionPicturesReportItem";
import ArsenalPicturesCarousel from "../../components/ArsenalPicturesCarousel/ArsenalPicturesCarousel";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Device from "../Models/Device";
import DevicePicture from "@/models/server/DevicePicture";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./PicturesPreviewPage.module.scss";
import ToolsUtility from "@/helpers/ToolsUtility";
import ObservationDetailsResponse from "@/models/server/responses/ObservationDetailsResponse";
import DeviceContract from "@/models/server/DeviceContract";
import {Fault} from "@/models/server/DeviceInfo";
import User from "@/models/server/User";

interface IPicturesPreviewPageProps {
}

interface IPicturesPreviewPageState {
    latitude: number | null;
    longitude: number | null;
    contract: DeviceContract | null;
    faults: Fault[];
    recordDate: Date | null;
    comment: string;
    user: User | null;
}

export default class PicturesPreviewPage extends AuthorizedPage<IPicturesPreviewPageProps, IPicturesPreviewPageState> {

    state: IPicturesPreviewPageState = {
        latitude: null,
        longitude: null,
        contract: null,
        faults: [],
        recordDate: null,
        comment: "",
        user: null,
    };

    private get title(): string {
        return `${this.device.externalId} ${this.device.name}\n
        ${(this.report != null)
            ? "{0} {1}".format(Localizer.reportReport, ToolsUtility.toDateString(this.report.completedAt))
            : (this.devicePictureItem != null)
                ? "{0} {1}".format(Localizer.enumDevicePictureTypeManual, ToolsUtility.toDateString(this.devicePictureItem.createdAt))
                : ""}`;
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get report(): Report | null {
        return RentaToolsController.context.picturePreviewReport;
    }

    private get devicePictureItem(): DevicePicture | null {
        return RentaToolsController.context.devicePicture;
    }

    private get reportItem(): QuestionPicturesReportItem {
        let reportItem: QuestionPicturesReportItem | null = RentaToolsController.context.picturePreviewReportItem;

        if (reportItem == null)
            throw Error(Localizer.picturesPreviewPageErrorPicturesCannotBeFound);

        return reportItem;
    }

    private get reportPictures(): FileModel[] {
        const pictures: FileModel[] = [];

        pictures.push(...this.reportItem.pictures || [])

        if (this.reportItem.picture) {
            pictures.push(this.reportItem.picture);
        }

        return pictures;
    }

    private get pictureSubtitle(): string {
        return ((this.report != null) && (this.report.name != null))
            ? Localizer.get(Localizer.picturesPreviewPageArsenalPicturesCarouselSubtitle, this.report.name)
            : Localizer.enumDevicePictureTypeManual;
    }

    private get hasLocation(): boolean {
        return this.state.latitude != null && this.state.longitude != null;
    }

    private get location(): IGoogleMapMarker[] {
        return [
            {
                position: {
                    lat: this.latitude ?? 0,
                    lng: this.longitude ?? 0
                }
            }
        ];
    }

    private get user(): User | null {
        return this.state.user;
    }

    private get userName(): string {
        if (!this.state.user) {
            return "-";
        }

        if (this.state.user.firstName && this.state.user.lastName) {
            return `${this.state.user.firstName} ${this.state.user.lastName}`;
        }

        if (this.state.user.username) {
            return this.state.user.username;
        }

        return "-";
    }

    private get observationDate(): Date | null {
        return this.state.recordDate;
    }

    private get contract(): DeviceContract | null {
        return this.state.contract;
    }

    private get faults(): Fault[] {
        return this.state.faults;
    }

    private get latitude(): number {
        return this.state.latitude!;
    }

    private get longitude(): number {
        return this.state.longitude!;
    }

    private get hasFaults(): boolean {
        return this.state.faults.length > 0;
    }

    private get hasComment(): boolean {
        return !!this.state.comment;
    }

    private get comment(): string {
        return this.state.comment;
    }

    public getManual(): string {
        return Localizer.picturesPreviewPageManual;
    }

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.devicePictureItem) {
            return
        }

        const observationResponse: ObservationDetailsResponse = await this.postAsync("api/device/getObservationDetails", this.devicePictureItem.groupKey);
        if (observationResponse?.success) {
            this.setState({
                faults: observationResponse.faults,
                contract: observationResponse.contract,
                latitude: observationResponse.latitude,
                longitude: observationResponse.longitude,
                recordDate: observationResponse.recordDate,
                comment: observationResponse.comment,
                user: observationResponse.createdBy
            })
        }
    }

    public render(): React.ReactNode {
        const commentModalId: string = `modal_comment-picture`;
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.picturesPreview)}>

                <PageHeader title={this.title} className={rentaToolsStyles.leftPageHeader}>

                    <span className={rentaToolsStyles.rightPageHeader}/>

                </PageHeader>

                <span className={rentaToolsStyles.wizardDescription}>{this.reportItem.name}</span>

                <ArsenalPageRow className={styles.pictureContainer}>

                    <ArsenalPicturesCarousel subtitle={this.pictureSubtitle}
                                             items={this.reportPictures}
                    />


                    {
                        (this.reportItem.commented) &&
                        (
                            <ArsenalPageRow>
                                <span className={styles.comment}>{this.reportItem.comment}</span>
                            </ArsenalPageRow>
                        )
                    }

                    {
                        (this.hasComment) &&
                        (
                            <>
                                <br/>
                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.invoiceDetailsPageComment} </span>

                                <TextAreaInput readonly
                                               className={styles.observationComment}
                                               value={this.comment}/>

                            </>
                        )
                    }

                    {
                        (this.hasLocation) && (
                            <div className={this.css("col-md-12")}>
                                <br/>

                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.deviceServicePageLocationHeader} </span>

                                <GoogleMap height={500}
                                           initialCenter={{lat: this.latitude, lng: this.longitude}}
                                           initialZoom={10}
                                           markers={this.location}
                                />
                            </div>
                        )
                    }

                    {
                        (this.contract) && (
                            <>
                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.genericContractDetails} </span>

                                <table className={this.css(styles.table, "table table-striped")}>
                                    <tbody>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageCustomerName}</td>
                                        <td>{"{0}".format(this.contract.customerName || "-")}</td>
                                    </tr>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageConstructionSite}</td>
                                        <td>{"{0}".format(this.contract.constructionSiteName || "-")}</td>
                                    </tr>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageContractId}</td>
                                        <td>{"{0}".format(this.contract.contractExternalId)}</td>
                                    </tr>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageConstructionSiteNumber}</td>
                                        <td>{this.contract.constructionSiteExternalId ? "{0}".format(this.contract.constructionSiteExternalId) : "-"}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </>
                        )
                    }

                    {
                        (this.hasFaults) && (
                            <>
                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.deviceServicePageFaultsHeader} </span>

                                <table className={this.css(styles.table, "table table-striped")}>
                                    <tbody>

                                    {
                                        (this.observationDate) && (
                                            <tr>
                                                <td>{Localizer.genericObservationDate}</td>
                                                <td>{ToolsUtility.toDateStringWithTime(this.observationDate, "", "", "HH:mm")}</td>
                                            </tr>
                                        )
                                    }

                                    {
                                        (this.user) && (
                                            <tr>
                                                <td>{Localizer.userManagementPageGridCreatedBy}</td>
                                                <td>{this.userName}</td>
                                            </tr>
                                        )
                                    }

                                    {
                                        this.faults.map((fault, index) => {
                                            return (
                                                <tr>
                                                    <td>{Localizer.genericManualFault}</td>
                                                    <td>{fault.faultText}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </>
                        )
                    }

                </ArsenalPageRow>


                <ArsenalPageRow>
                    <ArsenalButton big block
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   icon={{name: "fas chevron-left"}}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                </ArsenalPageRow>

            </PageContainer>
        );
    }
}