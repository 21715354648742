import {BasePage, BasePageParameters, ch, IManualProps, PageRoute, PageRouteProvider, ReactUtility} from "@renta-apps/athenaeum-react-common";
import UserContext from "../server/UserContext";
import User from "../server/User";
import PageDefinitions from "../../providers/PageDefinitions";
import React, {ReactNode} from "react";
import {TwoColumns} from "@renta-apps/athenaeum-react-components";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import UnleashHelper from "@/helpers/UnleashHelper";

import rentaToolsStyles from "../../pages/RentaTools.module.scss";

export default abstract class AuthorizedPage<TParams extends BasePageParameters = {}, TState = {}>
    extends BasePage<TParams, TState, UserContext> {

    public getUser(): User {
        return this.getContext().user!;
    }

    public async manualPropsCallback(): Promise<void> {
        return;
    }

    public getManualProps(): IManualProps {

        if (this.customInfoModal) {
            return {
                onClick: async () => await this.manualPropsCallback()
            };
        }

        return {
            manual: this.getManual()
        };

    }

    get automaticUrlChange(): boolean {
        return true;
    }

    protected get infoPageImage(): string {
        try {
            return require(`@/images/info/${this.routeName}-Info-${ch.language}.png`);
        } catch {
            return "";
        }
    }

    protected get customInfoModal(): boolean {
        return false;
    }

    protected get isSweden(): boolean {
        return (ch.country === "se" || ch.country === "sv");
    }

    protected getAdditionalInfoPageImage(index: number): string {
        try {
            return require(`@/images/info/${this.routeName}-additional-${index.toString()}-${ch.language}.png`);
        } catch {
            return "";
        }
    }

    protected getPageInfo(content: string, separator: string = "\n\n"): React.ReactNode {

        let paragraphs: string[] = content.split(separator);
        let additionalImages: { [id: string]: string } = {};

        paragraphs.forEach((par, index) => {
            additionalImages[(index).toString()] = this.getAdditionalInfoPageImage(index);
        })

        return (
            <TwoColumns>
                <img className={rentaToolsStyles.inheritWidth} src={this.infoPageImage} alt={"Picture of the current page"}/>
                <div className={rentaToolsStyles.inheritWidth} style={{whiteSpace: "pre-line"}}>
                    {
                        paragraphs.map((item: ReactNode | string, index: number) => {
                            return (
                                <div key={index}>
                                    <p>{ReactUtility.toTags(item as string)}</p>
                                    {
                                        (additionalImages[index]) &&
                                        (<img className={rentaToolsStyles.inheritWidth} src={additionalImages[`${index}`]} alt={`Additional picture ${index}`}/>)
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </TwoColumns>
        );
    }

    /**
     * {@link BasePage.parameters} with strong typing.
     */
    protected get typedParameters(): TParams | null {
        return this.parameters as TParams;
    }

    protected get isAdminOrManager(): boolean {
        const context: UserContext = this.getContext();
        return (context.isAdmin
            || context.isAreaManager
            || context.isDepoManager);
    }

    protected get isAdmin(): boolean {
        const context: UserContext = this.getContext();
        return (context.isAdmin);
    }

    public async initializeAsync(): Promise<void> {

        if (!this.isAuthorized) {
            const anonymousRoute: PageRoute = (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagHomePageEnabled))
                ? PageDefinitions.homeRoute
                : PageDefinitions.loginRoute;

            await PageRouteProvider.redirectAsync(anonymousRoute, true, true);
        }

        await super.initializeAsync();

        // Update userId on authorized page load to set the userId after the user has logged in.
        // Updating the userId only once in `Login.loginAsync()` would be optimal, but sometimes it does not work, maybe because context does not get updated fast enough.
        // Here the context always contains the user details.
        UnleashHelper.updateClientUserId();
    }
}