import {FaultLevel, MeasuringOperators, ReportItemType, ResourceItemType} from "@/models/Enums";
import CheckItem from "@/pages/Models/CheckItem";
import QuestionReportItem from "@/pages/Models/QuestionReportItem";
import QuestionResourceReportItem from "@/pages/Models/QuestionResourceReportItem";
import QuestionPicturesReportItem from "@/pages/Models/QuestionPicturesReportItem";
import UserContext from "@/models/server/UserContext";
import Phase from "@/models/server/Phase";
import DeviceTelematics from "@/pages/Models/DeviceTelematics";
import DeviceFault from "@/pages/Models/DeviceFault";
import AdditionalExpenseValue from "@/models/server/AdditionalExpenseValue";

export default class ReportDefinitionItem {

    public id: string = "";

    public title: string = "";

    public type: ReportItemType = ReportItemType.QuestionPictures;

    public name: string | null = null;

    public icon: string | null = null;

    public manual: string | null = null;

    public value: number | null = null;

    public telematicsData: DeviceTelematics | null = null;

    public isTelematicsStep: boolean = false;

    public permanentFault: boolean | null = null;

    /* Checks */
    public checks: CheckItem[] | null = null;

    /* MeasuringResource */
    public passedValue: number | null = null;

    /* MeasuringResource */
    public passedValueOperator: MeasuringOperators | null = null;

    /* Resource and QuestionResource */
    public resourceType: ResourceItemType | null = null;

    /* Resource and QuestionResource */
    public valueTypeId: string | null = null;

    /* Resource and QuestionResource */
    public valueTypeIds: string[] | null = null;

    /* Resource, QuestionResource and MeasuringResource */
    public min: number | null = null;

    /* Resource, QuestionResource and MeasuringResource */
    public max: number | null = null;

    /* Resource, QuestionResource and MeasuringResource */
    public step: number | null = null;

    /* Resource, QuestionResource and MeasuringResource */
    public default: number | null = null;

    /* Resource, QuestionResource and MeasuringResource */
    public defaultWashingTypeId: string | null = null;

    /* Resource, QuestionResource and MeasuringResource */
    public defaultFuelTypeId: string | null = null;

    /* Resource, QuestionResource and MeasuringResource */
    public defaultAdBlueTypeId: string | null = null;

    /* Question, QuestionResource and QuestionPictures */
    public minFaultLevel: FaultLevel | null = null;

    public completed: boolean | null = null;

    public phase: Phase | null = null;

    public isTouched: boolean | null = null;

    public additionalExpensesCanBeAdded: boolean = false;

    public additionalExpenseTypeIds: string[] | null = null;

    public additionalExpenseValues: AdditionalExpenseValue[] = [];

    public isReportDefinitionItem: boolean = true;

    public static userHasRightsToInvoiceAdditionalExpenses(userContext: UserContext): boolean {
        return (userContext.user != null) && (userContext.user.canInvoiceAdditionalExpenses);
    }

    public static userHasRightsToInvoiceFuelingAndWashing(userContext: UserContext): boolean {
        return (userContext.user != null) && (userContext.user.canInvoiceFuelingAndWashing);
    }

    public static canInvoiceFuelingAndWashing(item: ReportDefinitionItem, userContext: UserContext): boolean {
        return (this.userHasRightsToInvoiceFuelingAndWashing(userContext)) &&
            ((item.resourceType == ResourceItemType.Fueling) ||
                (item.resourceType == ResourceItemType.Washing) ||
                (item.resourceType == ResourceItemType.AdBlue));
    }

    public static getCommentFromStep(item : ReportDefinitionItem): string | null {
        switch (item.type) {
            case ReportItemType.Question:
                return (item as QuestionReportItem).comment;
            case ReportItemType.QuestionResource:
                return (item as QuestionResourceReportItem).comment;
            case ReportItemType.QuestionPictures:
                return (item as QuestionPicturesReportItem).comment;
        }

        return null;
    }

    public static getComment(fault : DeviceFault): string | null {
        if (!fault.step) {
            return fault.comment;
        }

        const item: ReportDefinitionItem = fault.step!;

        switch (item.type) {
            case ReportItemType.Question:
                return (item as QuestionReportItem).comment;
            case ReportItemType.QuestionResource:
                return (item as QuestionResourceReportItem).comment;
            case ReportItemType.QuestionPictures:
                return (item as QuestionPicturesReportItem).comment;
            default:
                return fault.comment;
        }
    }
}