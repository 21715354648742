import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ActionType} from "@/models/Enums";
import ServiceExpense from "@/models/server/ServiceExpense";
import SaveServiceExpenseResponse from "@/models/server/responses/SaveServiceExpenseResponse";
import AddServiceExpenseRequest from "@/models/server/requests/AddServiceExpenseRequest";
import SaveServiceExpenseRequest from "@/models/server/requests/SaveServiceExpenseRequest";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ServiceExpensesPage.module.scss";

interface IServiceExpensesPageProps {
}

interface IServiceExpensesPageState {
    showDeleted: boolean
}

export default class ServiceExpenses extends AuthorizedPage<IServiceExpensesPageProps, IServiceExpensesPageState> {

    state: IServiceExpensesPageState = {
        showDeleted: false
    };

    private readonly _serviceExpensesGridRef: React.RefObject<Grid<ServiceExpense>> = React.createRef();

    private readonly _serviceExpensesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 70,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.serviceExpensesPageGridNameLanguageItemName,
            accessor: nameof<ServiceExpense>(w => w.name),
            minWidth: 250,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.serviceExpensesPageGridProductExternalIdLanguageItemName,
            accessor: nameof<ServiceExpense>(w => w.productExternalId),
            minWidth: 270,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initServiceExpensesOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.serviceExpensesPageGridSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processServiceExpenseOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.serviceExpensesPageGridCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processServiceExpenseOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.serviceExpensesPageGridDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processServiceExpenseOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.serviceExpensesPageGridRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processServiceExpenseOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get serviceExpensesGrid(): GridModel<ServiceExpense> {
        return this._serviceExpensesGridRef.current!.model;
    }

    private async getServiceExpensesAsync(pageNumber: number, pageSize: number): Promise<ServiceExpense[]> {
        let serviceExpenses: ServiceExpense[] = await this.serviceExpensesGrid.postAsync("api/classifier/getServiceExpenses", null);
        if (!this.state.showDeleted) {
            serviceExpenses = serviceExpenses.filter(item => !item.isDeleted)
        }
        return serviceExpenses;
    }

    private initRow(row: RowModel<ServiceExpense>): void {
        const model: ServiceExpense = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = this.isValid(model);
        row.deleted = model.isDeleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private async addServiceExpenseAsync(): Promise<void> {
        const serviceExpense = new ServiceExpense();

        const newRows: RowModel<ServiceExpense>[] = await this.serviceExpensesGrid.insertAsync(0, serviceExpense);

        const newRow: RowModel<ServiceExpense> = newRows[0];
        const nameCell: CellModel<ServiceExpense> = newRow.get("name");

        await nameCell.editAsync(true);
    }

    private isValid(serviceExpense: ServiceExpense): boolean {
        return (!!serviceExpense.name) && (!!serviceExpense.productExternalId);
    }

    private async initServiceExpensesOperationsAsync(cell: CellModel<ServiceExpense>): Promise<void> {

        const model: ServiceExpense = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<ServiceExpense> = cell.actions[0];
        const cancelAction: CellAction<ServiceExpense> = cell.actions[1];
        const deleteAction: CellAction<ServiceExpense> = cell.actions[2];
        const restoreAction: CellAction<ServiceExpense> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    private getDeleteConfirmation(cell: CellModel<ServiceExpense>): string {
        const model: ServiceExpense = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types require returned value to be a string, but has been like this forever
            ? null as any
            : Utility.format(Localizer.serviceExpensesPageConfirmationButtonDelete, `'${cell.model.name} (${cell.model.productExternalId})'`);
    }

    private async processServiceExpenseOperationAsync(cell: CellModel<ServiceExpense>, action: CellAction<ServiceExpense>): Promise<void> {

        await ch.hideAlertAsync();

        const model: ServiceExpense = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {

                const request = new AddServiceExpenseRequest();
                request.name = model.name;
                request.productExternalId = model.productExternalId;

                const response: SaveServiceExpenseResponse = await cell.grid.postAsync("api/classifier/addServiceExpense", request);

                if (response.serviceExpenseAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.serviceExpensesPageAlertErrorAsyncServiceExpenseExist, model.name, model.productExternalId), true);
                    return;
                }

                cell.row.model = response.serviceExpense!;

                await cell.row.saveAsync();

            } else {

                const request = new SaveServiceExpenseRequest();
                request.id = model.id;
                request.name = model.name;
                request.productExternalId = model.productExternalId;

                const response: SaveServiceExpenseResponse = await cell.grid.postAsync("api/classifier/saveServiceExpense", request);

                if (response.serviceExpenseAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.serviceExpensesPageAlertErrorAsyncServiceExpenseExist, model.name, model.productExternalId), true);
                    return;
                }

                cell.row.model = response.serviceExpense!;

                await cell.row.bindAsync();
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            model.isDeleted = true;

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                await cell.grid.postAsync("api/classifier/deleteServiceExpense", model.id);

                await cell.row.setDeletedAsync(true);
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await cell.grid.postAsync("api/classifier/restoreServiceExpense", model.id);

                model.isDeleted = false;
            }

            await cell.row.setDeletedAsync(false);
        }
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.serviceExpensesGrid.reloadAsync();
    }

    public render(): React.ReactNode {
        return (

            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.serviceExpenses)}>
                <PageHeader title={Localizer.serviceExpensesPageTitle}/>
                <PageRow>
                    <div className="col">

                        <div className={styles.container}>

                            <ToolbarContainer className={styles.toolbar}>

                                <Inline justify={JustifyContent.End}>

                                    <Checkbox inline
                                              label={Localizer.userManagementPageCheckboxShowDeleted}
                                              value={this.state.showDeleted}
                                              onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                                    />

                                    <Button title={Localizer.serviceExpensesPageButtonReload} className="ml-1"
                                            icon={{name: "far history", size: IconSize.Large}}
                                            type={ButtonType.Info}
                                            onClick={async () => await this.reloadAsync()}
                                    />

                                    <Button icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Orange}
                                            title={Localizer.serviceExpensesPageButtonAddServiceExpense}
                                            onClick={async () => await this.addServiceExpenseAsync()}
                                    />

                                </Inline>

                            </ToolbarContainer>

                            <Grid responsive
                                  className={styles.expensesGrid}
                                  ref={this._serviceExpensesGridRef}
                                  hovering={GridHoveringType.EditableCell}
                                  odd={GridOddType.None}
                                  minWidth="auto"
                                  columns={this._serviceExpensesColumns}
                                  initRow={(row) => this.initRow(row)}
                                  fetchData={async (sender, pageNumber, pageSize) => await this.getServiceExpensesAsync(pageNumber, pageSize)}
                            />

                        </div>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}