import React from "react";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import {BaseAsyncComponent, IBaseAsyncComponentState, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import ArsenalPagination from "@/components/ArsenalPagination/ArsenalPagination";
import DevicePicture from "@/models/server/DevicePicture";
import ListDevicePicturesRequest from "@/models/server/requests/ListDevicePicturesRequest";
import {Button, ButtonType, Icon, IconSize} from "@renta-apps/athenaeum-react-components";
import {DevicePictureType} from "@/models/Enums";
import QuestionPicturesReportItem from "@/pages/Models/QuestionPicturesReportItem";
import Report from "@/pages/Models/Report";
import DevicePictureFiltersData from "@/models/server/DevicePictureFiltersData";
import PicturesFiltersModal from "@/pages/PictureEventsPage/PicturesFiltersModal/PicturesFiltersModal";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./PicturesList.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import ToolsUtility from "@/helpers/ToolsUtility";

interface IPicturesListProps {
    useReportPageStyles?: boolean;
    noDataText: string;
    request: ListDevicePicturesRequest;
    filtersData: DevicePictureFiltersData;

    fetchData(sender: IBaseComponent, request: ListDevicePicturesRequest): Promise<IPagedList<DevicePicture>>;

    onOpenPictureListItem(sender: IBaseComponent, item: DevicePicture): Promise<void>;
}

interface IPicturesListState extends IBaseAsyncComponentState<IPagedList<DevicePicture>> {
    request: ListDevicePicturesRequest;
}

export default class PicturesList extends BaseAsyncComponent<IPicturesListProps, IPicturesListState, IPagedList<DevicePicture>> {

    state: IPicturesListState = {
        data: null,
        isLoading: false,
        request: this.props.request
    };

    private readonly _picturesFiltersModalRef: React.RefObject<PicturesFiltersModal> = React.createRef();

    private isManualPictureType(item: DevicePicture): boolean {
        return item.type == DevicePictureType.Manual;
    }

    private getReportItemName(fileId: string, report: Report): string {
        const pictureItem: QuestionPicturesReportItem = Report.getQuestionPicturesReportItemByFileId(fileId, report);

        if (pictureItem && report.items.length > 0) {
            const stepIndex = report.items.indexOf(pictureItem);
            const stepAmount = report.items.length;

            if (pictureItem.name && !pictureItem.title) {
                return `${pictureItem.name} (${stepIndex}/${stepAmount})`
            }

            const name = pictureItem.name === pictureItem.title ? pictureItem.name : `${pictureItem.name} - ${pictureItem.title}`;

            return `${name} (${stepIndex}/${stepAmount})`;
        }

        return pictureItem.title || pictureItem.name || Localizer.picturesPreviewPagePicturesListNoItemData;
    }

    private renderItems(): React.ReactNode {
        if (this.props.useReportPageStyles) {
            return (
                <table className={styles.table}>
                    <tbody>
                    {
                        this.state.data?.items.map((item, index) => (
                            <tr key={index}>

                                <td className={styles.icon}>
                                                  <span>
                                                        <Icon name="far camera" size={IconSize.Large} tooltip={Localizer.picturesListObservation}/>
                                                    </span>
                                </td>

                                <td className={styles.info}>
                                                    <span onClick={async () => await this.onItemClickAsync(item)}>
                                                        <div className={styles.report}>
                                                            {(!this.mobile) && Localizer.picturesListObservationOrAttention.toUpperCase()} {ToolsUtility.toDateString(item.createdAt)}
                                                        </div>
                                                        {
                                                            (!this.mobile) &&
                                                            (
                                                                <div className={styles.user}>
                                                                    {(item.createdBy) && (TransformProvider.userToString(item.createdBy))}
                                                                </div>
                                                            )
                                                        }
                                                        <div className={styles.depo}>
                                                            {item.costPool}
                                                        </div>
                                                    </span>
                                </td>

                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            );
        }

        return (
            <table className={styles.table}>
                <tbody>
                {
                    this.state.data?.items.map((item, index) =>
                        (
                            <tr key={index}>

                                <td className={styles.info}>

                                    <span className={"align-items-center"}
                                          onClick={async () => await this.onItemClickAsync(item)}>

                                        <div className={styles.report}>

                                            <div className={this.css((!this.isManualPictureType(item)) && styles.twoLines, styles.topSpace)}>
                                                <span>{EnumProvider.getDevicePictureTypeText(item.type)}</span>
                                                {
                                                    (item.returnInspectionReportId) &&
                                                    (
                                                        <span>{this.getReportItemName(item.fileId, item.returnInspectionReport!)}</span>
                                                    )
                                                }
                                            </div>

                                        </div>

                                        <div className={this.css(styles.user, styles.twoLines, styles.topSpace)}>
                                            <span>{ToolsUtility.toDateString(item.createdAt)}</span>
                                            <span>{(item.createdBy) && (TransformProvider.userToString(item.createdBy))}</span>
                                        </div>

                                         <div className={styles.depo}>
                                             {item.costPool}
                                        </div>

                                    </span>
                                </td>

                            </tr>
                        ))
                }
                </tbody>
        </table>
        );
    }

    private async openFiltersAsync(): Promise<void> {
        await this._picturesFiltersModalRef.current!.openAsync();
    }

    private async submitFiltersAsync(request: ListDevicePicturesRequest): Promise<void> {
        request.resetPage();
        
        await this.setState({request});
        await this.reloadAsync();
    }

    private async onItemClickAsync(item: DevicePicture): Promise<void> {
        await this.props.onOpenPictureListItem(this, item);
    }

    protected async fetchDataAsync(): Promise<IPagedList<DevicePicture>> {
        return this.props.fetchData(this, this.state.request);
    }

    private async changePageNumberAsync(pageNumber: number): Promise<void> {
        this.state.request.pageNumber = pageNumber;

        await this.reloadAsync();
    }

    protected getEndpoint(): string {
        return "";
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    public getManual(): string {
        return "PicturesList";
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.picturesList}>

                {!this.props.useReportPageStyles ?
                    (
                        <div className={newStyles.col}>
                            <div className={this.css(styles.header, newStyles.header)}>

                                <h4>{Localizer.picturesPreviewPagePicturesListPictureEvents}</h4>

                                <Button small
                                        icon={{name: this.getIconName(), size: IconSize.X2}}
                                        type={ButtonType.Blue}
                                        className={this.css(styles.filter, newStyles.filter)}
                                        onClick={async () => await this.openFiltersAsync()}
                                />

                            </div>
                        </div>
                    )
                    :
                    (

                            <div className={"mt-4 mb-3"}>
                                <h5>{Localizer.picturesListObservations.toUpperCase()}</h5>
                            </div>

                    )
                }


                {
                    (!this.isLoading) && ((this.state.data == null) || (this.state.data.items.length == 0)) &&
                    (
                        <div className={this.css(styles.pictureListItem, styles.noItems, newStyles.deviceListItem)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        <React.Fragment>

                            {this.renderItems()}

                            <ArsenalPagination pageNumber={this.state.request.pageNumber}
                                               pageCount={this.state.data.pageCount}
                                               onChange={async (sender, pageNumber) => await this.changePageNumberAsync(pageNumber)}
                            />

                        </React.Fragment>
                    )
                }

                <PicturesFiltersModal ref={this._picturesFiltersModalRef}
                                      title={Localizer.picturesPreviewPagePicturesFiltersModalTitle}
                                      request={this.state.request}
                                      filtersData={this.props.filtersData}
                                      onSubmit={async (sender, request) => await this.submitFiltersAsync(request)}
                />

            </div>
        );
    }
}