import React from "react";
import {PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {
    ButtonType,
    Checkbox,
    Dropdown,
    Icon,
    IconSize,
    Inline, Modal,
    PageContainer,
    PageHeader,
    PageRow,
    ToolbarButton
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import ReportDefinition from "../Models/ReportDefinition";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import BaseReportDefinition from "@/pages/Models/BaseReportDefinition";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import {ReportDefinitionType} from "@/models/Enums";
import EnumProvider from "@/providers/EnumProvider";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ReportDefinitionsPage.module.scss";
import CopyReportDefinitionModal, {
    ICopyReportDefinitionModalData
} from "@/components/CopyReportDefinitionModal/CopyReportDefinitionModal";

interface IReportDefinitionsPageProps  {
}

interface IReportDefinitionsPageState {
    definitions: BaseReportDefinition[];
    showDeleted: boolean;
    type: ReportDefinitionType;
}

export default class ReportDefinitionsPage extends AuthorizedPage<IReportDefinitionsPageProps, IReportDefinitionsPageState> {

    state: IReportDefinitionsPageState = {
        definitions: [],
        showDeleted: false,
        type: ReportDefinitionType.ReturnInspection
    };

    private readonly _modalRef: React.RefObject<Modal<ICopyReportDefinitionModalData>> = React.createRef();

    private get showDeleted(): boolean {
        return this.state.showDeleted;
    }

    private get type(): ReportDefinitionType {
        return this.state.type;
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
    }

    private async setTypeAsync(type: ReportDefinitionType): Promise<void> {
        await this.setState({type});
    }

    private async loadDataAsync(): Promise<void> {

        const definitions: ReportDefinition[] = await RentaToolsController.getReportDefinitionsAsync();

        await this.setState({definitions});
    }

    private async reviewReportDefinitionAsync(definition: BaseReportDefinition): Promise<void> {

        RentaToolsController.reportDefinition = definition;

        const route: PageRoute = (definition.type == ReportDefinitionType.ReturnInspection)
            ? PageDefinitions.reportDefinitionRoute
            : PageDefinitions.serviceReportDefinitionRoute;

        await PageRouteProvider.redirectAsync(route);
    }

    private async addReportDefinitionAsync(): Promise<void> {

        if (this.type == ReportDefinitionType.ReturnInspection) {

            const emptyReportDefinition = new ReportDefinition();
            const emptyDefinitionItem = new ReportDefinitionItem();

            emptyReportDefinition.items.push(emptyDefinitionItem);

            RentaToolsController.reportDefinition = emptyReportDefinition;

            await PageRouteProvider.redirectAsync(PageDefinitions.reportDefinitionRoute);

            return;
        }

        RentaToolsController.reportDefinition = new ServiceReportDefinition();

        await PageRouteProvider.redirectAsync(PageDefinitions.serviceReportDefinitionRoute);
    }

    private get definitions(): BaseReportDefinition[] {
        return this.state.definitions.filter(item => ((!item.isDeleted) || (this.showDeleted)) && (item.type == this.type));
    }

    public getManual(): string {
        return Localizer.reportDefinitionsPageManual;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.loadDataAsync();
    }

    private getDefinitionTitle(definition: BaseReportDefinition): string {
        return (definition.type == ReportDefinitionType.ReturnInspection)
            ? Localizer.get(Localizer.reportDefinitionsPageDefinitionTitle, definition.name, (definition as ReportDefinition).items.length)
            : `${definition.name}`
    }

    public renderDefinition(item: BaseReportDefinition, index: number): React.ReactNode {
        const isDeletedStyle: any = item.isDeleted && "bg-deleted";
        const genericStyle: any = item.generic && styles.generic;
        return (
            <tr key={index}>
                <td>
                    <span id={`reportDefinition_${index}`}
                          data-cy={item.id}
                          className={this.css(isDeletedStyle, genericStyle, "cursor-pointer")}
                          onClick={async () => await this.reviewReportDefinitionAsync(item)}>
                        <span className={styles.spanContainer}>
                            {item.name}
                            <br/>
                            
                            <small>{this.getDefinitionTitle(item)}</small>
                        </span>
                            
                        <div className={styles.copyButton} onClick={e => {
                            e.stopPropagation();
                            this._modalRef.current?.openAsync({reportDefinitionId: item.id, definitionName: item.name});
                        }}>
                            <Icon name="far fa-copy"/>
                        </div>
                    </span>
                </td>
            </tr>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.reportDefinitions)}>

                <CopyReportDefinitionModal modalRef={this._modalRef} loadData={() => this.loadDataAsync()}/>

                <PageHeader title={Localizer.reportDefinitionsPageTitle} subtitle={Localizer.reportDefinitionsPageSubtitle}/>

                <PageRow>

                    <div className="col">

                        <Inline>

                            <Checkbox inline
                                      id={"checkBoxShowDeleted"}
                                      label={Localizer.userManagementPageCheckboxShowDeleted}
                                      value={this.showDeleted}
                                      onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                            />

                            <Dropdown id={"reportDefinitionTypeDD"}
                                      inline required noValidate
                                      label={Localizer.reportDefinitionsPageDropdownLabelType}
                                      minWidth={200}
                                      items={EnumProvider.getReportDefinitionTypeItems()}
                                      selectedItem={EnumProvider.getReportDefinitionTypeItem(this.state.type)}
                                      onChange={async (sender, item) => await this.setTypeAsync(parseInt(item!.value))}
                            />

                            <ToolbarButton id={"addReportDefinition"}
                                           label={Localizer.reportDefinitionsPageAdd}
                                           icon={{name: "plus", size: IconSize.Large}}
                                           type={ButtonType.Orange}
                                           onClick={async () => await this.addReportDefinitionAsync()}
                            />

                        </Inline>

                        <table id={"reportDefinitionsList"}
                               className={styles.table}>
                            <tbody>
                            {
                                this.definitions.map((item, index) => this.renderDefinition(item, index))
                            }
                            </tbody>
                        </table>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}