import React from "react";
import {BaseComponent, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import DeviceInfo from "@/models/server/DeviceInfo";
import {GoogleMap, Icon, IconSize, IconStyle, IGoogleMapMarker, Modal, PageHeader} from "@renta-apps/athenaeum-react-components";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import Device from "@/pages/Models/Device";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import ImageProvider from "@/providers/ImageProvider";
import DeviceAttribute from "@/pages/Models/DeviceAttribute";
import PageDefinitions from "@/providers/PageDefinitions";
import FailedItemsModal from "@/components/DeviceInfoPanel/FailedItemsModal/FaileditemsModal";
import ToolsUtility from "@/helpers/ToolsUtility";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import UnleashHelper from "@/helpers/UnleashHelper";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import styles from "./DeviceInfoPanel.module.scss"
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

export interface IDeviceInfoPanelProps {
    device: DeviceInfo;

    renderInfo(): React.ReactNode;

    deviceLocation?: google.maps.LatLngLiteral | null,

    lastReportId?: string | null,
}

interface IDeviceInfoPanelState {
    info: boolean;
    map: boolean;
}

export default class DeviceInfoPanel extends BaseComponent<IDeviceInfoPanelProps, IDeviceInfoPanelState> {

    state: IDeviceInfoPanelState = {
        info: true,
        map: false,
    };

    private readonly _extraInfoModalRef: React.RefObject<Modal> = React.createRef();

    private get device(): Device | null {
        return RentaToolsController.device;
    }

    private get attributes(): DeviceAttribute[] {
        const attributes = RentaToolsController.device ? RentaToolsController.device.attributes : [];

        attributes.forEach(attribute => {
            if ((attribute.name.includes("Date") || RentaToolsConstants.dateAttributes.includes(attribute.name))) {
                attribute.displayValue = ToolsUtility.toDateString(attribute.value.trim());
            }

            if (attribute.value === "true") {
                attribute.displayValue = Localizer.yes;
            } else if (attribute.value === "false") {
                attribute.displayValue = Localizer.no;
            }
        });

        return attributes;
    }

    private localizeAttributeValue(value: string): string {
        return Localizer.get(value);
    }

    private async openExtraInfoAsync(): Promise<void> {
        if (this._extraInfoModalRef.current) {
            await this._extraInfoModalRef.current.openAsync();
        }
    }

    private async switchTabsAsync(): Promise<void> {
        await this.setState({info: !this.state.info});
    }

    private async switchMapAsync(): Promise<void> {
        await this.setState({map: !this.state.map});
    }

    private async openErrorCodesAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.errorCodeRouteRoute);
    }

    private async openReportAsync(reportId: string): Promise<void> {
        RentaToolsController.reportPreview = await ReturnInspectionController.getReportAsync(reportId);
        await PageRouteProvider.redirectAsync(PageDefinitions.reportPreviewRoute);
    }

    private renderTitle(showExclamationMark: boolean = false): React.ReactNode {
        const deviceProductGroupName = this.device?.productGroupName;
        const deviceType = this.device?.type;
        const deviceExternalId = this.device?.externalId;
        const deviceGroupId = this.device?.productGroupId;

        return (
            <React.Fragment>

                <div>
                    <div id={"multiTitleDiv"}
                         className={styles.multiTitle}
                         onClick={async () => await this.openExtraInfoAsync()}>

                        <span id={"deviceProductGroup"} data-cy="product_group" className={styles.deviceProductGroup}>
                            {
                                (showExclamationMark) &&
                                (
                                    <Icon name="fal exclamation-triangle"
                                          className={this.css(styles.exclamationMark)}
                                          size={IconSize.Large}
                                          style={IconStyle.Regular}
                                    />
                                )
                            }
                            {deviceProductGroupName} {deviceGroupId}
                        </span>
                        <span id={"deviceType"} data-cy="deviceType" className={styles.deviceType}>{deviceType}</span>
                        <span id={"device_externalId"}>{deviceExternalId}</span>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    private get deviceMarker(): IGoogleMapMarker[] {
        return [
            {
                position: {
                    lat: this.props.deviceLocation?.lat ?? 0,
                    lng: this.props.deviceLocation?.lng ?? 0
                }
            }
        ];
    }

    private get startLatitude(): number {
        return RentaToolsConstants.defaultLatitude;
    }

    private get startLongitude(): number {
        return RentaToolsConstants.defaultLongitude;
    }

    private get deviceOrDefaultLatitude(): number {
        return this.props.deviceLocation?.lat ?? this.startLatitude;
    }

    private get deviceOrDefaultLongitude(): number {
        return this.props.deviceLocation?.lng ?? this.startLongitude;
    }

    private async previewReportAsync(reportId: string | null): Promise<void> {

        if (!reportId) {
            return;
        }

        RentaToolsController.reportPreview = await ReturnInspectionController.getReportAsync(reportId);

        const route = PageDefinitions.reportPreviewRoute;

        await PageRouteProvider.redirectAsync(route);
    }

    private renderContent(): React.ReactNode {

        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagLocationEnabled) && this.state.map) {
            return (
                <GoogleMap height={500}
                           initialCenter={{lat: this.deviceOrDefaultLatitude, lng: this.deviceOrDefaultLongitude}}
                           initialZoom={5}
                           markers={this.deviceMarker}
                />
            )
        }

        return (
            <div className={styles.container}>
                {
                    (this.state.info)
                        ?
                        (
                            <div id={"imageDiv"}>

                                {
                                    (this.device?.picture) &&
                                    (
                                        <div className={styles.picture}
                                             onClick={async () => await this.previewReportAsync(this.props.lastReportId ?? null)}
                                             style={ImageProvider.getImageStyle(this.device.picture)}
                                        />
                                    )
                                }

                                {
                                    (!this.device?.picture) &&
                                    (
                                        <Icon className={styles.image} name="far forklift"
                                              onClick={async () => await this.previewReportAsync(this.props.lastReportId ?? null)}
                                              size={IconSize.X10}
                                        />
                                    )
                                }

                            </div>
                        )
                        :
                        (
                            <div>

                                <table id={"infoTable"}
                                       className={this.css(styles.table, "table table-striped")}>
                                    <tbody>

                                    {
                                        this.attributes.map((attribute, index) => (
                                            <tr id={index.toString()} key={index.toString()}>
                                                <td>
                                                    {Localizer.get(attribute.name)}
                                                </td>
                                                <td>
                                                    {this.localizeAttributeValue(attribute.displayValue ? attribute.displayValue : attribute.value)}
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    {(UnleashHelper.isEnabled(RentaToolsConstants.featureNextServiceDateEnabled) && this.device?.nextServiceDate) && (
                                        <tr id="nextServiceDate" key="nextServiceDate">
                                            <td>
                                                {Localizer.devicePageNextServiceDate}
                                            </td>
                                            <td>
                                                {ToolsUtility.toDateString(this.device?.nextServiceDate)}
                                            </td>
                                        </tr>
                                    )}

                                    {(UnleashHelper.isEnabled(RentaToolsConstants.featureNextServiceDateEnabled) && this.device?.endLifeTimeDate) && (
                                        <tr id="totalLifeTime" key="totalLifeTime">
                                            <td>
                                                {Localizer.devicePageEndLifeTimeDate}
                                            </td>
                                            <td>
                                                {ToolsUtility.toDateString(this.device?.endLifeTimeDate)}
                                            </td>
                                        </tr>
                                    )}

                                    </tbody>

                                </table>

                            </div>
                        )
                }
            </div>
        );
    }

    render(): React.ReactNode {
        const hasActiveFaults: boolean = this.device?.activeFaults != null && this.device?.activeFaults.length > 0;
        const failedDeviceStyle: any = (hasActiveFaults) && styles.failedDevice;
        const extraInfoDeviceStyle: any = styles.extraInfoDevice;

        return (
            <div data-cy={"device_info_panel"} className={styles.deviceInfoPanel}>
                <PageHeader title={() => this.renderTitle(hasActiveFaults)}
                            className={this.css(rentaToolsStyles.leftPageHeader, failedDeviceStyle, extraInfoDeviceStyle)}>

                    <div className={styles.deviceActions}>

                        {(!this.state.map) &&
                            (
                                <ArsenalButton block secret
                                               id={"infobutton"}
                                               label={this.state.info
                                                   ? Localizer.devicePageInfo
                                                   : Localizer.devicePagePicture}
                                               className={this.css(rentaToolsStyles.arsenalButton, styles.infoButton)}
                                               onClick={async () => await this.switchTabsAsync()}
                                />
                            )
                        }

                        <FeatureSwitch flagName={RentaToolsConstants.featureFlagLocationEnabled}>
                            {
                                (this.props.deviceLocation) &&
                                (
                                    <ArsenalButton block secret
                                                   id={"locationButton"}
                                                   label={this.state.map ? Localizer.deviceInfoPanelHideMap : Localizer.deviceInfoPanelShowMap}
                                                   className={this.css(rentaToolsStyles.arsenalButton, styles.locationButton)}
                                                   onClick={async () => await this.switchMapAsync()}
                                    />
                                )
                            }
                        </FeatureSwitch>

                        <FeatureSwitch flagName={RentaToolsConstants.featureErrorCodes}>
                            {this.device?.supportErrorCodes && (
                                <ArsenalButton block secret
                                               id={"errorCodes"}
                                               label={Localizer.errorCodesErrorCodes}
                                               className={this.css(rentaToolsStyles.arsenalButton, styles.locationButton)}
                                               onClick={async () => await this.openErrorCodesAsync()}
                                />
                            )}
                        </FeatureSwitch>
                    </div>

                    <FailedItemsModal
                        modalRef={this._extraInfoModalRef}
                        openReportAsync={this.openReportAsync}
                        subTitle={`${this.device?.externalId} ${this.device?.type}`}
                        deviceExternalId={this.device?.externalId!}
                    />

                </PageHeader>
                <ArsenalPageRow>
                    <div className={this.css("col", rentaToolsStyles.noPadding)}>

                        {
                            this.renderContent()
                        }

                        {
                            this.props.renderInfo()
                        }
                    </div>
                </ArsenalPageRow>
            </div>
        );
    }
}