import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {IPageRowProps, PageRow} from "@renta-apps/athenaeum-react-components";

import styles from "./ArsenalPageRow.module.scss";

interface IArsenalPageRowProps extends IPageRowProps {
    className?: string;
}

export default class ArsenalPageRow extends BaseComponent<IArsenalPageRowProps> {
    render(): React.ReactNode {
        return (
            <PageRow className={this.css(this.props.className, styles.arsenalPageRow)}>
                {this.props.children}
            </PageRow>
        );
    }
};