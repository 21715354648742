import React from "react";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, Inline, List, Modal, SelectListItem, StatusListItem, TextInput} from "@renta-apps/athenaeum-react-components";
import ListInvoicesRequest from "@/models/server/requests/ListInvoicesRequest";
import ReturnInspectionInvoiceFiltersData from "@/models/server/ReturnInspectionInvoiceFiltersData";
import {Dictionary} from "typescript-collections";
import {InvoiceStatusFilter} from "@/models/Enums";
import Depo from "@/models/server/Depo";
import User from "@/models/server/User";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./InvoicesListFilters.module.scss";

interface IInvoicesListFiltersProps {
    request: ListInvoicesRequest;
    filtersData: ReturnInspectionInvoiceFiltersData;
    title: string;
    onSubmit(sender: IBaseComponent, request: ListInvoicesRequest): Promise<void>;
}

interface IInvoicesListFiltersState {
    request: ListInvoicesRequest;
    /**
     * If there are any depos selected, show only users from those depos in inspector dropdown
     */
    deposForUsersList: Depo[] | null;
    /**
     * If there are any inspectors selected, show only their depos in depo dropdown
     */
    usersForDepoList: User[] | null;
}

export default class InvoicesListFilters extends BaseComponent<IInvoicesListFiltersProps, IInvoicesListFiltersState> {
    state: IInvoicesListFiltersState = {
        request: this.props.request,
        deposForUsersList: null,
        usersForDepoList: null,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _statusRef: React.RefObject<Dropdown<InvoiceStatusFilter>> = React.createRef();
    private readonly _depotsIdsRef: React.RefObject<Dropdown<Depo>> = React.createRef();
    private readonly _contractsIdsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _inspectorsIdsRef: React.RefObject<Dropdown<User>> = React.createRef();
    private readonly _customersIdsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _customersRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _constructionSitesIdsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _constructionSitesRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _fromRef: React.RefObject<DateInput> = React.createRef();
    private readonly _toRef: React.RefObject<DateInput> = React.createRef();

    private async setDepotsForUsersListAsync(selectedDepos: Depo[]): Promise<void> {
        await this.setState({deposForUsersList: (selectedDepos.length > 0) ? selectedDepos : null})
    }

    private async setUsersForDepoList(selectedUsers: User[]): Promise<void> {
        await this.setState({usersForDepoList: (selectedUsers.length > 0) ? selectedUsers : null});
    }

    private async clearAsync(): Promise<void> {
        this.state.request.clear();

        await this.setState({request: this.state.request, deposForUsersList: null, usersForDepoList: null});

        this._depotsIdsRef.current!.reRender();
        this._contractsIdsRef.current!.reRender();
        this._customersIdsRef.current!.reRender();
        this._customersRef.current!.reRender();
        this._constructionSitesIdsRef.current!.reRender();
        this._constructionSitesRef.current!.reRender();
        this._inspectorsIdsRef.current!.reRender();
    }

    private async submitAsync(data: Dictionary<string, any>): Promise<void> {
        this.copyTo(data, this.state.request);

        await this._modalRef.current!.closeAsync();

        await this.props.onSubmit(this, this.state.request);
    }

    private get contractsIds(): SelectListItem[] {
        return this
            .props
            .filtersData
            .deviceContracts
            .filter(item => !!item.contractExternalId)
            .distinct(item => item.contractExternalId)
            .map(item => new SelectListItem(item.contractExternalId, item.contractExternalId));
    }

    private get customersIds(): SelectListItem[] {
        return this
            .props
            .filtersData
            .deviceContracts
            .filter(item => !!item.customerExternalId)
            .distinct(item => item.customerExternalId)
            .map(item => new SelectListItem(item.customerExternalId, item.customerExternalId));
    }

    private get constructionSitesIds(): SelectListItem[] {
        return this
            .props
            .filtersData
            .deviceContracts
            .filter(item => !!item.constructionSiteExternalId)
            .distinct(item => item.constructionSiteExternalId)
            .map(item => new SelectListItem(item.constructionSiteExternalId, item.constructionSiteExternalId));
    }

    private get customers(): SelectListItem[] {
        return this
            .props
            .filtersData
            .deviceContracts
            .filter(item => !!item.customerName)
            .distinct(item => item.customerName)
            .map(item => new SelectListItem(item.customerName, item.customerName));
    }

    private get constructionSites(): SelectListItem[] {
        return this
            .props
            .filtersData
            .deviceContracts
            .filter(item => !!item.constructionSiteName)
            .distinct(item => item.constructionSiteName)
            .map(item => new SelectListItem(item.constructionSiteName, item.constructionSiteName));
    }

    private get inspectors(): User[] {
        let users: User[] = this
            .props
            .filtersData
            .users
            .distinct(item => item.id);

        if (this.state.deposForUsersList) {
            users = users.filter(user => this.state.deposForUsersList?.some(depo => depo.id == user.depoId));
        }
        //Keep already selected users in the list
        if (this.state.usersForDepoList) {
            users = users.concat(this.state.usersForDepoList)
        }

        return users.distinct(user => user.id);
    }

    private get depots(): Depo[] {
        let depos = this
            .props
            .filtersData
            .depots
            .distinct(item => item.id);

        if (this.state.usersForDepoList) {
            depos = depos.filter(depo => this.state.usersForDepoList?.some(user => user.depoId == depo.id));
        }
        //Keep already selected depos in the list
        if (this.state.deposForUsersList) {
            depos = depos.concat(this.state.deposForUsersList);
        }

        return depos.distinct(depo => depo.id);
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    public render(): React.ReactNode {

        return (
            <Modal id={`invoices_list_filters_modal_${this.id}`} title={this.props.title} ref={this._modalRef} className={styles.returnInspectionInvoicesListFilters}>

                <Form className={styles.form} onSubmit={async (sender, data) => await this.submitAsync(data)}>

                    <Inline className={styles.inline}>
                        <TextInput id={`invoices_filter_deviceExternalId_${this.id}`}
                                   name="deviceExternalId"
                                   label={Localizer.devicesFiltersModalLabelDeviceId}
                                   value={this.state.request.deviceExternalId}
                        />

                        <Dropdown autoCollapse noSubtext required
                                  id={`invoices_filter_status_${this.id}`}
                                  name={`status`}
                                  ref={this._statusRef}
                                  label={Localizer.devicesFiltersModalLabelInvoiceStatus}
                                  transform={(value) => EnumProvider.getInvoiceStatusFilterItem(value)}
                                  orderBy={DropdownOrderBy.None}
                                  items={[InvoiceStatusFilter.NotProcessed, InvoiceStatusFilter.Approved, InvoiceStatusFilter.Declined]}
                                  selectedItem={this.state.request.status}
                        />
                    </Inline>

                    <Inline className={styles.inline}>
                        <Dropdown multiple clearButton
                                  name={`depotsIds`}
                                  id={`invoices_filter_depot_${this.id}`}
                                  ref={this._depotsIdsRef}
                                  label={Localizer.devicesFiltersModalLabelDepots}
                                  nothingSelectedText={"-"}
                                  items={this.depots}
                                  selectedItems={this.state.request.depotsIds.length > 0 ? this.state.request.depotsIds : undefined}
                                  onChange={async (_) => {
                                      await this.setDepotsForUsersListAsync(_.selectedItems)
                                  }}
                        />

                        <Dropdown noWrap noSubtext clearButton
                                  name={`contractExternalId`}
                                  id={`invoices_filter_contractId_${this.id}`}
                                  ref={this._contractsIdsRef}
                                  label={Localizer.devicesFiltersModalLabelContractsIds}
                                  nothingSelectedText={"-"}
                                  items={this.contractsIds}
                                  selectedItem={this.state.request.contractExternalId || undefined}
                        />
                    </Inline>

                    <Inline className={styles.inline}>
                        <Dropdown noWrap noSubtext multiple clearButton
                                  name={`inspectorIds`}
                                  id={`invoices_filter_inspectorId_${this.id}`}
                                  ref={this._inspectorsIdsRef}
                                  label={Localizer.invoiceDetailsPageInspectedBy.replace(":", "")}
                                  nothingSelectedText={"-"}
                                  items={this.inspectors}
                                  selectedItems={this.state.request.inspectorIds.length > 0 ? this.state.request.inspectorIds : undefined}
                                  onChange={async (_) => {
                                      await this.setUsersForDepoList(_.selectedItems)
                                  }}
                        />
                    </Inline>

                    <Inline className={styles.inline}>
                        <Dropdown noWrap noSubtext clearButton
                                  name={`customerExternalId`}
                                  id={`invoices_filter_customerId_${this.id}`}
                                  ref={this._customersIdsRef}
                                  label={Localizer.devicesFiltersModalLabelCustomersIds}
                                  nothingSelectedText={"-"}
                                  items={this.customersIds}
                                  selectedItem={this.state.request.customerExternalId || undefined}
                        />

                        <Dropdown noWrap noSubtext clearButton
                                  name={`customerName`}
                                  id={`invoices_filter_customerName_${this.id}`}
                                  ref={this._customersRef}
                                  label={Localizer.devicesFiltersModalLabelCustomers}
                                  nothingSelectedText={"-"}
                                  items={this.customers}
                                  selectedItem={this.state.request.customerName || undefined}
                        />
                    </Inline>

                    <Inline className={styles.inline}>
                        <Dropdown noWrap noSubtext clearButton
                                  name={`constructionSiteExternalId`}
                                  id={`invoices_filter_constructionSiteExternalId_${this.id}`}
                                  ref={this._constructionSitesIdsRef}
                                  label={Localizer.devicesFiltersModalLabelConstructionSitesIds}
                                  nothingSelectedText={"-"}
                                  items={this.constructionSitesIds}
                                  selectedItem={this.state.request.constructionSiteExternalId || undefined}
                        />

                        <Dropdown noWrap noSubtext clearButton
                                  name={`constructionSiteName`}
                                  id={`invoices_filter_constructionSiteName_${this.id}`}
                                  ref={this._constructionSitesRef}
                                  label={Localizer.devicesFiltersModalLabelConstructionSites}
                                  nothingSelectedText={"-"}
                                  items={this.constructionSites}
                                  selectedItem={this.state.request.constructionSiteName || undefined}
                        />
                    </Inline>

                    <Inline className={styles.inline}>
                        <DateInput popup clearButton
                                   id={`invoices_filter_from_${this.id}`}
                                   name="from"
                                   ref={this._fromRef}
                                   label={Localizer.devicesFiltersModalLabelInspectedFrom}
                                   value={this.props.request.from || undefined}
                        />

                        <DateInput popup clearButton
                                   id={`invoices_filter_to_${this.id}`}
                                   name="to"
                                   ref={this._toRef}
                                   label={Localizer.devicesFiltersModalLabelInspectedTo}
                                   value={this.props.request.to || undefined}
                        />
                    </Inline>

                    <ButtonContainer className={this.css(styles.buttons)}>
                        <Button submit
                                type={ButtonType.Orange}
                                label={Localizer.devicesFiltersModalButtonApply}
                        />

                        <Button type={ButtonType.Blue}
                                label={Localizer.devicesFiltersModalButtonClear}
                                onClick={async () => await this.clearAsync()}
                        />
                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
}