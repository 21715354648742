import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import React from "react";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

import styles from "./ToolsPageHeader.module.scss";

export interface IToolsPageHeaderProps{
    title: string;
    stickyHeader? : boolean;
}

export default class ToolsPageHeader extends BaseComponent<IToolsPageHeaderProps> {
    public render(): React.ReactNode {
        return(
        <div className={this.css(styles.headerStyles, this.props.stickyHeader && rentaToolsStyles.stickyHeader)}>
            {this.props.title}
        </div>)
    }
    
}