import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, IButtonProps, IIconProps} from "@renta-apps/athenaeum-react-components";
import Dictionary from "typescript-collections/dist/lib/Dictionary";

import styles from "./ArsenalButton.module.scss";

interface IArsenalButtonProps extends IButtonProps {
    big?: boolean;
    secret?: boolean;
    noPointerEvents?: boolean;
    ellipsis?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    whiteBg? : boolean;
    fullWidth?: boolean;
    reverse?: boolean;
    action? : boolean;
}

export default class ArsenalButton extends BaseComponent<IArsenalButtonProps> {
    
    private _buttonStyles : Dictionary<ButtonType, string> = new Dictionary<ButtonType, string>();
    private _clicked : boolean = false;
    private _icon : IIconProps | undefined = undefined;
    initializeAsync(): Promise<void> {
        this._buttonStyles.setValue(ButtonType.Success, styles.btnSuccess);
        this._buttonStyles.setValue(ButtonType.Danger, styles.btnDanger);
        this._buttonStyles.setValue(ButtonType.Blue, styles.btnBlue);
        
        if(this.props.icon){
            this._icon = this.props.icon;
        }
        return super.initializeAsync();
    }
    
    private getButtonStyles() : string{
        const buttonType = this.props.type;
        if(buttonType) {
            return this._buttonStyles.getValue(buttonType) ?? "";
        }
        
        return ""
    }
    
    private async onClick(sender: Button, data: string | null): Promise<void> {
        if(this.props.onClick) {
           await this.props.onClick(sender, data);

            this._clicked = !this._clicked;
        }
    }

    render(): React.ReactNode {
        const bigStyle: string = this.props.big ? styles.arsenalButton : "";
        const secretStyle: string = this.props.secret ? styles.arsenalSecretButton : "";
        const noPointerEventsStyle: string = this.props.noPointerEvents ? styles.arsenalNoPointerEventsButton : "";
        const ellipsisLabelStyle: string = this.props.ellipsis ? styles.arsenalEllipsisLabelButton : "";
        const whiteBgStyle  = this.props.whiteBg ? { background : "white" } : undefined;
        const fullWidthStyles = this.props.fullWidth ? styles.fullWidth : "";
        const reverse = this.props.reverse ? styles.flexReverse : "";
        const actionButton = this.props.action ? styles.actionButton : "";
        
        return (
            <Button {...this.props} 
                style={whiteBgStyle}
                    className={this.css(bigStyle, secretStyle, noPointerEventsStyle, ellipsisLabelStyle, this.props.className, fullWidthStyles, reverse, actionButton, this.getButtonStyles() )}
                    icon={this._icon}
                    onClick={(sender, data) => this.onClick(sender, data)}
            >
                {this.props.children}
            </Button>
        );
    }
};