import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Modal} from "@renta-apps/athenaeum-react-components";
import ToolsUtility from "@/helpers/ToolsUtility";
import React from "react";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import DeviceFailedItemViewModel from "@/models/server/DeviceFailedItemViewModel";
import {FailedItemType} from "@/models/Enums";

interface IFailedItemsModalProps{
    subTitle: string;
    modalRef: React.RefObject<Modal>;
    deviceExternalId: string;
    openReportAsync : (reportId: string) => Promise<void>;
}

interface IFailedItemsModalState{
    failedItems : DeviceFailedItemViewModel[];
}
export default class FailedItemsModal extends BaseComponent<IFailedItemsModalProps,IFailedItemsModalState > {
    state: IFailedItemsModalState = {
        failedItems: [],
    };

    private get hasExtraInfo(): boolean {
        return this.state.failedItems.length > 0;
    }

    private get failedItems(): DeviceFailedItemViewModel[] {
        return this.state.failedItems;
    }

    private async openReportAsync(reportId: string | null): Promise<void> {
        if (!reportId) {
            return;
        }

        await this.props.openReportAsync(reportId);
    }


    async onOpen(): Promise<void> {
        const result = await RentaToolsController.getFailedReportItemsAsync(this.props.deviceExternalId);
        this.setState({failedItems: result});
    }

    getTitle(item: FailedItemType): React.ReactNode {
        switch (item) {
            case FailedItemType.DeviceFault:
                return <p>{Localizer.genericFaultGeneratedFromInspection}</p>;
            case FailedItemType.ManualFault:
                return <p>{Localizer.genericFaultGeneratedManually}</p>;
            case FailedItemType.ReturnInspectionStep:
                return <p>{Localizer.genericFailedReturnInspectionStep}</p>;
            default:
                return <p>{Localizer.genericUnknown}</p>;
        }
    }

    getFaultDate(item: DeviceFailedItemViewModel): string {
        return `${Localizer.genericFoundAt} ${ToolsUtility.toDateString(item.completedAt)}`;
    }

    getContentForItem(item: DeviceFailedItemViewModel): React.ReactNode {
        switch (item.itemType) {
            case FailedItemType.DeviceFault:
                return (
                    <>
                        {
                            (item.completedAt) &&
                            (
                                <p>{this.getFaultDate(item)}, {item.inspectedByName}, {item.depoCostPool}</p>
                            )
                        }
                        <p>{Localizer.devicePageExtraDeviceInfoTextState} {item.stepNumber}/{item.stepsCount} {item.title}</p>
                        <p>{Localizer.wizardPageExtraInfoFaultLevel.format(item.faultLevel!)}</p>
                        <p>{Localizer.devicePageExtraDeviceInfoTextComment}: {item.comment}</p>
                    </>
                )
            case FailedItemType.ManualFault:
                return (
                    <>
                        {
                            (item.completedAt) &&
                            (
                                <p>{this.getFaultDate(item)}, {item.inspectedByName}, {item.depoCostPool}</p>
                            )
                        }
                        <p>{Localizer.wizardPageExtraInfoFaultLevel.format(item.faultLevel!)}</p>
                        <p>{Localizer.devicePageExtraDeviceInfoTextComment}: {item.comment}</p>
                    </>
                )
            case FailedItemType.ReturnInspectionStep:
                return (
                    <>
                        {
                            (item.completedAt) &&
                            (
                                <p>{this.getFaultDate(item)}, {item.inspectedByName}, {item.depoCostPool}</p>
                            )
                        }
                        <p>{Localizer.devicePageExtraDeviceInfoTextState} {item.stepNumber}/{item.stepsCount} {item.title}</p>
                        <p>{Localizer.devicePageExtraDeviceInfoTextComment}: {item.comment}</p>
                    </>
                )
            default:
                return null;
        }
    }

    render() {
        return (
            <Modal ref={this.props.modalRef}
                   onOpen={async () => await this.onOpen()}
                   className={rentaToolsStyles.extraInfoModal}
                   contentClassName={rentaToolsStyles.contentModal}
                   title={Localizer.devicePageExtraDeviceInfo} subtitle={this.props.subTitle}>
                {
                    (!this.hasExtraInfo) &&
                    (
                        <p>{Localizer.devicePageExtraDeviceInfoTextNoFailedSteps}</p>
                    )
                    ||
                    (
                        <div>
                            {
                                (this.failedItems) && (
                                    this.failedItems.map((item, index) => (
                                        <div id={`failed_item_${index}`} key={index} className={rentaToolsStyles.info} data-cy="failed_item"
                                             onClick={async () => await this.openReportAsync(item.reportId)}>

                                            {
                                                this.getTitle(item.itemType)
                                            }

                                            {
                                                this.getContentForItem(item)
                                            }

                                        </div>
                                    )))
                            }
                        </div>
                    )
                }
            </Modal>
        );
    }
}