import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {
    ITabContainerClassNames,
    Modal,
    PageContainer,
    PageHeader,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Device from "../Models/Device";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import PageDefinitions from "../../providers/PageDefinitions";
import DeviceInfo from "@/models/server/DeviceInfo";
import ListUnServicedDevicesRequest from "../../models/server/requests/ListUnServicedDevicesRequest";
import FindDeviceResponse from "../../models/server/responses/FindDeviceResponse";
import GetServiceDevicesFiltersResponse from "@/models/server/responses/GetServiceDevicesFiltersResponse";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ServicedDevicesPage.module.scss";
import newStyles from "../NewUI.module.scss";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import FiltersData from "@/pages/Models/FiltersData";
import ServicedDevicesList from "@/components/ServicedDevicesList/ServicedDevicesList";

interface IServicedDevicesPageProps  {
}

interface IServicedDevicesPageState {
    unServicedRequest: ListUnServicedDevicesRequest;
    unServicedFiltersData: FiltersData;
}

export default class ServicedDevicesPage extends AuthorizedPage<IServicedDevicesPageProps, IServicedDevicesPageState> {

    state: IServicedDevicesPageState = {
        unServicedRequest: ServicedDevicesPage.initializeListUnServicedDevicesRequest(),
        unServicedFiltersData: new FiltersData(),
    };

    private readonly _pageInfoModalRef: React.RefObject<Modal> = React.createRef();
    private _unServicedDevicesCount: number = 0;
    private static initializeListUnServicedDevicesRequest(): ListUnServicedDevicesRequest {
        const request = UserInteractionDataStorage.restore("ListUnServicedDevicesRequest", new ListUnServicedDevicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;
        request.modified = true;

        return request;
    }

    private get pageInfoModal(): Modal {
        return this._pageInfoModalRef.current!;
    }

    protected get customInfoModal(): boolean {
        return true;
    }

    private async onClosePageInfoModalAsync(): Promise<void> {
        await this.pageInfoModal.closeAsync();
    }

    private async openDeviceAsync(deviceInfo: DeviceInfo): Promise<void> {
        const response: FindDeviceResponse = await RentaToolsController.findDeviceAsync(deviceInfo.externalId);
        const device: Device | null = response.device;

        if (device) {

            RentaToolsController.device = device;
            await PageRouteProvider.redirectAsync(PageDefinitions.deviceRoute);

        } else {

            RentaToolsController.device = null;

            RentaToolsController.failedReportItems = null;

            await this.alertErrorAsync(Localizer.dashboardPageNotFound, true);
        }
    }

    private async fetchUnServicedDevicesAsync(): Promise<DeviceInfo[]> {
        UserInteractionDataStorage.set("ListUnServicedDevicesRequest", this.state.unServicedRequest);
        if(this.state.unServicedRequest.modified){
            this._unServicedDevicesCount = await RentaToolsController.listUnServicedDevicesCountAsync(this.state.unServicedRequest);
        }

        const unServicedDevices: DeviceInfo[] =  await RentaToolsController.listUnServicedDevicesAsync(this.state.unServicedRequest);

        await this.reRenderAsync();

        return unServicedDevices;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: GetServiceDevicesFiltersResponse = await RentaToolsController.getServiceDevicesFiltersAsync();

        await this.setState({unServicedFiltersData: response.unServiced});
    }

    public getManual(): string {
        return Localizer.dashboardPageGetManual;
    }

    public manualPropsCallback(): Promise<void> {
        return this.pageInfoModal.openAsync();
    }
    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.returnedDevicesPage, newStyles.pageContainer, newStyles.returnedDevicesPage)}>

                <ServicedDevicesList sticky
                                     title={`${Localizer.servicedDeviceListTitleUnInspected} ${this._unServicedDevicesCount}`}
                                     noDataText={Localizer.devicesListNoDataTextNoUnServicedDevices}
                                     request={this.state.unServicedRequest}
                                     filtersData={this.state.unServicedFiltersData}
                                     fetchData={async () => await this.fetchUnServicedDevicesAsync()}
                                     onDeviceOpen={async (sender, deviceInfo) => await this.openDeviceAsync(deviceInfo)}
                />

                <Modal id={"serviced-devices-page-info-modal"}
                       ref={this._pageInfoModalRef}
                       className={rentaToolsStyles.infoPageModal}
                       title={Localizer.servicedDevicesInfoHeader}
                       onClose={async () => await this.onClosePageInfoModalAsync()}
                >
                    {this.getPageInfo(Localizer.servicedDevicesInfoContent)}

                </Modal>

            </PageContainer>
        );
    }
}