import React from "react";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {
    CellAction,
    CellModel,
    ColumnActionType,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    PageContainer,
    PageHeader,
    PageRow,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import DeviceInfo from "@/models/server/DeviceInfo";
import ListDevicesRequest from "@/models/server/requests/ListDevicesRequest";
import DeviceDetailsPanel from "@/pages/DevicesManagementPage/DeviceDetailsPanel/DeviceDetailsPanel";
import Toolbar from "@/pages/DevicesManagementPage/Toolbar/Toolbar";
import ToolbarModel from "@/models/server/ToolbarModel";
import SaveDeviceRequest from "@/models/server/requests/SaveDeviceRequest";
import {DeviceBanOfUse, DeviceStatus} from "@/models/Enums";
import AdBlue from "@/models/server/AdBlue";
import FuelType from "@/models/server/FuelType";
import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./DevicesManagementPage.module.scss";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "../../localization/Localizer";
import {ActionType} from "@renta-apps/athenaeum-react-common";
import AnnualInspectionController from "@/pages/AnnualInspectionController";

interface IDevicesManagementPageProps {
}

interface IDevicesManagementPageState {
    filters: ToolbarModel,
    adBlueTypes: AdBlue[],
    fuelTypes: FuelType[]
}

export default class DevicesManagementPage extends AuthorizedPage<IDevicesManagementPageProps, IDevicesManagementPageState> {

    state: IDevicesManagementPageState = {
        filters: new ToolbarModel(),
        adBlueTypes: [],
        fuelTypes: []
    };

    private readonly _devicesGridRef: React.RefObject<Grid<DeviceInfo>> = React.createRef();

    private readonly _devicesGridColumns: ColumnDefinition[] = [
        {
            minWidth: 50,
            noWrap: true,
            className: "grey",
            actions: [
                {
                    title: "Details",
                    type: ColumnActionType.Details,
                    callback: async (cell) => await this.toggleDetailsAsync(cell)
                }
            ]
        },
        {
            header: Localizer.devicesManagementPageDeviceExternalIdLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.externalId),
            minWidth: 50,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceTypeLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.type),
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceDepoCostPoolLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.depoName),
            minWidth: 50,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceStatusLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.status),
            format: "DeviceStatus",
            minWidth: 100,
            noWrap: true,
            sorting: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDevicePreviousStatusLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.previousStatus),
            format: "DeviceStatus",
            minWidth: 225,
            noWrap: true,
            sorting: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceContractIdLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.currentContractExternalId),
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceReturnInspectionTypeLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.returnInspectionReportDefinitionName),
            type: ColumnType.Text,
            minWidth: 225,
            noWrap: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceServiceTypeLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.serviceDefinitionName),
            type: ColumnType.Text,
            minWidth: 225,
            noWrap: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceNameLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.name),
            minWidth: 200,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceUnderServiceLanguageItemName,
            minWidth: 100,
            type: ColumnType.Custom,
            sorting: true,
            noWrap: true,
            accessor: nameof<DeviceInfo>(d => d.underService),
            transform: (cell, value) => value ? "✓" : "",
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceUnderInspectionLanguageItemName,
            minWidth: 100,
            type: ColumnType.Custom,
            noWrap: true,
            sorting: true,
            accessor: nameof<DeviceInfo>(d => d.underInspection),
            transform: (cell, value) => value ? "✓" : "",
            init: (cell) => this.initDeviceCell(cell)
        },
        {
            minWidth: "75px",
            removable: false,
            init: (cell) => this.initDeviceInfoActions(cell),
            actions: [
                {
                    name: "cancel",
                    title: Localizer.devicesManagementRemoveBanOfUse,
                    confirm:  Localizer.devicesManagementRemoveBanOfUseConfirm,
                    icon: "far lock-open",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processDeviceInfoActionAsync(cell, action)
                }
            ]
        }
    ];

    private async processDeviceInfoActionAsync(cell: CellModel<DeviceInfo>, action: CellAction<DeviceInfo>): Promise<void> {

        if (action.action.name === "cancel") {

            await AnnualInspectionController.removeDeviceBanOfUseAsync(cell.model.id)

            await this.devicesGrid.reloadAsync();
        }
    }

    private async initDeviceInfoActions(cell: CellModel<DeviceInfo>): Promise<void> {
        const modified: boolean = cell.row.modified;

        const unBanAction: CellAction<DeviceInfo> = cell.actions[0];

        unBanAction.visible = ((cell.model.deviceBanOfUse == DeviceBanOfUse.Banned || cell.model.deviceBanOfUse == DeviceBanOfUse.NeedBan));
    }

    public async initializeAsync(): Promise<void> {

        let adBlue: AdBlue[] = await RentaToolsController.getAdBluesAsync();
        adBlue = adBlue.filter(type => !type.isDeleted);
        this.setState({adBlueTypes: adBlue});

        let fuelTypes: FuelType[] = await RentaToolsController.getFuelTypesAsync();
        fuelTypes = fuelTypes.filter(type => !type.isDeleted);
        this.setState({fuelTypes: fuelTypes});
    }

    private get devicesGrid(): GridModel<DeviceInfo> {
        return this._devicesGridRef.current!.model;
    }

    private async processDeviceOperationAsync(cell: CellModel<DeviceInfo>, action: CellAction<DeviceInfo>): Promise<void> {
        const model: DeviceInfo = cell.row.cells[0].model;

        if (action.action.name === "save") {
            const request: SaveDeviceRequest = {
                deviceId: model.id,
                deviceStatus: model.status,
                fuelTypeId: model.fuelTypeId,
                adBlueId: model.adBlueId
            }
            if (request.deviceStatus != DeviceStatus.InRent) {
                await cell.grid.postAsync("api/device/saveDevice", request);
                await cell.row.saveAsync();
            } else {
                await this.alertErrorAsync(Localizer.devicesManagementPageStatusInRentError)
            }


        } else if (action.action.name === "cancel") {
            await cell.row.cancelAsync();
        }
    }

    private async initDeviceOperationsAsync(cell: CellModel<DeviceInfo>): Promise<void> {

        const model: DeviceInfo = cell.row.model;
        const modified: boolean = cell.row.modified;

        const saveAction: CellAction<DeviceInfo> = cell.actions[0];
        const cancelAction: CellAction<DeviceInfo> = cell.actions[1];

        saveAction.visible = (modified);
        cancelAction.visible = (modified);

    }

    private initDeviceCell(cell: CellModel<DeviceInfo>): void {
        cell.readonly = true;
    }

    private async getDevicesAsync(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<DeviceInfo>> {
        const request = new ListDevicesRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortDirection = sortDirection;
        request.sortColumnName = sortColumnName!;
        request.searchTerm = this.state.filters.searchTerm;
        request.reportDefinitionId = this.state.filters.reportDefinitionId;
        request.serviceReportDefinitionId = this.state.filters.serviceReportDefinitionId;

        return await this.devicesGrid.postAsync("api/device/listdevices", request);
    }

    private async toggleDetailsAsync(cell: CellModel<DeviceInfo>): Promise<void> {
        const spannedRows: RowModel<DeviceInfo>[] = cell.spannedRows;
        const rowToExpand: RowModel<DeviceInfo> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }

    private initRow(row: RowModel<DeviceInfo>): void {
    }

    private async reloadAsync(): Promise<void> {
        await this.devicesGrid.reloadAsync();
    }

    private renderDetailsContent(row: RowModel<DeviceInfo>) {
        const model: DeviceInfo = row.model;
        return (
            <DeviceDetailsPanel device={model}
                                adBlueTypes={this.state.adBlueTypes}
                                fuelTypes={this.state.fuelTypes}
            />
        );
    }

    private async onFilterAsync(filters: ToolbarModel): Promise<void> {
        await this.setState({filters});

        await this.devicesGrid.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.devices)} scale>
                <PageHeader title={Localizer.devicesManagementPageTitle}/>
                <PageRow>
                    <div className="col">

                        <div className={styles.container}>

                            <ToolbarContainer className={styles.toolbar}>

                                <Toolbar model={this.state.filters}
                                         onChange={async (model) => this.onFilterAsync(model)}
                                />

                            </ToolbarContainer>

                            <Grid responsive
                                  id={"deviceDetails"}
                                  className={styles.devicesGrid}
                                  ref={this._devicesGridRef}
                                  pagination={10}
                                  hovering={GridHoveringType.EditableCell}
                                  odd={GridOddType.None}
                                  minWidth="auto"
                                  columns={this._devicesGridColumns}
                                  renderDetails={(row) => this.renderDetailsContent(row)}
                                  initRow={(row) => this.initRow(row)}
                                  fetchData={async (sender,
                                                    pageNumber,
                                                    pageSize,
                                                    sortColumnName,
                                                    sortDirection) =>
                                      await this.getDevicesAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                            />

                        </div>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }

}