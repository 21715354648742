import React from "react";
import {BaseComponent, ch, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonContainer,
    ButtonType,
    DateInput,
    Dropdown,
    DropdownAlign,
    Form,
    Inline,
    Modal,
    SelectListItem,
    TextInput
} from "@renta-apps/athenaeum-react-components";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import ListServicedDevicesRequest from "@/models/server/requests/ListServicedDevicesRequest";
import ListUnInspectedDevicesRequest from "@/models/server/requests/ListUnInspectedDevicesRequest";
import ListUnServicedDevicesRequest from "@/models/server/requests/ListUnServicedDevicesRequest";
import ListAnnualInspectionDevicesRequest from "@/models/server/requests/ListAnnualInspectionDevicesRequest";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import BaseListDevicesRequest from "@/models/server/requests/BaseListDevicesRequest";
import FiltersData from "@/pages/Models/FiltersData";
import {
    DeviceListOrder,
    PredictionTime,
    ReturnInspectionStatus,
    ServiceAndRepairsStatuses,
    ServiceTypeFilter
} from "@/models/Enums";
import ListInspectedDevicesRequest from "@/models/server/requests/ListInspectedDevicesRequest";
import RentaToolsController from "@/pages/RentaToolsController";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import styles from "./DevicesFiltersModal.module.scss";

interface IDevicesFiltersModalProps {
    request: BaseListDevicesRequest;
    filtersData: FiltersData;
    title: string;
    onSubmit(sender: IBaseComponent, request: BaseListDevicesRequest): Promise<void>;
    deviceStatusSelection?: boolean | null;
    returnInspectionDetailsSelection? : boolean | null;
    serviceFilters? : boolean | null;
    priorityOrder? : boolean | null;
}

interface IDevicesFiltersModalState {
    request: BaseListDevicesRequest;
    upcomingFilterSelected: boolean;
}

export default class DevicesFiltersModal extends BaseComponent<IDevicesFiltersModalProps, IDevicesFiltersModalState> {
    state: IDevicesFiltersModalState = {
        request: this.props.request,
        upcomingFilterSelected: false,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _depoCostPoolsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _productGroupIdsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _deviceTypesRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _fromRef: React.RefObject<DateInput> = React.createRef();
    private readonly _toRef: React.RefObject<DateInput> = React.createRef();
    private readonly _atRef: React.RefObject<DateInput> = React.createRef();
    private readonly _deviceStatusesRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _upcomingFilterRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _showDeletedDdRef:  React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _fuelingAndCleaningRef:  React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _rentalObjectGroupsRef:  React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _returnInspectionStatusRef:  React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _serviceStatusRef:  React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _servicesTypes: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _deviceListOrders: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _itemShowActive: string = "active";
    private readonly _itemShowAll: string = "all";
    private readonly _itemShowDeleted: string = "deleted";

    private readonly _completedToRef: React.RefObject<DateInput> = React.createRef();
    private readonly _completedFromRef: React.RefObject<DateInput> = React.createRef();
    
    private get getSelectedItemsForReturnInspectionDetails() : string[] {
        if(this.state.request instanceof ListUnInspectedDevicesRequest){
            return this.state.request.returnInspectionStatus;
        }
        
        if(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser)){
            return [
                ReturnInspectionStatus.Waiting.toString(),
                ReturnInspectionStatus.InProgress.toString(),
                ReturnInspectionStatus.PartlyCompleted.toString(),
            ]
        }
        
        return [
            ReturnInspectionStatus.Waiting.toString(), 
            ReturnInspectionStatus.InProgress.toString(),
        ]
    }

    private get getSelectedItemsForServiceDetails() : string[] {
        if(this.state.request instanceof ListUnServicedDevicesRequest){
            return this.state.request.serviceStatus;
        }

        return [
            ServiceAndRepairsStatuses.Waiting.toString(),
            ServiceAndRepairsStatuses.InProgress.toString(),
        ]
    }

    private get getSelectedItemsForServiceTypes() : string[] {
        if(this.state.request instanceof ListUnServicedDevicesRequest){
            return this.state.request.servicesTypes;
        }

        return [
            ServiceTypeFilter.Service.toString(),
            ServiceTypeFilter.Repair.toString(),
        ]
    }

    private get getSelectedItemForTimeline() : string {
        if(this.state.request instanceof ListUnServicedDevicesRequest){
            return this.state.request.predictionTime.toString();
        }

        return PredictionTime.OneMonth.toString();
    }

    private get getSelectedItemForOrder() : string {
        if(this.state.request instanceof ListUnInspectedDevicesRequest){
            return this.state.request.orderBy.toString();
        }

        return DeviceListOrder.ByPriority.toString();
    }

    private getDepos(): SelectListItem[] {
        const items: SelectListItem[] = this.props.filtersData.depos.map(item => TransformProvider.toDepoListItem(item, true));

        if (this.state.request.depoCostPools) {
            items.forEach(item => item.selected = this.state.request.depoCostPools.includes(item.value));
        }

        return items;
    }

    protected get isRentalGroupFilterEnabled(): boolean {
        return ch.isSweden|| ch.isDevelopment || RentaToolsController.userContext.IsAutomatedTestEnv;
    }

    private getProductGroups(): SelectListItem[] {
        const items: SelectListItem[] = this.props.filtersData.productGroups.map(item => TransformProvider.toSelectListItem(item));

        if (this.state.request.productGroupIds) {
            items.forEach(item => item.selected = this.state.request.productGroupIds.includes(item.value));
        }

        return items;
    }

    private getDeviceTypes(): SelectListItem[] {
        const items: SelectListItem[] = this.props.filtersData.deviceTypes.map(item => TransformProvider.toSelectListItem(item));

        if (this.state.request.deviceTypes) {
            items.forEach(item => item.selected = this.state.request.deviceTypes.includes(item.value));
        }

        return items;
    }

    private getDeviceStatuses(): SelectListItem[] {
        const items: SelectListItem[] = EnumProvider.getDeviceStatusItems();

        if ((this.hasDeviceStatuses) && (this.state.request as ListInspectedDevicesRequest).deviceStatuses) {
            const values: string[] = (this.state.request as ListInspectedDevicesRequest).deviceStatuses.map(status => status.toString());
            items.forEach(item => item.selected = values.includes(item.value));
        }

        return items;
    }

    private getDeletedFilters(): SelectListItem[]{
        return [
            new SelectListItem(this._itemShowAll, Localizer.devicesFiltersModalLabelShowAll),
            new SelectListItem(this._itemShowDeleted, Localizer.devicesFiltersModalLabelShowOnlyDeleted),
            new SelectListItem(this._itemShowActive, Localizer.devicesFiltersModalLabelShowOnlyActive)
        ];
    }

    private getReturnInspectionFilters(): SelectListItem[]{
        return EnumProvider.getReturnInspectionDetailsTypesItems();
    }

    private getReturnInspectionStatusFilters(): SelectListItem[]{
        if(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser)) {
            return [
                new SelectListItem(ReturnInspectionStatus.InProgress.toString(), Localizer.returnInspectionInProgress),
                new SelectListItem(ReturnInspectionStatus.PartlyCompleted.toString(), Localizer.returnInspectionPartlyCompleted),
                new SelectListItem(ReturnInspectionStatus.Completed.toString(), Localizer.returnInspectionCompleted),
                new SelectListItem(ReturnInspectionStatus.Waiting.toString(), Localizer.returnInspectionWaitingForReturnInspection)
            ];
        }

        return [
            new SelectListItem(ReturnInspectionStatus.InProgress.toString(), Localizer.returnInspectionInProgress),
            new SelectListItem(ReturnInspectionStatus.Completed.toString(), Localizer.returnInspectionCompleted),
            new SelectListItem(ReturnInspectionStatus.Waiting.toString(), Localizer.returnInspectionWaitingForReturnInspection)
        ];
    }
    
    private getServiceStatusFilters() : SelectListItem[] {
        if(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceForecastList)){
            return [
                new SelectListItem(ServiceAndRepairsStatuses.Waiting.toString(), Localizer.servicedPageWaitingForServiceOrRepair),
                new SelectListItem(ServiceAndRepairsStatuses.InProgress.toString(), Localizer.servicedPageInProgress),
                new SelectListItem(ServiceAndRepairsStatuses.Completed.toString(), Localizer.servicedPageCompleted),
                new SelectListItem(ServiceAndRepairsStatuses.UpcomingService.toString(), Localizer.servicedPageUpcoming),
            ]
        }
        
        return [
            new SelectListItem(ServiceAndRepairsStatuses.Waiting.toString(), Localizer.servicedPageWaitingForServiceOrRepair),
            new SelectListItem(ServiceAndRepairsStatuses.InProgress.toString(), Localizer.servicedPageInProgress),
            new SelectListItem(ServiceAndRepairsStatuses.Completed.toString(), Localizer.servicedPageCompleted),
        ]
    }

    private getServiceTypeFilters() : SelectListItem[] {
        return [
            new SelectListItem(ServiceTypeFilter.Service.toString(), Localizer.servicedPageService),
            new SelectListItem(ServiceTypeFilter.Repair.toString(), Localizer.servicedPageRepair),
        ]
    }


    private getServiceForecastGaps() : SelectListItem[] {
        return [
            new SelectListItem(PredictionTime.Week.toString(), Localizer.predictionTimeWeek),
            new SelectListItem(PredictionTime.TwoWeeks.toString(),  Localizer.predictionTimeTwoWeeks),
            new SelectListItem(PredictionTime.OneMonth.toString(),  Localizer.predictionTimeOneMonth),
            new SelectListItem(PredictionTime.TwoMonths.toString(),  Localizer.predictionTimeTwoMonths),
        ]
    }
    private getRentalObjectGroups() : SelectListItem[]{
        const items = this.props.filtersData.rentalObjectGroups.map(item => TransformProvider.toSelectListItem(item));

        if (this.state.request.rentalObjectGroups) {
            items.forEach(item => item.selected = this.state.request.rentalObjectGroups.includes(item.value));
        }
        return items;
    }

    private getInspectionFilters(): SelectListItem[] {
        if (!this.isAnnualInspectionFilter) {
            return [];
        }

        let items: SelectListItem[] = EnumProvider.getAnnualInspectionStatusFilterTypeItems();
        let request = this.state.request as ListAnnualInspectionDevicesRequest;
        if  (request.annualInspectionStatusFilter) {
            items.forEach(item => {item.selected = (item.value === request.annualInspectionStatusFilter?.toString())});
        }

        return items;
    }

    private getDateFromFilterTitle(): string {
        const request: BaseListDevicesRequest = this.props.request;

        return (request instanceof ListInspectedDevicesRequest)
            ? Localizer.devicesFiltersModalLabelInspectedFrom
            : (request instanceof ListUnInspectedDevicesRequest)
                ? Localizer.devicesFiltersModalLabelReturnedFrom
                : Localizer.devicesFiltersModalLabelFrom;
    }

    private getDateToFilterTitle(): string {
        const request: BaseListDevicesRequest = this.props.request;

        return (request instanceof ListInspectedDevicesRequest)
            ? Localizer.devicesFiltersModalLabelInspectedTo
            : (request instanceof ListUnInspectedDevicesRequest)
                ? Localizer.devicesFiltersModalLabelReturnedTo
                : Localizer.devicesFiltersModalLabelTo;
    }

    private getDateAtFilterTitle(): string {
        const request: BaseListDevicesRequest = this.props.request;

        return (request instanceof ListInspectedDevicesRequest)
            ? Localizer.devicesFiltersModalLabelInspectedAt
            : (request instanceof ListUnInspectedDevicesRequest)
                ? Localizer.devicesFiltersModalLabelReturnedAt
                : Localizer.devicesFiltersModalLabelDate;
    }

    private get hasDeviceStatuses(): boolean {
        return (RentaToolsController.supportUnServicedDevicesRequest)
            ? (this.props.request instanceof ListInspectedDevicesRequest) || (this.props.request instanceof ListServicedDevicesRequest || this.props.request instanceof ListUnServicedDevicesRequest)
            : (this.props.request instanceof ListInspectedDevicesRequest) || (this.props.request instanceof ListServicedDevicesRequest);
    }

    private get isAnnualInspectionFilter(): boolean {
        return (this.props.request instanceof ListAnnualInspectionDevicesRequest);
    }

    private async clearAsync(): Promise<void> {
        this.state.request.clear();

        await this.setState({request: this.state.request, upcomingFilterSelected: false});

        this._depoCostPoolsRef.current!.reRender();
        this._productGroupIdsRef.current!.reRender();
        this._deviceTypesRef.current!.reRender();
        
        if (this.isRentalGroupFilterEnabled){
            this._rentalObjectGroupsRef.current!.reRender();
        }

        if (this._deviceStatusesRef.current) {
            this._deviceStatusesRef.current.reRender();
        }

        if (this._upcomingFilterRef.current) {
            this._upcomingFilterRef.current.reRender();
        }
        
        this._returnInspectionStatusRef.current?.reRender();
        this._fuelingAndCleaningRef.current?.reRender();
        this._serviceStatusRef.current?.reRender();
        
        this._deviceListOrders.current?.reRender();
    }

    private async clearAtAsync(): Promise<void> {
        this._atRef.current!.clear();
        this._completedFromRef.current?.clear();
        this._completedToRef.current?.clear();
    }
    
    private async clearReturnedFiltersAsync(): Promise<void> {
        this._atRef.current!.clear();
        this._toRef.current!.clear();
        this._fromRef.current!.clear();
    }
    private async clearRangeAsync(): Promise<void> {
        this._fromRef.current!.clear();
        this._toRef.current!.clear();
        this._completedFromRef.current?.clear();
        this._completedToRef.current?.clear();
    }

    private async submitAsync(data: Dictionary<string, any>): Promise<void> {
        this.copyTo(data, this.state.request);

        await this._modalRef.current!.closeAsync();

        await this.props.onSubmit(this, this.state.request);
    }
    
    private get getSelectedReturnInspectionFilters() : string {
        if(this.props.request instanceof ListUnInspectedDevicesRequest){
            return this.props.request.returnInspectionDetails;
        }
        return "";
    }

    private get completedFrom() : Date | null | undefined {
        if(this.props.request instanceof ListUnInspectedDevicesRequest){
            return this.props.request.completedAtFrom;
        }
        return undefined;
    }

    private get completedTo() : Date | null | undefined {
        if (this.props.request instanceof ListUnInspectedDevicesRequest) {
            return this.props.request.completedAtTo;
        }
        return undefined;
    }
    
    private handleUpcoming(sender : Dropdown<SelectListItem>) {
        const count = sender.selectedItems.filter(p=>p.value === ServiceAndRepairsStatuses.UpcomingService.toString());
        
        if(count.length > 0){
            this.setState({
                upcomingFilterSelected: true
            })
        }else {
            this.setState({
                upcomingFilterSelected: false
            })
        }
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
        
        if(this._serviceStatusRef.current) {
            const count = this._serviceStatusRef.current?.selectedItems.filter(p => p.value === ServiceAndRepairsStatuses.UpcomingService.toString());
            if(count.length > 0) {
                this.setState({
                    upcomingFilterSelected: true
                })
            } else {
                this.setState({
                    upcomingFilterSelected: false
                })
            }
        }
    }

    public render(): React.ReactNode {

        return (
            <Modal id={"devicesFiltersModal"} title={this.props.title} ref={this._modalRef} className={styles.devicesFiltersModal}>

                <Form className={styles.form}
                      onSubmit={async (_, data) => await this.submitAsync(data)}
                >

                    <Inline className={styles.inline}>
                        <TextInput id="deviceExternalId"
                                   name="deviceExternalId"
                                   label={Localizer.devicesFiltersModalLabelDeviceId}
                                   value={this.state.request.deviceExternalId}
                        />

                        <Dropdown multiple clearButton
                                  id={"depoCostPools"}
                                  ref={this._depoCostPoolsRef}
                                  name={`depoCostPools`}
                                  label={Localizer.devicesFiltersModalLabelDepots}
                                  nothingSelectedText={"-"}
                                  items={this.getDepos()}
                        />
                    </Inline>

                    <Inline className={styles.inline}>
                        <Dropdown multiple clearButton
                                  id={"productGroupIds"}
                                  ref={this._productGroupIdsRef}
                                  name={`productGroupIds`}
                                  label={Localizer.devicesFiltersModalLabelProductGroups}
                                  nothingSelectedText={"-"}
                                  items={this.getProductGroups()} 
                                  align={DropdownAlign.Left}
                        />

                        <Dropdown multiple clearButton
                                  id={"deviceTypes"}
                                  ref={this._deviceTypesRef}
                                  name={`deviceTypes`}
                                  label={Localizer.devicesFiltersModalLabelDeviceTypes}
                                  nothingSelectedText={"-"}
                                  items={this.getDeviceTypes()}
                        />
                    </Inline>

                    <Inline className={styles.inline}>
                        <DateInput popup clearButton
                                   id={"from"}
                                   ref={this._fromRef}
                                   name="from"
                                   label={this.getDateFromFilterTitle()}
                                   value={this.props.request.from || undefined}
                                   onChange={async () => await this.clearAtAsync()}
                        />

                        <DateInput popup clearButton
                                   id={"to"}
                                   ref={this._toRef}
                                   name="to"
                                   label={this.getDateToFilterTitle()}
                                   value={this.props.request.to || undefined}
                                   onChange={async () => await this.clearAtAsync()}
                        />
                    </Inline>

                    <Inline className={styles.inline}>

                        <DateInput popup clearButton
                                   id={"date"}
                                   ref={this._atRef}
                                   name="date"
                                   label={this.getDateAtFilterTitle()}
                                   value={this.props.request.date || undefined}
                                   onChange={async () => await this.clearRangeAsync()}
                        />

                        {this.isRentalGroupFilterEnabled && (
                            <Dropdown
                                  multiple clearButton
                                  id={"rentalObjectGroups"}
                                  ref={this._rentalObjectGroupsRef}
                                  name={"rentalObjectGroups"}
                                  items={this.getRentalObjectGroups()}
                                  label={Localizer.devicesFiltersModalLabelRentalObjectGroups}
                                  nothingSelectedText={"-"}
                            />
                        )}

                    </Inline>

                    <Inline className={styles.inline}>
                        {
                            (this.hasDeviceStatuses) &&
                            (
                                <Dropdown multiple clearButton
                                          id={"deviceStatuses"}
                                          ref={this._deviceStatusesRef}
                                          name="deviceStatuses"
                                          label={Localizer.devicesFiltersModalLabelStatus}
                                          nothingSelectedText={"-"}
                                          items={this.getDeviceStatuses()}
                                />
                            )
                        }

                        {
                            (this.isAnnualInspectionFilter) &&
                            (
                                <Dropdown autoCollapse clearButton
                                          ref={this._upcomingFilterRef}
                                          name="annualInspectionStatusFilter"
                                          label={Localizer.devicesFiltersModalLabelAnnualInspectionStatus}
                                          nothingSelectedText={"-"}
                                          items={this.getInspectionFilters()}
                                />
                            )
                        }
                    </Inline>

                    {
                        (this.props.deviceStatusSelection) &&
                        <Inline className={styles.inline}>
                            <Dropdown clearButton
                                      ref={this._showDeletedDdRef}
                                      items={this.getDeletedFilters()}
                                      name={"showDeleted"}
                                      label={Localizer.devicesFiltersModalLabelShowDevicesByDeletedStatus}
                                      selectedItem={this._itemShowActive}
                                      />
                        </Inline>
                    }
                    {
                        (this.props.returnInspectionDetailsSelection) &&
                        <Inline className={styles.inline}>
                            <Dropdown clearButton
                                      ref={this._fuelingAndCleaningRef}
                                      items={this.getReturnInspectionFilters()}
                                      name={"returnInspectionDetails"}
                                      label={Localizer.devicesFiltersModalLabelWashingAndCleaning}
                                      selectedItem={this.getSelectedReturnInspectionFilters}
                            />

                            <Dropdown clearButton multiple
                                      ref={this._returnInspectionStatusRef}
                                      items={this.getReturnInspectionStatusFilters()}
                                      name={"returnInspectionStatus"}
                                      label={Localizer.devicesFiltersModalLabelReturnInspectionStatus}
                                      selectedItems={this.getSelectedItemsForReturnInspectionDetails}
                            />
                            
                        </Inline>
                    }

                    {
                        (this.props.returnInspectionDetailsSelection && UnleashHelper.isEnabled(RentaToolsConstants.featureRiCompletedAtFilterEnabled)) &&
                        <Inline className={styles.inline}>
                            <DateInput popup clearButton
                                       id={"completedAtFrom"}
                                       name="completedAtFrom"
                                       ref={this._completedFromRef}
                                       label={Localizer.devicesFiltersModalLabelCompletedAtFrom}
                                       value={this.completedFrom}
                                       onChange={async () => await this.clearReturnedFiltersAsync()}
                            />

                            <DateInput popup clearButton
                                       id={"completedAtTo"}
                                       name="completedAtTo"
                                       ref={this._completedToRef}
                                       label={Localizer.devicesFiltersModalLabelCompleteAtTo}
                                       value={this.completedTo}
                                       onChange={async () => await this.clearReturnedFiltersAsync()}
                            />
                        </Inline>
                    }

                    {
                        (this.props.serviceFilters) &&
                        <Inline className={styles.inline}>
                            <Dropdown multiple clearButton
                                      id={"servicesStatus"}
                                      ref={this._serviceStatusRef}
                                      name={"serviceStatus"}
                                      items={this.getServiceStatusFilters()}
                                      label={Localizer.servicePageFiltersServiceStatus}
                                      selectedItems={this.getSelectedItemsForServiceDetails}
                                      onChange={async (sender) => this.handleUpcoming(sender)}
                            />

                            <Dropdown multiple clearButton
                                      id={"servicesTypes"}
                                      ref={this._servicesTypes}
                                      name={"servicesTypes"}
                                      items={this.getServiceTypeFilters()}
                                      label={Localizer.servicePageFiltersServiceAndRepairFilter}
                                      selectedItems={this.getSelectedItemsForServiceTypes}
                            />
                            
                        </Inline>
                    }

                    {(this.props.serviceFilters && UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceForecastList)) && (
                        <Inline className={styles.inline}>
                            <Dropdown clearButton
                                      id={"serviceForecast"}
                                      ref={this._servicesTypes}
                                      name={"serviceForecast"}
                                      items={this.getServiceForecastGaps()}
                                      label={Localizer.servicedPageUpcomingFilter}
                                      selectedItem={this.getSelectedItemForTimeline}
                                      disabled={!this.state.upcomingFilterSelected}
                            />
                        </Inline>
                    )}

                    {this.props.priorityOrder && (
                        <Inline className={styles.inline}>
                            <Dropdown required
                                      id={"orderBy"}
                                      ref={this._deviceListOrders}
                                      name={"orderBy"}
                                      items={EnumProvider.getDeviceListOrderItems()}
                                      label={Localizer.deviceFiltersModalPriorityOrder}
                                      selectedItem={this.getSelectedItemForOrder}
                            />
                        </Inline>
                        )}


                    <ButtonContainer className={this.css(styles.buttons)}>
                        <Button id={"applyButton"} submit type={ButtonType.Orange} label={Localizer.devicesFiltersModalButtonApply}/>
                        <Button id={"clearButton"} type={ButtonType.Blue} label={Localizer.devicesFiltersModalButtonClear} onClick={async () => await this.clearAsync()}/>
                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
};