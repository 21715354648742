import heic2any from "heic2any";
import {FileModel} from "@renta-apps/athenaeum-toolkit";

export class ImageProcessorResponse {
    public isSuccessful: boolean;
    public src: string;

    private constructor(isSuccessful: boolean, src: string) {
        this.isSuccessful = isSuccessful;
        this.src = src;
    }

    public static ImageProcessorResponseSuccess(src: string) : ImageProcessorResponse {
        return new ImageProcessorResponse(true, src);
    }

    public static ImageProcessorResponseFailure() : ImageProcessorResponse {
        return new ImageProcessorResponse(false, "");
    }

}

class ImageProcessor{
    
    private readonly _maxWidth : number = 1920;
    private readonly _maxHeight : number = 1080;
    
    private IsResizeNeeded = (width : number, height : number) : boolean => {
        return width >= this._maxWidth && height >= this._maxHeight;
    }

    private blobToBase64 = (blob: Blob) : Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
                const base64 = reader.result?.toString();
                if (base64) {
                    resolve(base64);
                } else {
                    reject(new Error("Failed to convert Blob to base64"));
                }
            };
            reader.onerror = () => {
                reject(new Error("Failed to read Blob"));
            };
        });
    }
    private convertHeicToJpeg = async (src : string)  => {
        const buffer = Uint8Array.from(atob(src.split(',')[1]), c => c.charCodeAt(0));
        const result =  await heic2any({
            blob: new Blob([buffer]),
            toType: 'image/jpg',
            quality: 1
        });
        
        return await this.blobToBase64(result as Blob);
}
    
    private resizeImage = (src : string, maxWidth : number, maxHeight : number) : Promise<ImageProcessorResponse> => {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = src
            image.onload = () => {
                let width = image.width;
                let height = image.height;
                
                if(!this.IsResizeNeeded(width, height)) {
                    resolve(ImageProcessorResponse.ImageProcessorResponseSuccess(src));
                }

                const canvas = document.createElement('canvas');
                // Calculate new dimensions based on max size
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                // Resize canvas and draw image on it
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(image, 0, 0, width, height);

                // Convert canvas to a new BASE64
                const url = canvas.toDataURL('image/jpg', 1.0);
                resolve(ImageProcessorResponse.ImageProcessorResponseSuccess(url));

            };
            image.onerror = () =>{
                resolve(ImageProcessorResponse.ImageProcessorResponseFailure());
            };
        });
    };
    
    private isHeic = (file : FileModel) : boolean => {
        return file.name.includes(".heic");
    }
    public convertImage = async ( file : FileModel) : Promise<FileModel> =>{
        if(!this.isHeic(file)){
            return Promise.resolve(file);
        }

        let newSource = await this.convertHeicToJpeg(file.src);

        let newName = file.name.replace(".heic", ".jpg");
        
        let fileType = file.type.replace("image/heic", "image/jpg");
        
        let newFile : FileModel = {...file, src: newSource, name: newName, type: fileType };
        
        return Promise.resolve(newFile);
    }
    
    public shrinkImage = async (src : string) : Promise<ImageProcessorResponse> => {
        return this.resizeImage(src, this._maxWidth, this._maxHeight);
    }
}

export default new ImageProcessor();