import DeviceInfo from "@/models/server/DeviceInfo";
import Localizer from "@/localization/Localizer";
import { PredictionDatesType } from "@/models/Enums";

export interface IServicedPredictionInfo {
  item: DeviceInfo;
}

const ServicedPredictionInfo = ({ item } : IServicedPredictionInfo) => {
    const predictionDate = item.predictionDates.firstOrDefault(p => p.date.getUTCDate() === item.closestPredictionDate?.getUTCDate());
    
    if(predictionDate) {
        
        const predictionDateReason = predictionDate.type === PredictionDatesType.NextAnnualServiceDate ? Localizer.enumMaintenanceReasonAnnualServiceMonthsLimit : Localizer.forecastReasonOperatingHours;
        
        return (
            <div>
                <p>{Localizer.devicePageMaintenanceReasons} {predictionDateReason}</p>
            </div>
        )
    }
    
    return null;
}

export default ServicedPredictionInfo;