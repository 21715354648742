import React from "react";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import {ButtonType, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Report from "../Models/Report";
import QuestionPicturesReportItem from "../Models/QuestionPicturesReportItem";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Device from "../Models/Device";
import ListDevicePicturesRequest from "@/models/server/requests/ListDevicePicturesRequest";
import DevicePicture from "@/models/server/DevicePicture";
import GetDevicePicturesFiltersResponse from "@/models/server/responses/GetDevicePicturesFiltersResponse";
import DevicePictureFiltersData from "@/models/server/DevicePictureFiltersData";
import PicturesList from "@/pages/PictureEventsPage/PicturesList/PicturesList";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import TransformProvider from "@/providers/TransformProvider";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./PictureEventsPage.module.scss";

interface IPictureEventsPageProps {
}

interface IPictureEventsPageState {
    request: ListDevicePicturesRequest;
    filtersData: DevicePictureFiltersData;
}

export default class PicturesPreviewPage extends AuthorizedPage<IPictureEventsPageProps, IPictureEventsPageState> {

    state: IPictureEventsPageState = {
        request: new ListDevicePicturesRequest(),
        filtersData: new DevicePictureFiltersData()
    };
    

    private get title(): string {
        return `${this.device.externalId} ${this.device.name}`;
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private async onOpenPictureListItemAsync(devicePictureItem: DevicePicture): Promise<void> {
        const report: Report | null = devicePictureItem.returnInspectionReport;
        let reportItem: QuestionPicturesReportItem = new QuestionPicturesReportItem();

        if (report != null) {
            reportItem = Report.getQuestionPicturesReportItemByFileId(devicePictureItem.fileId, report);

            if (reportItem == null) {
                throw Error(Localizer.picturesPreviewPageErrorPicturesCannotBeFound);
            }

        } else {
            if (devicePictureItem.file == null) {
                throw Error(Localizer.picturesPreviewPageErrorPicturesCannotBeFound);
            }

            reportItem.name = Localizer.get(Localizer.genericMadeBy, TransformProvider.userToString(devicePictureItem.createdBy!));
            reportItem.pictures = [];

            const devicePictures: DevicePicture[] = await this.postAsync("api/device/getGroupedDevicePictures", devicePictureItem.groupKey);

            reportItem.pictures = devicePictures
                .where(item => (item.file != null))
                .map(item => item.file!);

            if ((devicePictureItem.file.description != null) && (devicePictureItem.file.description.length > 0)) {
                reportItem.commented = true;
                reportItem.comment = devicePictureItem.file.description;
            }
        }

        RentaToolsController.context.devicePicture = devicePictureItem;
        RentaToolsController.saveContext();

        await RentaToolsController.previewPicturesAsync(report, reportItem);
    }

    protected async fetchDataAsync(): Promise<IPagedList<DevicePicture>> {
        this.state.request.deviceId = this.device.id;
        this.state.request.pageSize = 10;

        UserInteractionDataStorage.set("ListDevicePicturesRequest", this.state.request);

        return await this.postAsync("api/device/listDevicePictures", this.state.request);
    }

    public getManual(): string {
        return Localizer.picturesPreviewPageManual;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: GetDevicePicturesFiltersResponse = await this.postAsync("api/device/getDevicePicturesFilters");

        await this.setState({filtersData: response.data});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(rentaToolsStyles.pageContainer, styles.picturesEvents)}>

                <PageHeader title={this.title}
                            className={rentaToolsStyles.leftPageHeader}>

                    <span className={rentaToolsStyles.rightPageHeader}/>

                </PageHeader>

                <ArsenalPageRow>

                    <PicturesList noDataText={Localizer.picturesPreviewPagePicturesListNoData}
                                  request={this.state.request}
                                  filtersData={this.state.filtersData}
                                  fetchData={async () => await this.fetchDataAsync()}
                                  onOpenPictureListItem={async (sender, item) => await this.onOpenPictureListItemAsync(item)}
                    />

                </ArsenalPageRow>

                <ArsenalPageRow>

                    <ArsenalButton block
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </ArsenalPageRow>

            </PageContainer>
        );
    }
}