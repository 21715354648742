import ToolsUtility from "@/helpers/ToolsUtility";
import Localizer from "@/localization/Localizer";
import React, {useEffect, useState} from "react";
import DeviceInfo from "@/models/server/DeviceInfo";

interface IServicedDeviceDate{
    item: DeviceInfo;
    upcomingFilter?: boolean;
}

const ServicedDeviceDate = ({ item, upcomingFilter } : IServicedDeviceDate) =>{
    const [date, setDate] = useState<Date | null>();
    const [forecastDate, setForecastDate] = useState<Date | null>();

    const compareDates = (date1: Date | null | undefined, date2: Date | null | undefined): Date | null => {
        if (!date1 && !date2) return null;
        return date1 && (!date2 || date1 > date2) ? date1 : date2!;
    }
    
    useEffect(() => {
        setForecastDate(null);
        setDate(null);
        
        if(item.closestPredictionDate && upcomingFilter){
            setForecastDate(item.closestPredictionDate);
        }
        if(DeviceInfo.needService(item) || DeviceInfo.needRepair(item)){
            setDate(item.statusChangedAt);
        }
        else{
            setDate(compareDates(item.lastServiceDate, item.lastRepairDate));
        }
        
    }, [item])
    
    let value = ToolsUtility.toDateString(date);
    
    if (date) {
        value += ` (${ToolsUtility.calculateDaysTillToday(date)}) ${Localizer.servicedPageDays}`;
    }
    
    if(forecastDate) {
        value = `${Localizer.servicedPageExpectedServiceDate} ${ToolsUtility.toDateString(forecastDate)} (${Math.abs(ToolsUtility.calculateDaysTillToday(forecastDate))}) ${Localizer.servicedPageDays}`;
    }
    
    return (
        <p data-cy={"serviceDateInfo"}>{value}</p>
    )
    
}

export default ServicedDeviceDate;