import React from "react";
import {
    BaseAsyncComponent,
    ch,
    IBaseAsyncComponentState,
    IBaseComponent,
    PageRouteProvider
} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Icon, IconSize, IconStyle} from "@renta-apps/athenaeum-react-components";
import DeviceInfo from "@/models/server/DeviceInfo";

import styles from "./ServicedDevicesList.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import Localizer from "@/localization/Localizer";
import ToolsUtility from "@/helpers/ToolsUtility";
import ListServicedDevicesRequest from "@/models/server/requests/ListServicedDevicesRequest";
import ListUnServicedDevicesRequest from "@/models/server/requests/ListUnServicedDevicesRequest";
import {DeviceListOrderType, DeviceStatus, MaintenanceStatus, ServiceAndRepairsStatuses} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ToolsPageHeader from "@/components/ToolsPageHeader/ToolsPageHeader";
import BaseListDevicesRequest from "@/models/server/requests/BaseListDevicesRequest";
import FiltersData from "@/pages/Models/FiltersData";
import DevicesFiltersModal from "@/components/DevicesFiltersModal/DevicesFiltersModal";
import FaultText from "@/components/FaultText/FaultText";
import ServicedDevicePageStatus from "@/components/ServicedDevicesList/components/ServicedDevicePageStatus";
import ServicedDeviceDate from "@/components/ServicedDevicesDate/ServicedDeviceDate";
import ExpandableMenu from "@/components/ExpandableMenu/ExpandableMenu";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import ServicedPredictionInfo from "@/components/ServicedDevicesList/components/ServicedPredictionInfo";

interface IServicedDevicesListProps {

    sticky?: boolean
    columnHeader?: boolean
    title: string;
    noDataText: string;
    request: BaseListDevicesRequest;
    filtersData: FiltersData;
    fetchData(sender: IBaseComponent, request: BaseListDevicesRequest): Promise<DeviceInfo[]>;
    onDeviceOpen(sender: IBaseComponent, device: DeviceInfo): Promise<void>;
}

export interface IServicedDevicesList extends IBaseAsyncComponentState<DeviceInfo[]> {
    request: BaseListDevicesRequest;
    isFetching : boolean;
    shortView : boolean;
}

export default class ServicedDevicesList extends BaseAsyncComponent<IServicedDevicesListProps, IServicedDevicesList, DeviceInfo[]> {

    state: IServicedDevicesList = {
        data: null,
        isLoading: false,
        request: this.props.request,
        isFetching: false,
        shortView : ServicedDevicesList.initializeViewSettings(),
    };
    
    private readonly _filtersModalRef: React.RefObject<DevicesFiltersModal> = React.createRef();

    private readonly _lastElement: React.RefObject<HTMLDivElement> = React.createRef();

    private _intersectionObserver: IntersectionObserver | null = null;
    public get haveMoreDataToLoad(): boolean {
        return (this.state.request instanceof ListServicedDevicesRequest || this.state.request instanceof ListUnServicedDevicesRequest) &&
            (this.state.request.pageSize * (this.state.request.pageNumber + 1) === this.state.data?.length!);
    }
    public async componentDidUpdate() {
        this._intersectionObserver = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting && !this.state.isFetching && this.haveMoreDataToLoad) {

                const request = this.state.request;

                if (request instanceof ListUnServicedDevicesRequest) {
                    request.nextPage();
                }

                await this.setState({...this.state, isFetching: true})

                const result = await this.props.fetchData(this, request);

                const currentState = this.state.data;

                currentState?.push(...result)

                await this.setState({...this.state, data: currentState, isFetching: false, request: request})
            }
        });

        if (this._intersectionObserver && this._lastElement.current) {
            this._intersectionObserver.observe(this._lastElement.current);
        }
    }

    private async openFiltersAsync(): Promise<void> {
        await this._filtersModalRef.current!.openAsync();
    }

    private async submitFiltersAsync(request: BaseListDevicesRequest): Promise<void> {
        if (request instanceof ListUnServicedDevicesRequest) {
            request.modified = true;
            request.resetPage();
        }
        await this.setState({request});
        await this.reloadAsync();
    }

    private async onItemClickAsync(device: DeviceInfo): Promise<void> {
        await this.props.onDeviceOpen(this, device);
    }

    protected async fetchDataAsync(): Promise<DeviceInfo[]> {
        return this.props.fetchData(this, this.state.request);
    }

    protected getEndpoint(): string {
        return "";
    }
    
    protected get upcomingFilter () : boolean {
         if(this.state.request instanceof ListUnServicedDevicesRequest)
         {
             return this.state.request.serviceStatus.some(p => p === ServiceAndRepairsStatuses.UpcomingService.toString());
         }
         
         return false;
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    private servicedStatus = [MaintenanceStatus.RequiresRepair, MaintenanceStatus.RequiresService]

    private needService = (device: DeviceInfo) => {
        return (this.servicedStatus.some(p=> ToolsUtility.hasFlag(device.maintenanceStatus, p)) || (device.status === DeviceStatus.InMaintenance || device.status === DeviceStatus.RepairIsNeeded)) && !device.underService;
    }
    
    private hasForecast = (device: DeviceInfo) : boolean => {
        return device.closestPredictionDate !== null;
    }

    private completedService = (device : DeviceInfo) => {
        return !this.needService(device) && !device.underService && !this.hasForecast(device);
    }

    private static initializeViewSettings = () : boolean => {
        const item = localStorage.getItem(RentaToolsConstants.servicedPageListViewSetting);

        if(item){
            return item.toLowerCase() == 'true'
        }

        return true;
    }
    private changeView = async () => {
        const nextView = !this.state.shortView;

        await this.setState({...this.state, shortView: nextView});

        localStorage.setItem(RentaToolsConstants.servicedPageListViewSetting, nextView.toString());
    }
    public render(): React.ReactNode {

        return (
            <div className={this.css(styles.devicesList, newStyles.deviceList)} data-cy={"devices_list"}>

                <div className={this.props.sticky ? this.css(rentaToolsStyles.stickyDeviceListHeader, styles.stickyDeviceListHeader) : ""}>

                    <ToolsPageHeader stickyHeader title={Localizer.servicedDevicesPageTitle.toUpperCase()}/>

                    <div className={newStyles.col}>

                        <div className={this.css(styles.header, newStyles.header)}>

                            <span className={newStyles.subTitle}>
                                {this.toMultiLines(this.props.title)}
                            </span>

                            <div className={this.css(styles.filterButton)}>
                                <Button small
                                        id={"filterButton"}
                                        icon={{name: this.getIconName(), size: IconSize.X2}}
                                        type={ButtonType.Blue}
                                        className={this.css(styles.filter, newStyles.filter)}
                                        onClick={async () => await this.openFiltersAsync()}
                                />
                            </div>

                            <div className={this.css(styles.filterButtonSettings)}>

                                <ExpandableMenu onClick={this.changeView} buttonDescription={Localizer.genericExpandedMenu} buttonState={!this.state.shortView}>
                                    {ch.getUser<User>().canEditDeviceOrder &&
                                        <Button id="edit_order"
                                                type={ButtonType.Orange}
                                                className={this.css(styles.filter, newStyles.filter)}
                                                label={Localizer.genericEditOrder}
                                                onClick={async () => {
                                                    await PageRouteProvider.redirectAsync(PageDefinitions.depoSelectionRoute(DeviceListOrderType.ServiceDevices))
                                                }}
                                        />
                                    }
                                </ExpandableMenu>
                            </div>

                        </div>

                    </div>
                </div>

                {
                    (!this.isLoading) && ((this.state.data == null) || (this.state.data.length == 0)) &&
                    (
                        <div className={this.css(styles.deviceListItem, styles.noItems, newStyles.deviceListItem)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        this.state.data.map((item, index) =>
                            <div key={index}
                                 data-cy={`deviceServiceListItem_${item.externalId}`}
                                 ref={index === this.state.data?.length! - 1 ? this._lastElement : undefined}
                                 className={this.css(styles.deviceListItem,
                                     "cursor-pointer",
                                     newStyles.deviceListItem
                                 )}
                                 onClick={async () => await this.onItemClickAsync(item)}>

                                <div data-cy={`priorityBar${item.underService ? "_progress" : ""}`} className={this.css(styles.priorityBar,
                                    this.hasForecast(item) && styles.forecast,
                                    this.needService(item) && styles.waitingForService,
                                    this.completedService(item) && styles.completed,
                                    item.underService && styles.inProgress,
                                )}
                                />

                                <div className={this.css(styles.leftCell)}>
                                    <p data-cy={"deviceId"} className={this.css(styles.fontBold)}><b>{item.externalId}</b> {item.depoCostPool}</p>
                                    <p data-cy={"deviceName"}>{item.type}</p>
                                    <ServicedDeviceDate item={item} upcomingFilter={this.upcomingFilter}/>
                                    {!this.state.shortView && (
                                        <>
                                            <p>{item.name}</p>
                                            {!this.hasForecast(item) && <ServicedDevicePageStatus item={item} />}
                                            {this.hasForecast(item) && <ServicedPredictionInfo item={item} />}
                                            <FaultText faults={item.faults}/>
                                        </>
                                    )}
                                </div>

                                <div className={styles.iconsService}>
                                    {
                                        DeviceInfo.needService(item) &&
                                        (
                                            <Icon name={"fa-oil-can"}
                                                  size={IconSize.Large}
                                                  style={IconStyle.Regular}
                                            />
                                        )
                                    }
                                    {
                                        DeviceInfo.needRepair(item) &&
                                        (
                                            <Icon name={"fa-tools"}
                                                  size={IconSize.Large}
                                                  style={IconStyle.Regular}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        )
                    )
                }

                <DevicesFiltersModal serviceFilters
                                     ref={this._filtersModalRef}
                                     title={this.props.title}
                                     request={this.state.request}
                                     filtersData={this.props.filtersData}
                                     onSubmit={async (sender, request) => await this.submitFiltersAsync(request)}
                />

            </div>
        );
    }
};