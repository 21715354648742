import styles from "./CheckButton.module.scss";
import { Icon } from "@renta-apps/athenaeum-react-components";
import { Utility } from "@renta-apps/athenaeum-toolkit";
import { CheckStatus } from "@/models/Enums";

export interface ICheckButtonProps {
  id: string;
  icon: string;
  buttonType: CheckStatus;
  buttonText: string;
  selected: boolean;
  onClick: (status: CheckStatus) => void;
  disabled?: boolean;
}

const CheckButton = ({
  id,
  icon,
  buttonType,
  buttonText,
  selected,
  onClick,
  disabled,
}: ICheckButtonProps) => {
  const iconsStyles = {
    "Yes" : styles.icon,
    "No": styles.noIcon,
    "Skip": styles.skipIcon,
    "Unselected": "",
  };

  const wrapperStyles = {
    "Yes": styles.wrapperYes,
    "No": styles.wrapperNo,
    "Skip": styles.wrapperSkip,
    "Unselected": "",
  };

  const onClickHandler = () => {
    if (!disabled) {
      onClick(buttonType);
    }
  };

  return (
    <div
      id={id}  
      className={Utility.css(
        styles.buttonWrapper,
        selected && wrapperStyles[buttonType],
        disabled && styles.disabled,
      )}
      onClick={onClickHandler}
    >
      <div className={styles.iconWrapper}>
        <Icon
          name={icon}
          className={Utility.css(
            iconsStyles[buttonType],
            selected && styles.selectedIcon,
          )}
        />
      </div>
      <div>{buttonText}</div>
    </div>
  );
};

export default CheckButton;
