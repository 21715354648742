import {IUser} from "@renta-apps/athenaeum-react-common";
import {AuthType, UserRole} from "../Enums";
import Depo from "./Depo";
import UserInvitation from "./UserInvitation";

export default class User implements IUser {
    public id: string = "";

    public username: string = "";

    public email: string = "";

    public phone: string = "";
    
    public firstName: string = "";

    public middleName: string = "";

    public lastName: string = "";

    public depoId: string = "";

    public depo: Depo | null = null;

    public depos: Depo[] | null = null;

    public language: string = "";

    public role: UserRole = UserRole.Technician;

    public authType: AuthType = AuthType.Email;

    public isDeleted: boolean = false;

    public isLocked: boolean = false;

    public hasPassword: boolean = false;

    public invitations: UserInvitation[] = [];

    public canInvoiceFuelingAndWashing: boolean = false;

    public canInvoiceAdditionalExpenses: boolean = false;
    
    public canEditOwnAnnualInspections: boolean = false;

    public canEditDeviceOrder: boolean = false;
    
    public isUser: true = true;

    public static hasDepoAccess(user: User, depoId: string | null): boolean {
        return (!depoId) || (user.depoId == depoId) || ((user.depos != null) && (user.depos.length > 0) && (user.depos.some(depo => depo.id == depoId)));
    }

    public static hasFuelingAndWashingAccess(user: User): boolean {
        return (user.role >= UserRole.DepoManager);
    }
}