class AdminPage {
    public elements = {
        menu: () => cy.get('[id=admin_menu]'),
        settings: () => this.elements.menu().children().eq(0),
        userManagement: () => this.elements.menu().children().eq(1),
        reportDefinitions: () => this.elements.menu().children().eq(2),
        serviceActions: () => this.elements.menu().children().eq(3),
        mappings: () => this.elements.menu().children().eq(4),
        annualInspectionVendors: () => this.elements.menu().children().eq(5),
        depos: () => this.elements.menu().children().eq(6),
        annualInspectionTypeMappings: () => this.elements.menu().children().eq(7),
        returnInspectionExpenses: () => this.elements.menu().children().eq(8),
        expenses: () => this.elements.menu().children().eq(9),
        deviceManagement: () => this.elements.menu().children().eq(10),
        adminConsole: () => this.elements.menu().children().eq(11),
        dataManagement: () => this.elements.menu().children().eq(12),
    }

    public routes = {}
}

export default new AdminPage();