import EndpointPaths from "@/common/EndpointPaths";

class CypressFeatureFlagService {
    public enable(featureFlag : string) {
        cy.request("POST", EndpointPaths.LocalFeatureFlags.UpdateFeatureFlags, {
            featureFlagName: featureFlag,
            status: true,
        });
    }
    
    public disable(featureFlag : string) {
        cy.request("POST", EndpointPaths.LocalFeatureFlags.UpdateFeatureFlags, {
            featureFlagName: featureFlag,
            status: false,
        });
    }
}

export default new CypressFeatureFlagService();